//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";

import {
  DownOutlined,
  InfoCircleFilled,
  MinusSquareFilled,
  PieChartOutlined,
  PlusSquareFilled,
  QuestionCircleFilled,
  StopOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

import {
  Dropdown,
  Button,
  Menu,
  Input,
  Pagination,
  Radio,
  Checkbox,
  Empty,
  Drawer,
  Tooltip,
  Badge,
  notification,
  Alert,
  Spin,
  Modal,
  InputNumber,
  Row,
  Col,
  Space,
} from "antd";
import deepFreeze from "deep-freeze";
import { debounce } from "lodash";
import Axios from "axios";
import { FixedSizeList } from "react-window";
import introJs from "intro.js";

//--------IP-SUITE COMMON COMPONENTS--------
import GeneralMarkCard from "../../commonComponents/generalCards/GeneralMarkCard";
import ResultsAnalysis from "../../commonComponents/subComponents/ResultsAnalysis";
import DateRangePicker from "../../../functions/commonFunctions/dateRangePicker";

//--------IP-SUITE CONSTANTS--------
import {
  SUCCESS,
  LOADING,
  PDF,
  EXCEL,
  ERROR,
  BI_EXCEL,
} from "../../../constants/commonConstants/generalConstants";

//--------IP-SUITE COMMON ACTIONS--------
import {
  TPAction,
  TPSAction,
  logOut,
} from "../../../redux/actions/commonActions/actions";

//--------IP-SUITE COMMON GENERAL FUNCTIONS--------
// import { changeStateValue_L1 } from '../../../functions/commonFunctions/generalFunctions';

//--------IP-SUITE COMMON CHECK VALUE FUNCTIONS--------
import { checkNA } from "../../../functions/commonFunctions/checkValueFunctions";

//--------SEARCH ROOT CONFIG--------
import { SRCH_RT_URL } from "../../../configs/rootConfigs";

//--------SEARCH SUB-COMPONENTS--------
import TrademarksAlgosInfo from "../subComponents/TrademarksAlgosInfo";

//--------SEARCH COMMON ACTIONS--------
import {
  getSearchResults,
  postReport,
  handleSearchInfoDispatch,
  getImageSearchResults,
} from "../../../redux/actions/searchActions/commonActions";

//--------SEARCH ACTION TYPES--------
import {
  TS_SELECT_VIEW,
  CHANGE_SEARCH_RESULTS_TAB,
  TS_SET_SEARCHES_CANCEL,
  TS_SET_FILTERS_CANCEL,
  TS_GET_SEARCH_FILTERS,
  TS_SET_REMINDER_STAMP,
  TS_GET_REPORT_AVAILABILITY,
  TS_SET_STATE,
  TS_SET_DEFAULT_STATE,
} from "../../../redux/actions/searchActions/types";

//--------SEARCH STYLESHEETS--------
import "../../../stylesheets/searchStyleSheets/search.css";

//--------TRADEMARKS SEARCH ACTIONS--------
import {
  getTrademarkReportStatus,
  patchTrademarkReport,
} from "../../../redux/actions/searchActions/trademarksSearchActions";

//--------TRADEMARKS SEARCH CONSTANTS--------
import {
  TS_trademarksSearchState,
  TS_selectedFilters,
  TS_singlewordSearch,
  TS_multiwordSearch,
} from "../../../constants/searchConstants/trademarksSearchConstants";
import { refreshPeriod } from "../../../constants/searchConstants/generalConstants";
// import {SearchFilterSearchBasic}
import SearchFilterSearchBasic from "./../../commonComponents/FilterComponents/SearchFilterSearchBasic";

/**
 * This function will help in resetting this component's state
 * to it's initial values.
 */
export function resetTrademarksSearchState() {
  this.props.TPAction(TS_SET_DEFAULT_STATE, {});
}

class TrademarksSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...TS_trademarksSearchState,
      range1: null,
      range2: null,
      open: null,
      wildcardSearch: this.props["Wildcard"].searchParameters.query,
      booleanSearch: this.props["Boolean"].searchParameters.query,
      page: 10,
    };

    this.changeStateValue_L1 = this.changeStateValue_L1.bind(this);
    // this.changeStateValue_L2 = changeStateValue_L2.bind(this);

    this.changeStateValue_L1_Debounced = debounce(
      this.changeStateValue_L1,
      400,
    );

    this.handleConfirmReport_Debounced = debounce(
      this.handleConfirmReport,
      400,
    );
    this.handleGetFilters_Debounced = debounce(this.handleGetFilters, 2000);

    resetTrademarksSearchState = resetTrademarksSearchState.bind(this);
    this.countSelections = this.countSelections.bind(this);
    this.setDate1 = this.setDate1.bind(this);
    this.setDate2 = this.setDate2.bind(this);
  }

  changeStateValue_L1(stateKey, subStateKey, value) {
    this.props.TPAction(TS_SET_STATE, {
      [stateKey]: {
        ...this.props.searchState[stateKey],
        [subStateKey]: value,
      },
    });
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.history.push(this.props.searchPath);
      let trademarksSearchTour;
      if (window.location.pathname === this.props.searchPath) {
        try {
          trademarksSearchTour = document.cookie
            .split("; ")
            .find((row) => row.startsWith("trademarksSearchTour"));
        } catch (err) {
          introJs().start();
          document.cookie = "trademarksSearchTour=false";
        }
      }
    } else {
      this.props.logOut();
    }
  }

  setDate1(val) {
    this.setState({ range1: val });
  }

  setDate2(val) {
    this.setState({ range2: val });
  }

  async handleFilters(reset = false) {
    switch (this.props.filtersStatus) {
      case SUCCESS:
        if (this.props.searchCounter < 0) {
          let filter_flag = false;

          const {
            classes,
            statuses,
            states,
            types,
            selectProprietors,
            deselectProprietors,
            doa,
            dou,
            containsTerm,
            douProposed,
          } = this.props.searchState.selectedFilters;

          let filters = {
            filter_class: null,
            filter_status: null,
            filter_state: null,
            filter_trademark_type: null,
            filter_proprietor_select: null,
            filter_proprietor_deselect: null,
            filter_application_date: null,
            filter_date_of_usage: null,
            filter_contains_term: null,
            filter_date_of_usage_proposed: null,
          };

          if (!reset) {
            if (
              classes.length > 0 ||
              statuses.length > 0 ||
              states.length > 0 ||
              types.length > 0 ||
              selectProprietors.length > 0 ||
              deselectProprietors.length > 0 ||
              doa ||
              dou ||
              containsTerm.length > 0 ||
              douProposed
            ) {
              filter_flag = true;
            }

            filters = {
              ...filters,
              filter_class: `${classes}`,
              filter_status: `${statuses}`,
              filter_state: `${states}`,
              filter_trademark_type: `${types}`,
              filter_proprietor_select: `${selectProprietors}`,
              filter_proprietor_deselect: `${deselectProprietors}`,
              filter_application_date: doa,
              filter_date_of_usage: dou,
              filter_contains_term: containsTerm.toLowerCase().trim(),
              filter_date_of_usage_proposed: douProposed,
            };
          } else {
            this.setState({ range1: null, range2: null });
            this.props.TPAction(TS_SET_STATE, {
              selectedFilters: TS_selectedFilters,
            });
          }
          if (this.props.activeTab === "ImageSearch") {
            let myParams = { ...this.props["ImageSearch"].searchParameters };
            delete myParams.image;
            this.props.getImageSearchResults(
              {
                ...myParams,
                search_log_id: this.props[this.props.activeTab].searchLogID,
                operation: "search",
                page: 1,
                filter_flag,
                filters: { ...filters },
                // ...filters,
              },

              "ImageSearch",
            );
          } else {
            Object.keys(this.props.axiosCancelSearches).map(function () {
              return "Request is cancelled!!!";
            });

            let fuzzyCancelToken = Axios.CancelToken;
            let fuzzyAxiosSource = fuzzyCancelToken.source();

            let wildcardCancelToken = Axios.CancelToken;
            let wildcardAxiosSource = wildcardCancelToken.source();

            let standardCancelToken = Axios.CancelToken;
            let standardAxiosSource = standardCancelToken.source();

            let initialsCancelToken = Axios.CancelToken;
            let initialsAxiosSource = initialsCancelToken.source();

            let phoneticCancelToken = Axios.CancelToken;
            let phoneticAxiosSource = phoneticCancelToken.source();

            let booleanCancelToken = Axios.CancelToken;
            let booleanAxiosSource = booleanCancelToken.source();

            let containsCancelToken = Axios.CancelToken;
            let containsAxiosSource = containsCancelToken.source();

            this.props.TPAction(TS_SET_SEARCHES_CANCEL, {
              Fuzzy: fuzzyAxiosSource.cancel,
              Wildcard: wildcardAxiosSource.cancel,
              Standard: standardAxiosSource.cancel,
              Initials: initialsAxiosSource.cancel,
              Phonetic: phoneticAxiosSource.cancel,
              Boolean: booleanAxiosSource.cancel,
              Contains: containsAxiosSource.cancel,
            });

            switch (this.props.trademarksSearchAlgo) {
              case "Flash":
                if (this.props.multiwordTrademark) {
                  this.props.getSearchResults(
                    {
                      ...this.props["Boolean"].searchParameters,
                      operation: "search",
                      flash_flag: true,
                      page: 1,
                      filter_flag,
                      ...filters,
                    },
                    "Boolean",
                    booleanAxiosSource.token,
                  );

                  this.props.getSearchResults(
                    {
                      ...this.props["Initials"].searchParameters,
                      operation: "search",
                      flash_flag: true,
                      page: 1,
                      filter_flag,
                      ...filters,
                    },
                    "Initials",
                    initialsAxiosSource.token,
                  );
                }

                this.props.getSearchResults(
                  {
                    ...this.props["Fuzzy"].searchParameters,
                    operation: "search",
                    flash_flag: true,
                    page: 1,
                    filter_flag,
                    ...filters,
                  },
                  "Fuzzy",
                  fuzzyAxiosSource.token,
                );

                this.props.getSearchResults(
                  {
                    ...this.props["Wildcard"].searchParameters,
                    operation: "search",
                    flash_flag: true,
                    page: 1,
                    filter_flag,
                    ...filters,
                  },
                  "Wildcard",
                  wildcardAxiosSource.token,
                );

                this.props.getSearchResults(
                  {
                    ...this.props["Standard"].searchParameters,
                    operation: "search",
                    flash_flag: true,
                    page: 1,
                    filter_flag,
                    ...filters,
                  },
                  "Standard",
                  standardAxiosSource.token,
                );

                this.props.getSearchResults(
                  {
                    ...this.props["Contains"].searchParameters,
                    operation: "search",
                    flash_flag: true,
                    page: 1,
                    filter_flag,
                    ...filters,
                  },
                  "Contains",
                  containsAxiosSource.token,
                );

                this.props.getSearchResults(
                  {
                    ...this.props["Phonetic"].searchParameters,
                    operation: "search",
                    flash_flag: true,
                    page: 1,
                    filter_flag,
                    ...filters,
                  },
                  "Phonetic",
                  phoneticAxiosSource.token,
                );
                break;

              case "Wildcard":
                this.props.getSearchResults(
                  {
                    ...this.props["Wildcard"].searchParameters,
                    operation: "search",
                    page: 1,
                    filter_flag,
                    ...filters,
                  },
                  "Wildcard",
                  wildcardAxiosSource.token,
                );
                break;

              case "Initials":
                this.props.getSearchResults(
                  {
                    ...this.props["Initials"].searchParameters,
                    operation: "search",
                    page: 1,
                    filter_flag,
                    ...filters,
                  },
                  "Initials",
                  initialsAxiosSource.token,
                );

                break;

              case "Fuzzy":
                this.props.getSearchResults(
                  {
                    ...this.props["Fuzzy"].searchParameters,
                    operation: "search",
                    page: 1,
                    filter_flag,
                    ...filters,
                  },
                  "Fuzzy",
                  fuzzyAxiosSource.token,
                );

                break;

              case "Boolean":
                this.props.getSearchResults(
                  {
                    ...this.props["Boolean"].searchParameters,
                    operation: "search",
                    page: 1,
                    filter_flag,
                    ...filters,
                  },
                  "Boolean",
                  booleanAxiosSource.token,
                );

                break;

              default:
                break;
            }
          }
        } else {
          notification["warning"]({
            message: "Wait For Filters",
            description: "Filters are being fetched. Kindly, wait for them.",
          });
        }
        break;

      default:
        break;
    }
  }

  async handleConfirmReport() {
    let response = null;

    switch (this.props.reportAvailabilityStatus) {
      case SUCCESS:
        this.changeStateValue_L1("drawersVisibility", "customReport", false);
        if (!this.props.reportAvailability.id) {
          response = await this.props.postReport({
            report_source: "MARK",
            report_type: this.props.searchState.customReportData.reportType,
            report_file:
              this.props.searchState.customReportData.reportFormat.length > 0
                ? true
                : false,
            report_file_types:
              this.props.searchState.customReportData.reportFormat,
            report_file_name:
              this.props.searchState.customReportData.reportName,
            mark_id: this.props.userTrademark.id,
            // selected_mark_id_list: this.props.searchState.customReportData.applicationsIDs,
            ...this.props.searchState.customReportData,
          });

          if (response.status === SUCCESS) {
            if (
              this.props.searchState.customReportData.reportFormat.length > 0
            ) {
              notification["success"]({
                message: "Report Queued",
                description:
                  "Your Report is being generated and will be sent to you via e-mail.You can also visit the Reports section, once generated.",
              });
            } else {
              notification["success"]({
                message: "Report Queued",
                description: "The report has been queued successfully.",
              });
            }
          }
        } else {
          if (this.props.reportAvailability.available) {
            response = await this.props.patchTrademarkReport(
              {
                report_source: "MARK",
                operation: this.props.searchState.customReportData.operation,
                report_type: this.props.searchState.customReportData.reportType,
                report_file:
                  this.props.searchState.customReportData.reportFormat.length >
                  0
                    ? true
                    : false,
                report_file_types:
                  this.props.searchState.customReportData.reportFormat,
                report_file_name:
                  this.props.searchState.customReportData.reportName,
                // selected_mark_id_list: this.props.searchState.customReportData.applicationsIDs,
                ...this.props.searchState.customReportData,
              },
              this.props.reportAvailability.id,
            );

            if (response.status === SUCCESS) {
              if (
                this.props.searchState.customReportData.reportFormat.length > 0
              ) {
                notification["success"]({
                  message: "Report Queued",
                  description:
                    "Your Report is being generated and will be sent to you via e-mail.You can also visit the Reports section, once generated.",
                });
              } else {
                notification["success"]({
                  message: "Report Queued",
                  description: "The report has been queued successfully.",
                });
              }
            }
          } else {
            notification["warning"]({
              message: "Report Under Process",
              description:
                "The report is already under process. Kindly, wait for its completion",
            });
          }
        }
        break;

      case LOADING:
        break;

      default:
        break;
    }
  }

  async handleGetFilters() {
    this.setState({
      wildcardSearch: this.props["Wildcard"].searchParameters.query,
      booleanSearch: this.props["Boolean"].searchParameters.query,
    });
    let CancelToken = Axios.CancelToken;
    let axiosSource = CancelToken.source();

    this.props.TPAction(TS_SET_FILTERS_CANCEL, axiosSource.cancel);

    let search_log_id = [];
    let search_log_ids = [];

    if (this.props.trademarksSearchAlgo === "Flash") {
      if (this.props.multiwordTrademark) {
        TS_multiwordSearch.map(
          (e) =>
            (search_log_ids = [...search_log_ids, this.props[e].searchLogID]),
        );
      } else {
        TS_singlewordSearch.map(
          (e) =>
            (search_log_ids = [...search_log_ids, this.props[e].searchLogID]),
        );
      }

      search_log_ids = `${search_log_ids.filter((e) => e !== null)}`;
    } else {
      search_log_id = this.props[this.props.activeTab].searchLogID;
    }

    let response = {};
    try {
      if (this.props.activeTab === "ImageSearch") {
        response = await Axios.get(`${SRCH_RT_URL}/trademarks/image`, {
          params: {
            operation: "filters",
            search_log_id,
          },
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });
      } else {
        response = await Axios.get(`${SRCH_RT_URL}/trademarks/`, {
          cancelToken: axiosSource.token,
          params: {
            operation: "filters",
            search_log_ids,
            search_log_id,
            flash_flag: this.props.trademarksSearchAlgo === "Flash",
          },
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });
      }

      this.props.TPSAction(TS_GET_SEARCH_FILTERS, response?.data, SUCCESS);
    } catch (err) {
      if (Axios.isCancel(err)) {
        return err;
      } else {
        notification["error"]({
          message: "Error! Search Filters",
          description:
            "We are sorry that something unexpected has happened. Please, search again. Meanwhile, we are looking into it.",
        });
        this.props.TPSAction(TS_GET_SEARCH_FILTERS, null, ERROR);
      }
    }
  }

  handleSearchRefresh(refresh) {
    if (refresh === true) {
      Object.keys(this.props.axiosCancelSearches).map(function () {
        return "Search Requests Cancelled!!!";
      });
      Object.keys(this.props.axiosCancelAnalysis).map(function () {
        return "Analysis Requests Cancelled!!!";
      });
      if (this.props.axiosCancelFilters) {
        this.props.axiosCancelFilters("Filters Request Cancelled");
      }

      let fuzzyCancelToken = Axios.CancelToken;
      let fuzzyAxiosSource = fuzzyCancelToken.source();

      let wildcardCancelToken = Axios.CancelToken;
      let wildcardAxiosSource = wildcardCancelToken.source();

      let standardCancelToken = Axios.CancelToken;
      let standardAxiosSource = standardCancelToken.source();

      let initialsCancelToken = Axios.CancelToken;
      let initialsAxiosSource = initialsCancelToken.source();

      let phoneticCancelToken = Axios.CancelToken;
      let phoneticAxiosSource = phoneticCancelToken.source();

      let booleanCancelToken = Axios.CancelToken;
      let booleanAxiosSource = booleanCancelToken.source();

      let containsCancelToken = Axios.CancelToken;
      let containsAxiosSource = containsCancelToken.source();

      const {
        trademarksSearchAlgo,
        activeTab,
        userTrademark,
        multiwordTrademark,
      } = this.props;

      switch (this.props.trademarksSearchAlgo) {
        case "Flash":
          this.props.handleSearchInfoDispatch({
            trademarksSearchAlgo,
            activeTab,
            userTrademark,
            multiwordTrademark,
            axiosCancelSearches: {
              Fuzzy: fuzzyAxiosSource.cancel,
              Wildcard: wildcardAxiosSource.cancel,
              Standard: standardAxiosSource.cancel,
              Initials: initialsAxiosSource.cancel,
              Phonetic: phoneticAxiosSource.cancel,
              Boolean: booleanAxiosSource.cancel,
              Contains: containsAxiosSource.cancel,
            },
            searchCounter: multiwordTrademark ? 7 : 5,
            reminderStamp: new Date().getTime() + refreshPeriod,
            searchStamp: new Date().getTime() + refreshPeriod,
          });

          if (multiwordTrademark) {
            this.props.getSearchResults(
              {
                ...this.props["Boolean"].searchParameters,
                operation: "search",
                flash_flag: false,
                page: 1,
                search_log_id: null,
              },
              "Boolean",
              booleanAxiosSource.token,
              true,
            );

            this.props.getSearchResults(
              {
                ...this.props["Initials"].searchParameters,
                operation: "search",
                flash_flag: false,
                page: 1,
                search_log_id: null,
              },
              "Initials",
              initialsAxiosSource.token,
              true,
            );
          }

          this.props.getSearchResults(
            {
              ...this.props["Fuzzy"].searchParameters,
              operation: "search",
              flash_flag: false,
              page: 1,
              search_log_id: null,
            },
            "Fuzzy",
            fuzzyAxiosSource.token,
            true,
          );

          this.props.getSearchResults(
            {
              ...this.props["Wildcard"].searchParameters,
              operation: "search",
              flash_flag: false,
              page: 1,
              search_log_id: null,
            },
            "Wildcard",
            wildcardAxiosSource.token,
            true,
          );

          this.props.getSearchResults(
            {
              ...this.props["Standard"].searchParameters,
              operation: "search",
              flash_flag: false,
              page: 1,
              search_log_id: null,
            },
            "Standard",
            standardAxiosSource.token,
            true,
          );

          this.props.getSearchResults(
            {
              ...this.props["Contains"].searchParameters,
              operation: "search",
              flash_flag: false,
              page: 1,
              search_log_id: null,
            },
            "Contains",
            containsAxiosSource.token,
            true,
          );

          this.props.getSearchResults(
            {
              ...this.props["Phonetic"].searchParameters,
              operation: "search",
              flash_flag: false,
              page: 1,
              search_log_id: null,
            },
            "Phonetic",
            phoneticAxiosSource.token,
            true,
          );
          break;

        case "Wildcard":
          this.props.handleSearchInfoDispatch({
            trademarksSearchAlgo,
            activeTab,
            userTrademark,
            multiwordTrademark,
            axiosCancelSearches: {
              Wildcard: wildcardAxiosSource.cancel,
            },
            searchCounter: 1,
            reminderStamp: new Date().getTime() + refreshPeriod,
            searchStamp: new Date().getTime() + refreshPeriod,
          });

          this.props.getSearchResults(
            {
              ...this.props["Wildcard"].searchParameters,
              operation: "search",
              flash_flag: false,
              page: 1,
              search_log_id: null,
            },
            "Wildcard",
            wildcardAxiosSource.token,
            true,
          );
          break;

        case "Initials":
          this.props.handleSearchInfoDispatch({
            trademarksSearchAlgo,
            activeTab,
            userTrademark,
            multiwordTrademark,
            axiosCancelSearches: {
              Initials: initialsAxiosSource.cancel,
            },
            searchCounter: 1,
            reminderStamp: new Date().getTime() + refreshPeriod,
            searchStamp: new Date().getTime() + refreshPeriod,
          });

          this.props.getSearchResults(
            {
              ...this.props["Initials"].searchParameters,
              operation: "search",
              flash_flag: false,
              page: 1,
              search_log_id: null,
            },
            "Initials",
            initialsAxiosSource.token,
            true,
          );

          break;

        case "Fuzzy":
          this.props.handleSearchInfoDispatch({
            trademarksSearchAlgo,
            activeTab,
            userTrademark,
            multiwordTrademark,
            axiosCancelSearches: {
              Fuzzy: fuzzyAxiosSource.cancel,
            },
            searchCounter: 1,
            reminderStamp: new Date().getTime() + refreshPeriod,
            searchStamp: new Date().getTime() + refreshPeriod,
          });

          this.props.getSearchResults(
            {
              ...this.props["Initial"]?.searchParameters,
              operation: "search",
              flash_flag: false,
              page: 1,
              search_log_id: null,
            },
            "Fuzzy",
            fuzzyAxiosSource.token,
            true,
          );
          break;

        case "Boolean":
          this.props.handleSearchInfoDispatch({
            trademarksSearchAlgo,
            activeTab,
            userTrademark,
            multiwordTrademark,
            axiosCancelSearches: {
              Boolean: booleanAxiosSource.cancel,
            },
            searchCounter: 1,
            reminderStamp: new Date().getTime() + refreshPeriod,
            searchStamp: new Date().getTime() + refreshPeriod,
          });

          this.props.getSearchResults(
            {
              ...this.props["Boolean"].searchParameters,
              operation: "search",
              flash_flag: false,
              page: 1,
              search_log_id: null,
            },
            "Boolean",
            booleanAxiosSource.token,
            true,
          );

          break;

        default:
          break;
      }
    } else {
      this.props.TPAction(
        TS_SET_REMINDER_STAMP,
        new Date().getTime() + refreshPeriod,
      );
    }
  }

  async handleQueryChange(query) {
    if (this.props.searchStamp > new Date().getTime()) {
      if (query) {
        const {
          trademarksSearchAlgo,
          activeTab,
          userTrademark,
          multiwordTrademark,
          searchCounter,
          reminderStamp,
          axiosCancelSearches,
          searchStamp,
        } = this.props;

        let CancelToken0 = Axios.CancelToken;
        let axiosSource0 = CancelToken0.source();

        let CancelToken1 = Axios.CancelToken;
        let axiosSource1 = CancelToken1.source();

        const filter_flag = this.props[activeTab].searchParameters.filter_flag;

        if (searchCounter < 0 && this.props.filtersStatus !== LOADING) {
          if (this.props[activeTab].status === LOADING) {
            notification["warning"]({
              message: "Wait For Results",
              description: "Results are being fetched. Kindly, wait for them.",
            });
          } else {
            switch (activeTab) {
              case "Fuzzy":
                if (/^\d+$/.test(query)) {
                  if (query > userTrademark.term.length || query < 2) {
                    notification["warning"]({
                      message: "Fuzz Depth Too Low or High",
                      description:
                        "Your fuzz depth cannot be lower than 2 and higher than the length of the selected trademark.",
                    });

                    break;
                  }

                  this.props.handleSearchInfoDispatch({
                    trademarksSearchAlgo,
                    activeTab,
                    userTrademark,
                    multiwordTrademark,
                    axiosCancelSearches: {
                      ...axiosCancelSearches,
                      [activeTab]: axiosSource0.cancel,
                    },
                    searchCounter: 1,
                    reminderStamp,
                    searchStamp,
                  });

                  await this.props.getSearchResults(
                    {
                      ...this.props[activeTab].searchParameters,
                      operation: "search",
                      filter_flag: false,
                      page: 1,
                      search_log_id: null,
                      fuzzy_depth: query,
                    },
                    activeTab,
                    axiosSource0.token,
                    true,
                  );

                  if (filter_flag) {
                    this.props.TPAction(TS_SET_SEARCHES_CANCEL, {
                      ...axiosCancelSearches,
                      [activeTab]: axiosSource1.cancel,
                    });

                    this.props.getSearchResults(
                      {
                        ...this.props[activeTab].searchParameters,
                        operation: "search",
                        filter_flag,
                        page: 1,
                        fuzzy_depth: query,
                      },
                      activeTab,
                      axiosSource1.token,
                    );
                  }
                } else {
                  notification["warning"]({
                    message: "Not An Integer",
                    description: "Fuzz depth can consist of only numbers.",
                  });
                }
                break;

              case "Boolean":
                if (
                  !(
                    query.includes("[[&]]") ||
                    query.includes("[[!]]") ||
                    query.includes("[[|]]")
                  )
                ) {
                  notification["warning"]({
                    message: "No Boolean Operator Used",
                    description:
                      "Your query must consist of any one of the three boolean operators( [[&]], [[|]], [[!]] ).",
                  });
                  break;
                }

                if (
                  query
                    .replace(/ {1,}/g, " ")
                    .replace(/\[\[&\]\]/g, "")
                    .replace(/\[\[\|\]\]/g, "")
                    .replace(/\[\[!\]\]/g, "")
                    .trim()
                    .search(/[&|!'"()$%_^]/g) !== -1
                ) {
                  notification["warning"]({
                    message: "Invalid Boolean Characters",
                    description:
                      "Your query cannot have & ' \" ( ) $ %_^ | and ! apart from the boolean operators.",
                  });
                  break;
                }

                if (
                  !query
                    .replace(/ {1,}/g, " ")
                    .replace(/\[\[&\]\]/g, " ")
                    .replace(/\[\[\|\]\]/g, " ")
                    .replace(/\[\[!\]\]/g, "")
                    .trim()
                    .includes(" ")
                ) {
                  notification["warning"]({
                    message: "Single Word Query",
                    description: "Your query must have more than 1 word.",
                  });
                  break;
                }

                this.props.handleSearchInfoDispatch({
                  trademarksSearchAlgo,
                  activeTab,
                  userTrademark,
                  multiwordTrademark,
                  axiosCancelSearches: {
                    ...axiosCancelSearches,
                    [activeTab]: axiosSource0.cancel,
                  },
                  searchCounter: 1,
                  reminderStamp,
                  searchStamp,
                });

                await this.props.getSearchResults(
                  {
                    ...this.props[activeTab].searchParameters,
                    operation: "search",
                    filter_flag: false,
                    page: 1,
                    search_log_id: null,
                    query: query.replace(/ {1,}/g, " ").trim(),
                  },
                  activeTab,
                  axiosSource0.token,
                  true,
                );

                if (filter_flag) {
                  this.props.TPAction(TS_SET_SEARCHES_CANCEL, {
                    ...axiosCancelSearches,
                    [activeTab]: axiosSource1.cancel,
                  });

                  this.props.getSearchResults(
                    {
                      ...this.props[activeTab].searchParameters,
                      operation: "search",
                      filter_flag,
                      page: 1,
                      query: query.replace(/ {1,}/g, " ").trim(),
                    },
                    activeTab,
                    axiosSource1.token,
                  );
                }
                break;

              case "Wildcard":
                this.props.handleSearchInfoDispatch({
                  trademarksSearchAlgo,
                  activeTab,
                  userTrademark,
                  multiwordTrademark,
                  axiosCancelSearches: {
                    ...axiosCancelSearches,
                    [activeTab]: axiosSource0.cancel,
                  },
                  searchCounter: 1,
                  reminderStamp,
                  searchStamp,
                });

                await this.props.getSearchResults(
                  {
                    ...this.props[activeTab].searchParameters,
                    operation: "search",
                    filter_flag: false,
                    page: 1,
                    search_log_id: null,
                    query: query
                      .replace(/[&|!'"()$%_^]/g, "")
                      .replace(/ {1,}/g, " ")
                      .trim(),
                  },
                  activeTab,
                  axiosSource0.token,
                  true,
                );

                if (filter_flag) {
                  this.props.TPAction(TS_SET_SEARCHES_CANCEL, {
                    ...axiosCancelSearches,
                    [activeTab]: axiosSource1.cancel,
                  });

                  this.props.getSearchResults(
                    {
                      ...this.props[activeTab].searchParameters,
                      operation: "search",
                      filter_flag,
                      page: 1,
                      query: query
                        .replace(/[&|!'"()$%_^]/g, "")
                        .replace(/ {1,}/g, " ")
                        .trim(),
                    },
                    activeTab,
                    axiosSource1.token,
                  );
                }
                break;

              case "Contains":
                this.props.handleSearchInfoDispatch({
                  trademarksSearchAlgo,
                  activeTab,
                  userTrademark,
                  multiwordTrademark,
                  axiosCancelSearches: {
                    ...axiosCancelSearches,
                    [activeTab]: axiosSource0.cancel,
                  },
                  searchCounter: 1,
                  reminderStamp,
                  searchStamp,
                });

                await this.props.getSearchResults(
                  {
                    ...this.props[activeTab].searchParameters,
                    operation: "search",
                    filter_flag: false,
                    page: 1,
                    search_log_id: null,
                    query: query
                      .replace(/[&:|!'"()$%_^]/g, "")
                      .replace(/ {1,}/g, " ")
                      .trim(),
                  },
                  activeTab,
                  axiosSource0.token,
                  true,
                );

                if (filter_flag) {
                  this.props.TPAction(TS_SET_SEARCHES_CANCEL, {
                    ...axiosCancelSearches,
                    [activeTab]: axiosSource1.cancel,
                  });

                  this.props.getSearchResults(
                    {
                      ...this.props[activeTab].searchParameters,
                      operation: "search",
                      filter_flag,
                      page: 1,
                      query: query.replace(/ {1,}/g, " ").trim(),
                    },
                    activeTab,
                    axiosSource1.token,
                  );
                }
                break;

              case "Standard":
                this.props.handleSearchInfoDispatch({
                  trademarksSearchAlgo,
                  activeTab,
                  userTrademark,
                  multiwordTrademark,
                  axiosCancelSearches: {
                    ...axiosCancelSearches,
                    [activeTab]: axiosSource0.cancel,
                  },
                  searchCounter: 1,
                  reminderStamp,
                  searchStamp,
                });

                await this.props.getSearchResults(
                  {
                    ...this.props[activeTab].searchParameters,
                    operation: "search",
                    filter_flag: false,
                    page: 1,
                    search_log_id: null,
                    query: query.replace(/ {1,}/g, " ").trim(),
                  },
                  activeTab,
                  axiosSource0.token,
                  true,
                );

                if (filter_flag) {
                  this.props.TPAction(TS_SET_SEARCHES_CANCEL, {
                    ...axiosCancelSearches,
                    [activeTab]: axiosSource1.cancel,
                  });

                  this.props.getSearchResults(
                    {
                      ...this.props[activeTab].searchParameters,
                      operation: "search",
                      filter_flag,
                      page: 1,
                      query: query.replace(/ {1,}/g, " ").trim(),
                    },
                    activeTab,
                    axiosSource1.token,
                  );
                }
                break;

              case "Phonetic":
                this.props.handleSearchInfoDispatch({
                  trademarksSearchAlgo,
                  activeTab,
                  userTrademark,
                  multiwordTrademark,
                  axiosCancelSearches: {
                    ...axiosCancelSearches,
                    [activeTab]: axiosSource0.cancel,
                  },
                  searchCounter: 1,
                  reminderStamp,
                  searchStamp,
                });

                await this.props.getSearchResults(
                  {
                    ...this.props[activeTab].searchParameters,
                    operation: "search",
                    filter_flag: false,
                    page: 1,
                    search_log_id: null,
                    query: query
                      .replace(/[^a-zA-Z ]/g, "")
                      .replace(/ {1,}/g, " ")
                      .trim(),
                  },
                  activeTab,
                  axiosSource0.token,
                  true,
                );

                if (filter_flag) {
                  this.props.TPAction(TS_SET_SEARCHES_CANCEL, {
                    ...axiosCancelSearches,
                    [activeTab]: axiosSource1.cancel,
                  });

                  this.props.getSearchResults(
                    {
                      ...this.props[activeTab].searchParameters,
                      operation: "search",
                      filter_flag,
                      page: 1,
                      query: query
                        .replace(/[^a-zA-Z ]/g, "")
                        .replace(/ {1,}/g, " ")
                        .trim(),
                    },
                    activeTab,
                    axiosSource1.token,
                  );
                }
                break;

              default:
                break;
            }
          }
        } else {
          notification["warning"]({
            message: "Wait For Filters",
            description: "Filters are being fetched. Kindly, wait for them.",
          });
        }
      } else {
        notification["warning"]({
          message: "Empty Query",
          description: "No input is provided. Please, enter a valid query.",
        });
      }
    } else {
      this.changeStateValue_L1("modalsVisibility", "needSearchRefresh", true);
    }
  }

  countSelections() {
    const tempArr = [
      ...this.props.searchState.customReportData["fuzzy"].selected,
      ...this.props.searchState.customReportData["contains"].selected,
      ...this.props.searchState.customReportData["wildcard"].selected,
      ...this.props.searchState.customReportData["phonetic"].selected,
      ...this.props.searchState.customReportData["standard"].selected,
      ...this.props.searchState.customReportData["boolean"].selected,
    ];

    const uniqueSelections = new Set(tempArr);

    return uniqueSelections.size;
  }

  handleOpenChange = (flag, key) => {
    if (flag) {
      this.setState({
        open: key,
      });
    } else {
      this.setState({
        open: null,
      });
    }
  };

  render() {
    if (this.props.searchCounter === 0) {
      this.handleGetFilters_Debounced();
    }

    const filterMenuScroll = {
      maxHeight: "260px",
      minWidth: "150px",
      overflowY: "auto",
    };

    const searches = this.props.multiwordTrademark
      ? TS_multiwordSearch
      : TS_singlewordSearch;

    let noFilterData = (
      <Menu>
        <Menu.Item>No Filters Data Found</Menu.Item>
      </Menu>
    );
    let classesMenu = noFilterData;
    let statusesMenu = noFilterData;
    let typesMenu = noFilterData;
    let statesMenu = noFilterData;
    let proprietorsMenu = noFilterData;

    const moreActionsMenu = (
      <Menu
        selectable={true}
        multiple={true}
        selectedKeys={[]}
        style={filterMenuScroll}
      >
        <Menu.Item
          disabled={
            this.props?.searchState?.customReportData?.selectAll === true
          }
          key="Select page"
          onClick={() => {
            this.props.TPAction(TS_SET_STATE, {
              ["customReportData"]: {
                ...this.props.searchState.customReportData,
                ["selectAll"]: false,
                ["selectAllTypes"]: [],
                [this.props.activeTab.toLowerCase()]: {
                  ...this.props.searchState.customReportData[
                    this.props.activeTab.toLowerCase()
                  ],
                  selected: [
                    ...new Set([
                      ...this.props[this.props.activeTab].results.map(
                        (e) => e.id,
                      ),
                      ...this.props.searchState.customReportData[
                        this.props.activeTab.toLowerCase()
                      ].selected,
                    ]),
                  ],
                  select_all: false,
                  search_log_id: this.props[this.props.activeTab].searchLogID,
                },
                applicationsIDs: [
                  ...new Set([
                    ...this.props[this.props.activeTab].results.map(
                      (e) => e.id,
                    ),
                    ...this.props.searchState.customReportData.applicationsIDs,
                  ]),
                ],
              },
            });
          }}
        >
          Select page
        </Menu.Item>
        <Menu.Item
          disabled={
            this.props?.searchState?.customReportData?.selectAll === true
          }
          key="Deselect page"
          onClick={() => {
            this.props.TPAction(TS_SET_STATE, {
              ["customReportData"]: {
                ...this.props.searchState.customReportData,
                ["selectAll"]: false,
                ["selectAllTypes"]: [],
                [this.props.activeTab.toLowerCase()]: {
                  ...this.props.searchState.customReportData[
                    this.props.activeTab.toLowerCase()
                  ],
                  selected: this.props.searchState.customReportData[
                    this.props.activeTab.toLowerCase()
                  ].selected.filter(
                    (e) =>
                      !this.props[this.props.activeTab].results
                        .map((x) => x.id)
                        .includes(e),
                  ),
                  search_log_id: this.props[this.props.activeTab].searchLogID,
                  select_all: false,
                },
                applicationsIDs:
                  this.props.searchState.customReportData.applicationsIDs.filter(
                    (item) =>
                      ![
                        ...this.props[this.props.activeTab].results.map(
                          (e) => e.id,
                        ),
                      ].includes(item),
                  ),
              },
            });
          }}
        >
          Deselect page
        </Menu.Item>
        {/* <Menu.Item
                    key="Select all logicwise"
                    onClick={() => {
                        this.props.TPAction(TS_SET_STATE,{["customReportData"]: {
                            ...this.props.searchState.customReportData,
                            [ "selectAll"]:false,
                            [ "selectAllTypes"]: [...new Set([
                                ...this.props.searchState.customReportData.selectAllTypes, this.props.activeTab])],
                            [this.props.activeTab.toLowerCase()]: {
                                ...this.props.searchState.customReportData[this.props.activeTab.toLowerCase()],
                                select_all:true,
                                search_log_id: this.props[this.props.activeTab].searchLogID,
                            }
                        }});
                    }}  
                >
                
                   {` Select All - ${this.props.activeTab.toLowerCase()}`}
                </Menu.Item>

                <Menu.Item
                    key="DeSelect all logicwise"
                    onClick={() => {
                        this.props.TPAction(TS_SET_STATE,{["customReportData"]: {
                            ...this.props.searchState.customReportData,
                            [ "selectAll"]:false,
                            [ "selectAllTypes"]: [...new Set([
                                ...this.props.searchState.customReportData.selectAllTypes.filter(x => x !== this.props.activeTab)])],
                            [this.props.activeTab.toLowerCase()]: {
                                ...this.props.searchState.customReportData[this.props.activeTab.toLowerCase()],
                                select_all:false
                            }
                        }});
                    }}  
                >
                
                   {` Deselect All - ${this.props.activeTab.toLowerCase()}`}
                </Menu.Item>

                <Menu.Item
                    key="Select all "
                    onClick={() => {
                        this.props.TPAction(TS_SET_STATE,{["customReportData"]: {
                            ...this.props.searchState.customReportData,
                            selectAll: true,
                            [ "selectAllTypes"]: [],
                            [this.props.activeTab.toLowerCase()]: {
                                ...this.props.searchState.customReportData[this.props.activeTab.toLowerCase()],
                            },
                            fuzzy: {
                                select_all: true,
                                selected: [],
                                deselected: [],
                                search_log_id: this.props["Fuzzy"].searchLogID,
                            },
                            standard: {
                                select_all: true,
                                selected: [],
                                deselected: [],
                                search_log_id: this.props["Standard"].searchLogID,
                            },
                            contains: {
                                select_all: true,
                                selected: [],
                                deselected: [],
                                search_log_id: this.props["Contains"].searchLogID,
                            },
                            wildcard: {
                                select_all: true,
                                selected: [],
                                deselected: [],
                                search_log_id: this.props["Wildcard"].searchLogID,
                            },
                            phonetic: {
                                select_all: true,
                                selected: [],
                                deselected: [],
                                search_log_id: this.props["Phonetic"].searchLogID,
                            },
                            initials: {
                                select_all: true,
                                selected: [],
                                deselected: [],
                                search_log_id: this.props["Initials"].searchLogID,
                            },
                            boolean: {
                                select_all: true,
                                selected: [],
                                deselected: [],
                                search_log_id: this.props["Boolean"].searchLogID,
                            },

                        }});
                    }}  
                >
                    Select All
                </Menu.Item> */}

        <Menu.Item
          key="DeSelect all "
          onClick={() => {
            this.props.TPAction(TS_SET_STATE, {
              ["customReportData"]: {
                ...this.props.searchState.customReportData,
                ["applicationsIDs"]: [],
                ["selectAllTypes"]: [],
                selectAll: false,

                [this.props.activeTab.toLowerCase()]: {
                  ...this.props.searchState.customReportData[
                    this.props.activeTab.toLowerCase()
                  ],
                },
                fuzzy: {
                  select_all: false,
                  selected: [],
                  deselected: [],
                  search_log_id: this.props["Fuzzy"].searchLogID,
                },
                standard: {
                  select_all: false,
                  selected: [],
                  deselected: [],
                  search_log_id: this.props["Standard"].searchLogID,
                },
                contains: {
                  select_all: false,
                  selected: [],
                  deselected: [],
                  search_log_id: this.props["Contains"].searchLogID,
                },
                wildcard: {
                  select_all: false,
                  selected: [],
                  deselected: [],
                  search_log_id: this.props["Wildcard"].searchLogID,
                },
                phonetic: {
                  select_all: false,
                  selected: [],
                  deselected: [],
                  search_log_id: this.props["Phonetic"].searchLogID,
                },
                initials: {
                  select_all: false,
                  selected: [],
                  deselected: [],
                  search_log_id: this.props["Initials"].searchLogID,
                },
                boolean: {
                  select_all: false,
                  selected: [],
                  deselected: [],
                  search_log_id: this.props["Boolean"].searchLogID,
                },
              },
            });
          }}
        >
          Deselect All
        </Menu.Item>
      </Menu>
    );

    if (this.props.filters.classes.length > 0) {
      classesMenu = (
        <SearchFilterSearchBasic
          data={this.props?.filters?.classes}
          searchFilters={this.state?.filtersSearchTerms}
          search_key={"classes"}
          filter_key={"classes"}
          selectedFilters={this.props?.searchState?.selectedFilters}
          onSelect={this.changeStateValue_L1}
          noSearch={"NOSearch"}
        />
      );
    }

    if (this.props.filters.statuses.length > 0) {
      statusesMenu = (
        <SearchFilterSearchBasic
          data={this.props?.filters?.statuses}
          searchFilters={this.props?.searchState?.filtersSearchTerms}
          search_key={"statuses"}
          filter_key={"statuses"}
          selectedFilters={this.props?.searchState?.selectedFilters}
          onSelect={this.changeStateValue_L1}
        />
      );
    }

    if (this.props.filters.states.length > 0) {
      statesMenu = (
        <SearchFilterSearchBasic
          data={this.props?.filters?.states}
          searchFilters={this.props?.searchState?.filtersSearchTerms}
          search_key={"states"}
          filter_key={"states"}
          selectedFilters={this.props?.searchState?.selectedFilters}
          onSelect={this.changeStateValue_L1}
        />
      );
    }

    if (this.props.filters.types.length > 0) {
      typesMenu = (
        <SearchFilterSearchBasic
          data={this.props?.filters?.types}
          searchFilters={this.props?.searchState?.filtersSearchTerms}
          search_key={"types"}
          filter_key={"types"}
          selectedFilters={this.props?.searchState?.selectedFilters}
          onSelect={this.changeStateValue_L1}
          type={"name"}
          noSearch={"NOSearch"}
        />
      );
    }

    if (this.props.filters.proprietors.length > 0) {
      let filteredProprietors = this.props.filters.proprietors.filter(
        (eachProprietor) =>
          eachProprietor.name
            .toLowerCase()
            .indexOf(
              `${this.props.searchState.filtersSearchTerms.proprietors}`,
            ) !== -1,
      );

      proprietorsMenu = (
        <>
          <Input
            placeholder="Search proprietors"
            onChange={(event) =>
              this.changeStateValue_L1_Debounced(
                "filtersSearchTerms",
                "proprietors",
                event.target.value.toLowerCase(),
              )
            }
            allowClear
          />
          <Menu
            onScroll={(x) => {
              const { scrollTop, scrollHeight, clientHeight } = x.target;
              if (scrollTop + clientHeight >= scrollHeight - 20) {
                this.setState({ page: this.state.page + 100 });
              }
            }}
            className="List"
            style={{ height: 150, width: 450 }}
          >
            {filteredProprietors.slice(0, this.state.page).map((item) => (
              <Menu.Item
                key={item.id}
                style={{
                  padding: "5px",
                  cursor:
                    this.props.searchState.selectedFilters.deselectProprietors.includes(
                      `${item.id}`,
                    )
                      ? "not-allowed"
                      : "",
                }}
                className={`
        ${
          this.props.searchState.selectedFilters.selectProprietors.indexOf(
            `${item.id}`,
          ) !== -1
            ? "ant-dropdown-menu-item-selected"
            : ""
        }
      `}
                onClick={() => {
                  if (
                    !this.props.searchState.selectedFilters.deselectProprietors.includes(
                      `${item.id}`,
                    )
                  ) {
                    let { selectProprietors } =
                      this.props.searchState.selectedFilters;
                    if (selectProprietors.indexOf(`${item.id}`) !== -1) {
                      this.changeStateValue_L1(
                        "selectedFilters",
                        "selectProprietors",
                        selectProprietors.filter(
                          (eachProprietor) => eachProprietor !== `${item.id}`,
                        ),
                      );
                    } else {
                      this.changeStateValue_L1(
                        "selectedFilters",
                        "selectProprietors",
                        [...selectProprietors, `${item.id}`],
                      );
                    }
                  }
                }}
              >
                <div style={{ display: "flex", alignItems: "end" }}>
                  <Button
                    type="link"
                    disabled={this.props.searchState.selectedFilters.selectProprietors.includes(
                      `${item.id}`,
                    )}
                    style={{
                      color: `${
                        this.props.searchState.selectedFilters.deselectProprietors.includes(
                          `${item.id}`,
                        )
                          ? "#ff0000"
                          : ""
                      }`,
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (
                        !this.props.searchState.selectedFilters.selectProprietors.includes(
                          `${item.id}`,
                        )
                      ) {
                        let { deselectProprietors } =
                          this.props.searchState.selectedFilters;
                        if (deselectProprietors.indexOf(`${item.id}`) !== -1) {
                          this.changeStateValue_L1(
                            "selectedFilters",
                            "deselectProprietors",
                            deselectProprietors.filter(
                              (eachProprietor) =>
                                eachProprietor !== `${item.id}`,
                            ),
                          );
                        } else {
                          this.changeStateValue_L1(
                            "selectedFilters",
                            "deselectProprietors",
                            [...deselectProprietors, `${item.id}`],
                          );
                        }
                      }
                    }}
                  >
                    <Tooltip placement="left" title="Exclude">
                      <StopOutlined />
                    </Tooltip>
                  </Button>
                  <span
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      // whiteSpace: "nowrap",
                      width: 300,
                    }}
                  >
                    {item.name}
                  </span>
                </div>
              </Menu.Item>
            ))}
          </Menu>
        </>
      );
    }

    const searchesMenu = (
      <>
        <Menu
          selectable={true}
          selectedKeys={[this.props.activeTab]}
          onClick={({ key }) => {
            this.props.TPAction(CHANGE_SEARCH_RESULTS_TAB, key);
            let element = document.getElementById("Results-Header");
            if (element !== null) {
              element.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
              });
            }
          }}
          style={{
            maxHeight: "200px",
            width: "200px",
            fontSize: "16px",
            overflow: "auto",
            boxShadow: "0px 0px 5px #c9c9c9",
          }}
        >
          {searches.map((eachClass) => (
            <Menu.Item key={eachClass} style={{ fontSize: "16px" }}>
              {eachClass}
            </Menu.Item>
          ))}
        </Menu>
        <div style={{ textAlign: "center", marginTop: "15px" }}>
          <Tooltip
            placement="left"
            open={
              this.props.searchState.filtersVisibility.trademarksSearchAlgos
            }
            title="What are these search types?"
          >
            <span
              style={{
                padding: "10px",
                backgroundColor: "#ffffff",
                borderRadius: "50%",
                boxShadow: "0px 0px 5px #4285f4",
                cursor: "pointer",
              }}
              onClick={() =>
                this.changeStateValue_L1(
                  "modalsVisibility",
                  "trademarksAlgosInfo",
                  true,
                )
              }
            >
              <QuestionCircleFilled
                style={{
                  fontSize: "20px",
                  verticalAlign: "0px",
                  color: "#4285f4",
                }}
              />
            </span>
          </Tooltip>
        </div>
      </>
    );

    return (
      <>
        <header
          style={{
            textAlign: "center",
            marginBottom: "10px",
            width: "100%",
            alignSelf: "center",
          }}
        >
          <Row justify="center">
            <Col span={20}>
              <Space>
                <Dropdown
                  dropdownRender={() => classesMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "classesMenu")
                  }
                  open={this.state.open === "classesMenu"}
                >
                  <Button
                    className={`${
                      this.props.searchState.selectedFilters.classes.length > 0
                        ? "Search-Filter-Selected"
                        : "Search-Filter-Empty"
                    }`}
                  >
                    Classes
                    <DownOutlined style={{ fontSize: "12px" }} />
                  </Button>
                </Dropdown>
                <Dropdown
                  dropdownRender={() => statusesMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "statusesMenu")
                  }
                  open={this.state.open === "statusesMenu"}
                >
                  <Button
                    className={`${
                      this.props.searchState.selectedFilters.statuses.length > 0
                        ? "Search-Filter-Selected"
                        : "Search-Filter-Empty"
                    }`}
                  >
                    Statuses
                    <DownOutlined style={{ fontSize: "12px" }} />
                  </Button>
                </Dropdown>
                <Dropdown
                  dropdownRender={() => statesMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "statesMenu")
                  }
                  open={this.state.open === "statesMenu"}
                >
                  <Button
                    className={`${
                      this.props.searchState.selectedFilters.states.length > 0
                        ? "Search-Filter-Selected"
                        : "Search-Filter-Empty"
                    }`}
                  >
                    States
                    <DownOutlined style={{ fontSize: "12px" }} />
                  </Button>
                </Dropdown>
                <Dropdown
                  dropdownRender={() => typesMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "typesMenu")
                  }
                  open={this.state.open === "typesMenu"}
                >
                  <Button
                    className={`${
                      this.props.searchState.selectedFilters.types.length > 0
                        ? "Search-Filter-Selected"
                        : "Search-Filter-Empty"
                    }`}
                  >
                    TM Types
                    <DownOutlined style={{ fontSize: "12px" }} />
                  </Button>
                </Dropdown>
                <Dropdown
                  dropdownRender={() => proprietorsMenu}
                  overlayClassName="filter-menu"
                  autoAdjustOverflow
                  onOpenChange={(flag) =>
                    this.handleOpenChange(flag, "proprietorsMenu")
                  }
                  open={this.state.open === "proprietorsMenu"}
                >
                  <Button
                    className={`${
                      this.props.searchState.selectedFilters.selectProprietors
                        .length > 0 ||
                      this.props.searchState.selectedFilters.deselectProprietors
                        .length > 0
                        ? "Search-Filter-Selected"
                        : "Search-Filter-Empty"
                    }`}
                  >
                    Proprietors
                    <DownOutlined style={{ fontSize: "12px" }} />
                  </Button>
                </Dropdown>
                <Dropdown
                  overlay={
                    <DateRangePicker
                      type={"Application Date"}
                      startValue={
                        this.props.searchState.selectedFilters.doaStart
                      }
                      endValue={this.props.searchState.selectedFilters.doaEnd}
                      setDate={(val) => this.setDate1(val)}
                      range={this.state.range1}
                      handleDateString={(startValue, endValue, dateString) => {
                        this.props.TPAction(TS_SET_STATE, {
                          selectedFilters: {
                            ...this.props.searchState.selectedFilters,
                            doaStart: startValue,
                            doaEnd: endValue,
                            doa:
                              startValue === null || endValue === null
                                ? null
                                : dateString,
                          },
                        });
                      }}
                    />
                  }
                  overlayClassName="filter-menu"
                >
                  <Button
                    className={`${
                      this.props.searchState.selectedFilters.doa
                        ? "Search-Filter-Selected"
                        : "Search-Filter-Empty"
                    }`}
                  >
                    DoA
                    <DownOutlined style={{ fontSize: "12px" }} />
                  </Button>
                </Dropdown>
                <Dropdown
                  overlay={
                    <>
                      <DateRangePicker
                        type={"Date of Usage"}
                        startValue={
                          this.props.searchState.selectedFilters.douStart
                        }
                        endValue={this.props.searchState.selectedFilters.douEnd}
                        setDate={(val) => this.setDate2(val)}
                        range={this.state.range2}
                        handleDateString={(startValue, endValue, dateString) =>
                          this.props.TPAction(TS_SET_STATE, {
                            selectedFilters: {
                              ...this.props.searchState.selectedFilters,
                              douStart: startValue,
                              douEnd: endValue,
                              dou:
                                startValue === null || endValue === null
                                  ? null
                                  : dateString,
                            },
                          })
                        }
                      />
                      <div style={{ textAlign: "center", lineHeight: "32px" }}>
                        <Checkbox
                          checked={
                            this.props.searchState.selectedFilters.douProposed
                          }
                          onChange={(event) =>
                            this.changeStateValue_L1(
                              "selectedFilters",
                              "douProposed",
                              event.target.checked,
                            )
                          }
                        >
                          Proposed
                        </Checkbox>
                      </div>
                    </>
                  }
                  overlayClassName="filter-menu"
                >
                  <Button
                    className={`${
                      this.props.searchState.selectedFilters.dou ||
                      this.props.searchState.selectedFilters.douProposed
                        ? "Search-Filter-Selected"
                        : "Search-Filter-Empty"
                    }`}
                  >
                    DoU
                    <DownOutlined style={{ fontSize: "12px" }} />
                  </Button>
                </Dropdown>
                <Tooltip title="Search application name">
                  <Input.Search
                    data-step="3"
                    data-intro="Search a mark within the results."
                    data-highlightclass="Data-Highlight-Class-General"
                    data-tooltipclass="Data-Tooltip-Class-General"
                    value={this.props.searchState.selectedFilters.containsTerm}
                    onChange={(event) =>
                      this.changeStateValue_L1(
                        "selectedFilters",
                        "containsTerm",
                        event.target.value,
                      )
                    }
                    style={{ width: "170px", margin: "0px 3px" }}
                    placeholder="Search application name"
                    onSearch={() => this.handleFilters()}
                    loading={this.props.filtersStatus === LOADING}
                    enterButton={
                      <Button
                        style={{
                          backgroundColor: "#4285f4",
                          color: "#ffffff",
                          fontWeight: "600",
                        }}
                        loading={
                          this.props.filtersStatus === LOADING ? true : false
                        }
                      >
                        {this.props.filtersStatus === LOADING ? "" : "Apply"}
                      </Button>
                    }
                  />
                </Tooltip>
                <Button
                  onClick={() => this.handleFilters(true)}
                  style={{ padding: "0px 3px" }}
                  type="link"
                >
                  Reset
                </Button>
                <Badge
                  overflowCount={9999}
                  count={
                    // this.props.searchState.customReportData.applicationsIDs.length ||
                    (this.props.searchState.customReportData.selectAll ||
                    TS_multiwordSearch.some(
                      (x) =>
                        this.props.searchState.customReportData[
                          x.toLocaleLowerCase()
                        ].select_all,
                    )
                      ? "All"
                      : this.props.searchState.customReportData.applicationsIDs
                          .length) ||
                    this.props.searchState.customReportData.applicationsIDs
                      .length
                    // this.countSelections()
                  }
                >
                  <Button
                    style={{ padding: "0px 5px" }}
                    disabled={
                      TS_multiwordSearch.some(
                        (x) =>
                          this.props.searchState.customReportData[
                            [this.props.activeTab.toLowerCase()]
                          ].selected.length > 0,
                      )
                        ? false
                        : !TS_multiwordSearch.some(
                            (x) =>
                              this.props.searchState.customReportData[
                                x.toLocaleLowerCase()
                              ].select_all,
                          )
                    }
                    onClick={() => {
                      this.props.getTrademarkReportStatus(
                        {
                          check: true,
                          report_source: "MARK",
                          mark_id: this.props.userTrademark.id,
                        },
                        TS_GET_REPORT_AVAILABILITY,
                      );
                      this.changeStateValue_L1(
                        "drawersVisibility",
                        "customReport",
                        true,
                      );
                    }}
                  >
                    Create Report
                  </Button>
                </Badge>
                {/* <Icon
                      onClick={() => this.changeStateValue_L1("customReportData", "applicationsIDs", [])}
                      type="minus-circle"
                      style={{
                          margin: "0px 5px",
                          fontSize: "14px",
                          visibility: `${this.props.searchState.customReportData.applicationsIDs.length === 0 ? "hidden" : ""}`
                      }}
                  /> */}
                <Dropdown
                  disabled={this.props[this.props.activeTab].status === LOADING}
                  overlay={moreActionsMenu}
                  overlayClassName="Filters-Menu"
                  open={
                    this.props.searchState.filtersVisibility.moreActionsMenu
                  }
                  onVisibleChange={(event) =>
                    this.changeStateValue_L1(
                      "filtersVisibility",
                      "moreActionsMenu",
                      event,
                    )
                  }
                >
                  <Button
                    data-step="2"
                    data-intro="Select/Deselect all marks on the current page from here."
                    data-highlightclass="Data-Highlight-Class-General"
                    data-tooltipclass="Data-Tooltip-Class-General"
                    className="Search-More-Actions"
                  >
                    <UnorderedListOutlined
                      style={{
                        fontSize: "16px",
                        position: "relative",
                        bottom: "2px",
                      }}
                    />
                  </Button>
                </Dropdown>
                {this.props.trademarksSearchAlgo === "Flash" && (
                  <Dropdown overlay={searchesMenu}>
                    <Button
                      style={{
                        color: "#ffffff",
                        fontWeight: "600",
                        border: "none",
                        backgroundColor: "#4285f4",
                        padding: "0px 5px",
                      }}
                      data-step="1"
                      data-intro="Access the different result tabs."
                      data-highlightclass="Data-Highlight-Class-General"
                      data-tooltipclass="Data-Tooltip-Class-General"
                    >
                      {this.props.activeTab}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                )}
              </Space>
            </Col>
          </Row>
        </header>
        <div
          className="General-Search-Content Flex-Content vertical_scroll"
          style={{
            height:
              this.props.searchType === "Image_Search"
                ? `${
                    this.props.searchAlert.length > 0
                      ? "calc(100vh - 290px)"
                      : "calc(100vh - 244px)"
                  }`
                : `${
                    this.props.searchAlert.length > 0
                      ? "calc(100vh - 220px)"
                      : "calc(100vh - 180px)"
                  }`,
          }}
        >
          <header
            id="Results-Header"
            style={{
              backgroundColor: "#ffffff",
              margin: "5px",
              width: "100%",
              height: "fit-content",
            }}
          >
            {this.props.trademarksSearchAlgo === "Flash" && (
              <Alert
                message={
                  <div>
                    <InfoCircleFilled
                      style={{
                        fontSize: "20px",
                        verticalAlign: "0px",
                        color: "#4285f4",
                        marginRight: "10px",
                        backgroundColor: "#ffffff",
                        border: "2px solid #ffffff",
                        borderRadius: "50%",
                      }}
                    />
                    For the best analysis please go through all search result
                    tabs such as Fuzzy, Phonetic, Wildcard etc.
                  </div>
                }
                type="info"
              />
            )}
            {this.props.trademarksSearchAlgo === "Flash" &&
              !(this.props[this.props.activeTab].status === LOADING) &&
              [
                "Wildcard",
                "Boolean",
                "Contains",
                "Fuzzy",
                "Standard",
                "Phonetic",
              ].includes(this.props.activeTab) && (
                <div
                  data-step="6"
                  data-intro="Change your query to get more customized results."
                  data-highlightclass="Data-Highlight-Class-General"
                  data-tooltipclass="Data-Tooltip-Class-General"
                  style={{ marginTop: "5px" }}
                >
                  {this.props.activeTab === "Contains" && (
                    <Input.Search
                      defaultValue={
                        this.props["Contains"].searchParameters.query
                      }
                      style={{ width: "100%" }}
                      placeholder="Enter contains term"
                      onSearch={(query) => this.handleQueryChange(query)}
                    />
                  )}
                  {this.props.activeTab === "Boolean" && (
                    <>
                      <Tooltip title="And" placement="bottom">
                        <Button
                          onClick={() => {
                            document.getElementById("Boolean-Query").value = `${
                              document.getElementById("Boolean-Query").value
                            }[[&]]`;
                            this.setState({
                              booleanSearch:
                                document.getElementById("Boolean-Query").value,
                            });
                          }}
                          type="link"
                        >
                          {"[[&]]"}
                        </Button>
                      </Tooltip>
                      <Tooltip title="Or" placement="bottom">
                        <Button
                          onClick={() => {
                            document.getElementById("Boolean-Query").value = `${
                              document.getElementById("Boolean-Query").value
                            }[[|]]`;
                            this.setState({
                              booleanSearch:
                                document.getElementById("Boolean-Query").value,
                            });
                          }}
                          type="link"
                        >
                          {"[[|]]"}
                        </Button>
                      </Tooltip>
                      <Tooltip title="Not" placement="bottom">
                        <Button
                          onClick={() => {
                            document.getElementById("Boolean-Query").value = `${
                              document.getElementById("Boolean-Query").value
                            }[[!]]`;
                            this.setState({
                              booleanSearch:
                                document.getElementById("Boolean-Query").value,
                            });
                          }}
                          type="link"
                        >
                          {"[[!]]"}
                        </Button>
                      </Tooltip>
                      <Input.Search
                        id="Boolean-Query"
                        defaultValue={
                          this.props["Boolean"].searchParameters.query
                        }
                        value={this.state.booleanSearch}
                        onChange={(event) =>
                          this.setState({ booleanSearch: event.target.value })
                        }
                        style={{ width: "calc(100% - 180px)" }}
                        placeholder="Enter boolean query"
                        onSearch={(query) => this.handleQueryChange(query)}
                      />
                    </>
                  )}
                  {this.props.activeTab === "Wildcard" && (
                    <>
                      <Tooltip title="Multiple characters" placement="bottom">
                        <Button
                          onClick={() => {
                            document.getElementById("Wildcard-Query").value =
                              `${
                                document.getElementById("Wildcard-Query").value
                              }[[*]]`;
                            this.setState({
                              wildcardSearch:
                                document.getElementById("Wildcard-Query").value,
                            });
                          }}
                          type="link"
                        >
                          {"[[*]]"}
                        </Button>
                      </Tooltip>
                      <Tooltip title="Single character" placement="bottom">
                        <Button
                          onClick={() => {
                            document.getElementById("Wildcard-Query").value =
                              `${
                                document.getElementById("Wildcard-Query").value
                              }[[?]]`;
                            this.setState({
                              wildcardSearch:
                                document.getElementById("Wildcard-Query").value,
                            });
                          }}
                          type="link"
                        >
                          {"[[?]]"}
                        </Button>
                      </Tooltip>
                      <Input.Search
                        id="Wildcard-Query"
                        defaultValue={
                          this.props["Wildcard"].searchParameters.query
                        }
                        value={this.state.wildcardSearch}
                        style={{ width: "calc(100% - 120px)" }}
                        placeholder="Enter wildcard query"
                        onChange={(event) =>
                          this.setState({ wildcardSearch: event.target.value })
                        }
                        onSearch={(query) => this.handleQueryChange(query)}
                      />
                    </>
                  )}
                  {this.props.activeTab === "Fuzzy" && (
                    <InputNumber
                      onPressEnter={(event) => {
                        this.handleQueryChange(event.target.value);
                      }}
                      defaultValue={
                        this.props[this.props.activeTab].searchParameters
                          .fuzzy_depth
                      }
                      placeholder="Enter fuzz depth"
                      style={{ width: "100%" }}
                    />
                  )}
                  {this.props.activeTab === "Standard" && (
                    <Input.Search
                      defaultValue={
                        this.props["Standard"].searchParameters.query
                      }
                      style={{ width: "100%" }}
                      placeholder="Enter contains term"
                      onSearch={(query) => this.handleQueryChange(query)}
                    />
                  )}
                  {this.props.activeTab === "Phonetic" && (
                    <Input.Search
                      defaultValue={
                        this.props["Phonetic"].searchParameters.query
                      }
                      style={{ width: "100%" }}
                      placeholder="Enter contains term"
                      onSearch={(query) => this.handleQueryChange(query)}
                    />
                  )}
                </div>
              )}
          </header>

          {this.props[this.props.activeTab].status === LOADING && (
            <Spin size="large" style={{ width: "100%" }} />
          )}

          {this.props.selectedView === "1" &&
            this.props[this.props.activeTab].status === SUCCESS &&
            this.props[this.props.activeTab].results.length > 0 &&
            this.props[this.props.activeTab].results.map((eachMark) => (
              <GeneralMarkCard
                key={eachMark.application_number}
                eachMark={eachMark}
                onSelectMark={(eachMark, checked) => {
                  let { applicationsIDs } =
                    this.props.searchState.customReportData;

                  if (checked) {
                    this.props.TPAction(TS_SET_STATE, {
                      ["customReportData"]: {
                        ...this.props.searchState.customReportData,
                        ["selectAll"]: false,
                        ["selectAllTypes"]: [],
                        fuzzy: this.props.Fuzzy.results
                          .map(({ id }) => id)
                          .some((id) => id === eachMark.id)
                          ? {
                              ...this.props.searchState.customReportData[
                                "fuzzy"
                              ],
                              selected: [
                                eachMark.id,
                                ...this.props.searchState.customReportData[
                                  "fuzzy"
                                ].selected,
                              ],
                              select_all: false,
                            }
                          : {
                              ...this.props.searchState.customReportData[
                                "fuzzy"
                              ],
                            },
                        standard: this.props.Standard.results
                          .map(({ id }) => id)
                          .some((id) => id === eachMark.id)
                          ? {
                              ...this.props.searchState.customReportData[
                                "standard"
                              ],
                              selected: [
                                eachMark.id,
                                ...this.props.searchState.customReportData[
                                  "standard"
                                ].selected,
                              ],
                              select_all: false,
                            }
                          : {
                              ...this.props.searchState.customReportData[
                                "standard"
                              ],
                            },
                        contains: this.props.Contains.results
                          .map(({ id }) => id)
                          .some((id) => id === eachMark.id)
                          ? {
                              ...this.props.searchState.customReportData[
                                "contains"
                              ],
                              selected: [
                                eachMark.id,
                                ...this.props.searchState.customReportData[
                                  "contains"
                                ].selected,
                              ],
                              select_all: false,
                            }
                          : {
                              ...this.props.searchState.customReportData[
                                "contains"
                              ],
                            },
                        wildcard: this.props.Wildcard.results
                          .map(({ id }) => id)
                          .some((id) => id === eachMark.id)
                          ? {
                              ...this.props.searchState.customReportData[
                                "wildcard"
                              ],
                              selected: [
                                eachMark.id,
                                ...this.props.searchState.customReportData[
                                  "wildcard"
                                ].selected,
                              ],
                              select_all: false,
                            }
                          : {
                              ...this.props.searchState.customReportData[
                                "wildcard"
                              ],
                            },
                        phonetic: this.props.Phonetic.results
                          .map(({ id }) => id)
                          .some((id) => id === eachMark.id)
                          ? {
                              ...this.props.searchState.customReportData[
                                "phonetic"
                              ],
                              selected: [
                                eachMark.id,
                                ...this.props.searchState.customReportData[
                                  "phonetic"
                                ].selected,
                              ],
                              select_all: false,
                            }
                          : {
                              ...this.props.searchState.customReportData[
                                "phonetic"
                              ],
                            },
                        initials: this.props.Initials.results
                          .map(({ id }) => id)
                          .some((id) => id === eachMark.id)
                          ? {
                              ...this.props.searchState.customReportData[
                                "initials"
                              ],
                              selected: [
                                eachMark.id,
                                ...this.props.searchState.customReportData[
                                  "initials"
                                ].selected,
                              ],
                              select_all: false,
                            }
                          : {
                              ...this.props.searchState.customReportData[
                                "initials"
                              ],
                            },
                        boolean: this.props.Boolean.results
                          .map(({ id }) => id)
                          .some((id) => id === eachMark.id)
                          ? {
                              ...this.props.searchState.customReportData[
                                "boolean"
                              ],
                              selected: [
                                eachMark.id,
                                ...this.props.searchState.customReportData[
                                  "boolean"
                                ].selected,
                              ],
                              select_all: false,
                            }
                          : {
                              ...this.props.searchState.customReportData[
                                "boolean"
                              ],
                            },
                        imagesearch: this.props.ImageSearch.results
                          .map(({ id }) => id)
                          .some((id) => id === eachMark.id)
                          ? {
                              ...this.props.searchState.customReportData[
                                "imagesearch"
                              ],
                              selected: [
                                eachMark.id,
                                ...this.props.searchState.customReportData[
                                  "imagesearch"
                                ].selected,
                              ],
                              select_all: false,
                            }
                          : {
                              ...this.props.searchState.customReportData[
                                "imagesearch"
                              ],
                            },
                        applicationsIDs: [eachMark.id, ...applicationsIDs],
                      },
                    });
                  } else {
                    this.props.TPAction(TS_SET_STATE, {
                      ["customReportData"]: {
                        ...this.props.searchState.customReportData,
                        ["selectAll"]: false,
                        ["selectAllTypes"]: [],
                        [this.props.activeTab.toLowerCase()]: {
                          ...this.props.searchState.customReportData[
                            this.props.activeTab.toLowerCase()
                          ],
                          selected: this.props.searchState.customReportData[
                            this.props.activeTab.toLowerCase()
                          ].selected.filter((e) => e !== eachMark.id),
                          select_all: false,
                        },
                        fuzzy: this.props.Fuzzy.results
                          .map(({ id }) => id)
                          .some((id) => id === eachMark.id)
                          ? {
                              ...this.props.searchState.customReportData[
                                "fuzzy"
                              ],
                              selected: this.props.searchState.customReportData[
                                "fuzzy"
                              ].selected.filter((e) => e !== eachMark.id),
                              select_all: false,
                            }
                          : {
                              ...this.props.searchState.customReportData[
                                "fuzzy"
                              ],
                            },
                        standard: this.props.Standard.results
                          .map(({ id }) => id)
                          .some((id) => id === eachMark.id)
                          ? {
                              ...this.props.searchState.customReportData[
                                "standard"
                              ],
                              selected: this.props.searchState.customReportData[
                                "standard"
                              ].selected.filter((e) => e !== eachMark.id),
                              select_all: false,
                            }
                          : {
                              ...this.props.searchState.customReportData[
                                "standard"
                              ],
                            },
                        contains: this.props.Contains.results
                          .map(({ id }) => id)
                          .some((id) => id === eachMark.id)
                          ? {
                              ...this.props.searchState.customReportData[
                                "contains"
                              ],
                              selected: this.props.searchState.customReportData[
                                "contains"
                              ].selected.filter((e) => e !== eachMark.id),
                              select_all: false,
                            }
                          : {
                              ...this.props.searchState.customReportData[
                                "contains"
                              ],
                            },
                        wildcard: this.props.Wildcard.results
                          .map(({ id }) => id)
                          .some((id) => id === eachMark.id)
                          ? {
                              ...this.props.searchState.customReportData[
                                "wildcard"
                              ],
                              selected: this.props.searchState.customReportData[
                                "wildcard"
                              ].selected.filter((e) => e !== eachMark.id),
                              select_all: false,
                            }
                          : {
                              ...this.props.searchState.customReportData[
                                "wildcard"
                              ],
                            },
                        phonetic: this.props.Phonetic.results
                          .map(({ id }) => id)
                          .some((id) => id === eachMark.id)
                          ? {
                              ...this.props.searchState.customReportData[
                                "phonetic"
                              ],
                              selected: this.props.searchState.customReportData[
                                "phonetic"
                              ].selected.filter((e) => e !== eachMark.id),
                              select_all: false,
                            }
                          : {
                              ...this.props.searchState.customReportData[
                                "phonetic"
                              ],
                            },
                        initials: this.props.Initials.results
                          .map(({ id }) => id)
                          .some((id) => id === eachMark.id)
                          ? {
                              ...this.props.searchState.customReportData[
                                "initials"
                              ],
                              selected: this.props.searchState.customReportData[
                                "initials"
                              ].selected.filter((e) => e !== eachMark.id),
                              select_all: false,
                            }
                          : {
                              ...this.props.searchState.customReportData[
                                "initials"
                              ],
                            },
                        boolean: this.props.Boolean.results
                          .map(({ id }) => id)
                          .some((id) => id === eachMark.id)
                          ? {
                              ...this.props.searchState.customReportData[
                                "boolean"
                              ],
                              selected: [
                                eachMark.id,
                                ...this.props.searchState.customReportData[
                                  "boolean"
                                ].selected,
                              ],
                              selected: this.props.searchState.customReportData[
                                "boolean"
                              ].selected.filter((e) => e !== eachMark.id),
                              select_all: false,
                            }
                          : {
                              ...this.props.searchState.customReportData[
                                "boolean"
                              ],
                            },
                        applicationsIDs: applicationsIDs.filter(
                          (e) => e !== eachMark.id,
                        ),
                      },
                    });
                  }
                }}
                selectAllCards={
                  this.props.searchState.customReportData.selectAll ||
                  this.props.searchState.customReportData.selectAllTypes.includes(
                    this.props.activeTab,
                  ) ||
                  this.props.searchState.customReportData[
                    this.props.activeTab.toLowerCase()
                  ].select_all
                }
                checked={
                  this.props.searchState.customReportData[
                    this.props.activeTab.toLowerCase()
                  ].selected.includes(eachMark.id) ||
                  this.props.searchState.customReportData[
                    this.props.activeTab.toLowerCase()
                  ].select_all ||
                  // this.props.searchState.customReportData.applicationsIDs.includes(eachMark.id) ||
                  this.props.searchState.customReportData.selectAll ||
                  this.props.searchState.customReportData.selectAllTypes.includes(
                    this.props.activeTab,
                  )
                }
                changeStateValue_L1={(x, y, application) => {
                  sessionStorage.setItem("application", application);
                  window.open(`/search/trademark-profile`);
                }}
                styleCard={{ textAlign: "left", height: "inherit" }}
                similarity={null}
                status={checkNA(eachMark.status)}
                proprietorClickable={true}
                onProprietorClick={(proprietor) => {
                  sessionStorage.setItem("proprietor", proprietor);
                  window.open(`/search/proprietor-profile/${proprietor}`);
                }}
              />
            ))}

          {/* When the data is received successfully and selectedVew is 1, cards will be rendered by the following code. */}
          {this.props.selectedView === "2" &&
            this.props[this.props.activeTab].status === SUCCESS &&
            this.props[this.props.activeTab].results.length > 0 && (
              <table className="Table" style={{ height: "inherit" }}>
                <thead className="Table-Head">
                  <tr className="Table-Head-Row">
                    <th
                      className="Table-Head-Cell Black-Shadow"
                      style={{ width: "4%", minWidth: "50px" }}
                    ></th>
                    <th className="Table-Head-Cell Red-Shadow Red-BG">MARK</th>
                    <th className="Table-Head-Cell Red-Shadow Red-BG">
                      STATUS
                    </th>
                    <th className="Table-Head-Cell Red-Shadow Red-BG">TYPE</th>
                    <th className="Table-Head-Cell Red-Shadow Red-BG">
                      PROPRIETORS
                    </th>
                    <th className="Table-Head-Cell Red-Shadow Red-BG">
                      CLASSES
                    </th>
                    <th className="Table-Head-Cell Red-Shadow Red-BG">
                      DoA | DoU
                    </th>
                    <th
                      className="Table-Head-Cell Red-Shadow Red-BG"
                      style={{ width: "4%", minWidth: "50px" }}
                    >
                      {this.props.searchState.tableDescriptionRowsVisibility ? (
                        <Tooltip placement="left" title="Hide Descriptions">
                          <MinusSquareFilled
                            style={{
                              fontSize: "20px",
                              verticalAlign: "baseline",
                            }}
                            onClick={() =>
                              this.props.TPAction(TS_SET_STATE, {
                                tableDescriptionRowsVisibility: false,
                              })
                            }
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip placement="left" title="Show Descriptions">
                          <PlusSquareFilled
                            style={{
                              fontSize: "20px",
                              verticalAlign: "baseline",
                            }}
                            onClick={() =>
                              this.props.TPAction(TS_SET_STATE, {
                                tableDescriptionRowsVisibility: true,
                              })
                            }
                          />
                        </Tooltip>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody className="Table-Body">
                  {/* When table data is received successfully and is not empty, this table will render */}
                  {this.props[this.props.activeTab].status === SUCCESS &&
                    this.props[this.props.activeTab].count > 0 &&
                    this.props[this.props.activeTab].results.map(
                      (eachMark, rowIndex) => {
                        return (
                          <React.Fragment key={eachMark.application_number}>
                            <tr
                              className={`Table-Body-Row ${
                                rowIndex % 2 === 0 ? "Row-Even" : "Row-Odd"
                              }`}
                            >
                              <td>
                                <Checkbox
                                  disabled={
                                    this.props.searchState.customReportData
                                      .selectAll ||
                                    this.props.searchState.customReportData.selectAllTypes.includes(
                                      this.props.activeTab,
                                    )
                                  }
                                  onChange={(event) => {
                                    let { applicationsIDs } =
                                      this.props.searchState.customReportData;

                                    if (event.target.checked) {
                                      this.props.TPAction(TS_SET_STATE, {
                                        ["customReportData"]: {
                                          ...this.props.searchState
                                            .customReportData,
                                          ["selectAll"]: false,
                                          ["selectAllTypes"]: [],
                                          fuzzy: this.props.Fuzzy.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["fuzzy"],
                                                selected: [
                                                  eachMark.id,
                                                  ...this.props.searchState
                                                    .customReportData["fuzzy"]
                                                    .selected,
                                                ],
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["fuzzy"],
                                              },
                                          standard: this.props.Standard.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["standard"],
                                                selected: [
                                                  eachMark.id,
                                                  ...this.props.searchState
                                                    .customReportData[
                                                    "standard"
                                                  ].selected,
                                                ],
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["standard"],
                                              },
                                          contains: this.props.Contains.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["contains"],
                                                selected: [
                                                  eachMark.id,
                                                  ...this.props.searchState
                                                    .customReportData[
                                                    "contains"
                                                  ].selected,
                                                ],
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["contains"],
                                              },
                                          wildcard: this.props.Wildcard.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["wildcard"],
                                                selected: [
                                                  eachMark.id,
                                                  ...this.props.searchState
                                                    .customReportData[
                                                    "wildcard"
                                                  ].selected,
                                                ],
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["wildcard"],
                                              },
                                          phonetic: this.props.Phonetic.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["phonetic"],
                                                selected: [
                                                  eachMark.id,
                                                  ...this.props.searchState
                                                    .customReportData[
                                                    "phonetic"
                                                  ].selected,
                                                ],
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["phonetic"],
                                              },
                                          initials: this.props.Initials.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["initials"],
                                                selected: [
                                                  eachMark.id,
                                                  ...this.props.searchState
                                                    .customReportData[
                                                    "initials"
                                                  ].selected,
                                                ],
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["initials"],
                                              },
                                          boolean: this.props.Boolean.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["boolean"],
                                                selected: [
                                                  eachMark.id,
                                                  ...this.props.searchState
                                                    .customReportData["boolean"]
                                                    .selected,
                                                ],
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["boolean"],
                                              },
                                          applicationsIDs: [
                                            eachMark.id,
                                            ...applicationsIDs,
                                          ],
                                        },
                                      });
                                    } else {
                                      this.props.TPAction(TS_SET_STATE, {
                                        ["customReportData"]: {
                                          ...this.props.searchState
                                            .customReportData,
                                          ["selectAll"]: false,
                                          ["selectAllTypes"]: [],
                                          [this.props.activeTab.toLowerCase()]:
                                            {
                                              ...this.props.searchState
                                                .customReportData[
                                                this.props.activeTab.toLowerCase()
                                              ],
                                              selected:
                                                this.props.searchState.customReportData[
                                                  this.props.activeTab.toLowerCase()
                                                ].selected.filter(
                                                  (e) => e !== eachMark.id,
                                                ),
                                              select_all: false,
                                            },
                                          fuzzy: this.props.Fuzzy.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["fuzzy"],
                                                selected:
                                                  this.props.searchState.customReportData[
                                                    "fuzzy"
                                                  ].selected.filter(
                                                    (e) => e !== eachMark.id,
                                                  ),
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["fuzzy"],
                                              },
                                          standard: this.props.Standard.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["standard"],
                                                selected:
                                                  this.props.searchState.customReportData[
                                                    "standard"
                                                  ].selected.filter(
                                                    (e) => e !== eachMark.id,
                                                  ),
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["standard"],
                                              },
                                          contains: this.props.Contains.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["contains"],
                                                selected:
                                                  this.props.searchState.customReportData[
                                                    "contains"
                                                  ].selected.filter(
                                                    (e) => e !== eachMark.id,
                                                  ),
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["contains"],
                                              },
                                          wildcard: this.props.Wildcard.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["wildcard"],
                                                selected:
                                                  this.props.searchState.customReportData[
                                                    "wildcard"
                                                  ].selected.filter(
                                                    (e) => e !== eachMark.id,
                                                  ),
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["wildcard"],
                                              },
                                          phonetic: this.props.Phonetic.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["phonetic"],
                                                selected:
                                                  this.props.searchState.customReportData[
                                                    "phonetic"
                                                  ].selected.filter(
                                                    (e) => e !== eachMark.id,
                                                  ),
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["phonetic"],
                                              },
                                          initials: this.props.Initials.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["initials"],
                                                selected:
                                                  this.props.searchState.customReportData[
                                                    "initials"
                                                  ].selected.filter(
                                                    (e) => e !== eachMark.id,
                                                  ),
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["initials"],
                                              },
                                          boolean: this.props.Boolean.results
                                            .map(({ id }) => id)
                                            .some((id) => id === eachMark.id)
                                            ? {
                                                ...this.props.searchState
                                                  .customReportData["boolean"],
                                                selected: [
                                                  eachMark.id,
                                                  ...this.props.searchState
                                                    .customReportData["boolean"]
                                                    .selected,
                                                ],
                                                selected:
                                                  this.props.searchState.customReportData[
                                                    "boolean"
                                                  ].selected.filter(
                                                    (e) => e !== eachMark.id,
                                                  ),
                                                select_all: false,
                                              }
                                            : {
                                                ...this.props.searchState
                                                  .customReportData["boolean"],
                                              },
                                          applicationsIDs:
                                            applicationsIDs.filter(
                                              (e) => e !== eachMark.id,
                                            ),
                                        },
                                      });
                                    }
                                    // if (event.target.checked) {
                                    //     this.props.TPAction(TS_SET_STATE,{["customReportData"]: {
                                    //         ...this.props.searchState.customReportData,
                                    //         ["selectAll"]: false,
                                    //         [ "selectAllTypes"]:[],
                                    //         [this.props.activeTab.toLowerCase()]:{
                                    //             ...this.props.searchState.customReportData[this.props.activeTab.toLowerCase()],
                                    //             selected:[eachRow.id, ...this.props.searchState.customReportData[this.props.activeTab.toLowerCase()].selected],
                                    //             select_all: false,
                                    //         },
                                    //     }});
                                    // } else {
                                    //     this.props.TPAction(TS_SET_STATE,{["customReportData"]: {
                                    //         ...this.props.searchState.customReportData,
                                    //         ["selectAll"]: false,
                                    //         [ "selectAllTypes"]:[],
                                    //         [this.props.activeTab.toLowerCase()]:{...this.props.searchState.customReportData[this.props.activeTab.toLowerCase()],
                                    //             selected:this.props.searchState.customReportData[this.props.activeTab.toLowerCase()].selected.filter(e => e !== eachRow.id),
                                    //             select_all:false
                                    //         }
                                    //     }});
                                    // }
                                  }}
                                  checked={
                                    this.props.searchState.customReportData[
                                      this.props.activeTab.toLowerCase()
                                    ].selected.includes(eachMark.id) ||
                                    this.props.searchState.customReportData[
                                      this.props.activeTab.toLowerCase()
                                    ].select_all ||
                                    // this.props.searchState.customReportData.applicationsIDs.includes(eachRow.id) ||
                                    this.props.searchState.customReportData
                                      .selectAll ||
                                    this.props.searchState.customReportData.selectAllTypes.includes(
                                      this.props.activeTab,
                                    )
                                  }
                                  // checked={
                                  //     this.props.searchState.customReportData[this.props.activeTab.toLowerCase()].selected.includes(eachRow.id) ||
                                  //     this.props.searchState.customReportData.applicationsIDs.includes(eachRow.id) ||
                                  //     this.props.searchState.customReportData.selectAll ||
                                  //     this.props.searchState.customReportData.selectAllTypes.includes(this.props.activeTab)
                                  // }
                                  // onChange={(event) => {
                                  //     let { applicationsIDs } = this.props.searchState.customReportData;
                                  //     if (event.target.checked) {
                                  //         this.changeStateValue_L1(
                                  //             "customReportData", "applicationsIDs",
                                  //             [eachRow.id, ...applicationsIDs]
                                  //         );
                                  //     } else {
                                  //         this.changeStateValue_L1(
                                  //             "customReportData", "applicationsIDs",
                                  //             applicationsIDs.filter(e => e !== eachRow.id)
                                  //         );
                                  //     }
                                  // }}
                                />
                              </td>
                              <td>
                                <span>{eachMark.applied_for}</span>
                                <br />
                                {eachMark.associated_image && (
                                  <>
                                    <span>
                                      <img
                                        src={eachMark.associated_image}
                                        height="70px"
                                        width="70px"
                                        alt=""
                                      />
                                    </span>
                                    <br />
                                  </>
                                )}
                                <span
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      "application",
                                      eachMark.application_number,
                                    );
                                    window.open(`/search/trademark-profile`);
                                  }}
                                  className="Application-Number"
                                >
                                  {eachMark.application_number}
                                </span>
                              </td>
                              <td>{eachMark.status}</td>
                              <td>{eachMark?.type?.name}</td>
                              <td>
                                {eachMark.proprietor.map(
                                  (eachProprietor, index, allProprietors) => (
                                    <React.Fragment key={eachProprietor.id}>
                                      <span>
                                        <span>
                                          <strong>Proprietor:</strong>{" "}
                                          <span
                                            onClick={() => {
                                              sessionStorage.setItem(
                                                "proprietor",
                                                eachProprietor.id,
                                              );
                                              window.open(
                                                `/search/proprietor-profile/${eachProprietor.id}`,
                                              );
                                            }}
                                            className="Application-Number"
                                          >
                                            {eachProprietor.name}
                                          </span>
                                        </span>
                                        <br />
                                        <span>
                                          <strong>Address:</strong>{" "}
                                          {eachProprietor.address}
                                        </span>
                                      </span>
                                      {allProprietors.length === index + 1 ? (
                                        ""
                                      ) : (
                                        <hr />
                                      )}
                                    </React.Fragment>
                                  ),
                                )}
                              </td>
                              <td>
                                {eachMark.associated_class.map(
                                  (eachClass, index, allClasses) =>
                                    allClasses.length === index + 1
                                      ? `${eachClass}`
                                      : `${eachClass}, `,
                                )}
                              </td>
                              <td>
                                <span>
                                  DoA:{" "}
                                  {eachMark.application_date
                                    ? eachMark.application_date
                                    : "N/A"}
                                </span>
                                <br />
                                <span>
                                  DoU:{" "}
                                  {eachMark.date_of_usage
                                    ? eachMark.date_of_usage
                                    : "Proposed"}
                                </span>
                              </td>
                              <td></td>
                            </tr>
                            {this.props.searchState
                              .tableDescriptionRowsVisibility && (
                              <tr
                                className={`Table-Body-Row ${
                                  rowIndex % 2 === 0 ? "Row-Even" : "Row-Odd"
                                }`}
                              >
                                <td></td>
                                <td
                                  colSpan="6"
                                  style={{ verticalAlign: "top" }}
                                >
                                  {eachMark.description
                                    ? eachMark.description
                                    : ""}
                                </td>
                                <td></td>
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      },
                    )}
                </tbody>
              </table>
            )}

          {/* Data is received successfully but it is empty */}
          {this.props[this.props.activeTab].status === SUCCESS &&
            this.props[this.props.activeTab].results.length === 0 && (
              <Empty style={{ width: "100%" }} />
            )}

          {/* Request fails or there is any kind of error while fetching the data */}
          {this.props[this.props.activeTab].status === ERROR && (
            <div style={{ display: "table", height: "50vh", width: "100%" }}>
              <div
                style={{
                  display: "table-cell",
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
              >
                <h1>
                  Unfortunately, due to the occurrence of an error, we were
                  unable to fetch the data from the server.
                </h1>
                <h1>
                  We apologize for the inconvenience. Please, try again after
                  some time.
                </h1>
              </div>
            </div>
          )}
        </div>
        <footer className="General-Footer">
          <Radio.Group
            onChange={(event) =>
              this.props.TPAction(TS_SELECT_VIEW, event.target.value)
            }
            value={this.props.selectedView}
            style={{ float: "left" }}
          >
            <Radio.Button value="1">Cards</Radio.Button>
            <Radio.Button value="2">
              <Tooltip title={"View marks along with the G&S Description"}>
                <span
                  data-step="4"
                  data-intro={`View marks with the G&S description.`}
                  data-highlightclass="Data-Highlight-Class-General"
                  data-tooltipclass="Data-Tooltip-Class-General"
                >
                  Table
                </span>
              </Tooltip>
            </Radio.Button>
          </Radio.Group>
          <Tooltip title="Results Analysis" placement="right">
            <Button
              data-step="5"
              data-intro="Graphical representation of similar marks."
              data-highlightclass="Data-Highlight-Class-General"
              data-tooltipclass="Data-Tooltip-Class-General"
              style={{ float: "left", margin: "0px 5px" }}
              onClick={() =>
                this.changeStateValue_L1(
                  "drawersVisibility",
                  "resultsAnalysis",
                  true,
                )
              }
            >
              <PieChartOutlined style={{ fontSize: "14px" }} />
            </Button>
          </Tooltip>
          <Pagination
            showQuickJumper
            defaultCurrent={1}
            current={this.props[this.props.activeTab].pageNumber}
            total={this.props[this.props.activeTab].count}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} `}
            pageSize={100}
            showSizeChanger={false}
            onChange={(page) => {
              if (this.props[this.props.activeTab].status === LOADING) {
                notification["warning"]({
                  message: "Request Under Process",
                  description:
                    "A request is under process. Kindly, wait for its completion",
                });
              } else {
                let CancelToken = Axios.CancelToken;
                let axiosSource = CancelToken.source();

                this.props.TPAction(TS_SET_SEARCHES_CANCEL, {
                  ...this.props.axiosCancelSearches,
                  [this.props.activeTab]: axiosSource.cancel,
                });

                if (this.props.activeTab === "ImageSearch") {
                  let myParams = {
                    ...this.props["ImageSearch"].searchParameters,
                  };
                  delete myParams.image;
                  // let newParams = {...myParams}
                  this.props.getImageSearchResults(
                    {
                      ...myParams,
                      search_log_id:
                        this.props[this.props.activeTab].searchLogID,
                      operation: "search",
                      page,
                    },
                    "ImageSearch",
                  );
                } else {
                  this.props.getSearchResults(
                    {
                      ...this.props[this.props.activeTab].searchParameters,
                      search_log_id:
                        this.props[this.props.activeTab].searchLogID,
                      page,
                      flash_flag: this.props.trademarksSearchAlgo === "Flash",
                    },
                    this.props.activeTab,
                    axiosSource.token,
                  );
                }
              }
            }}
          />
        </footer>

        <Drawer
          title={<h2>Results Analysis({`${this.props.activeTab}`})</h2>}
          placement="right"
          width={800}
          closable={true}
          open={this.props.searchState.drawersVisibility.resultsAnalysis}
          onClose={() =>
            this.changeStateValue_L1(
              "drawersVisibility",
              "resultsAnalysis",
              false,
            )
          }
          className="analysis-body"
          bodyStyle={{
            padding: 0,
          }}
        >
          <div className="analysis-body-container vertical_scroll">
            {this.props.searchState.drawersVisibility.resultsAnalysis && (
              <ResultsAnalysis
                analysisStatus={this.props[this.props.activeTab].analysisStatus}
                statusesDistribution={
                  this.props[this.props.activeTab].analysis
                    ? this.props[this.props.activeTab].analysis.status
                    : []
                }
                classesDistribution={
                  this.props[this.props.activeTab].analysis
                    ? this.props[this.props.activeTab].analysis.class
                    : []
                }
                typesDistribution={
                  this.props[this.props.activeTab].analysis
                    ? this.props[this.props.activeTab].analysis.type
                    : []
                }
              />
            )}
          </div>
        </Drawer>

        <Drawer
          title={<h2>Custom Report Details</h2>}
          placement="right"
          width={400}
          closable={false}
          open={this.props.searchState.drawersVisibility.customReport}
          onClose={() =>
            this.changeStateValue_L1("drawersVisibility", "customReport", false)
          }
        >
          <form>
            Enter the name of your report:
            <br />
            <Input
              onChange={(event) => {
                if (/^[ \w!?:_\-()@#,;<>[\]{}|]*$/g.test(event.target.value)) {
                  this.changeStateValue_L1(
                    "customReportData",
                    "reportName",
                    event.target.value,
                  );
                }
              }}
              value={this.props.searchState.customReportData.reportName}
              placeholder="Report's name (Optional)"
            />
            <br />
            <br />
            {this.props.reportAvailabilityStatus === SUCCESS &&
              this.props.reportAvailability &&
              this.props.reportAvailability.id && (
                <>
                  Choose an action:
                  <br />
                  <Radio.Group
                    value={this.props.searchState.customReportData.operation}
                    onChange={(event) =>
                      this.changeStateValue_L1(
                        "customReportData",
                        "operation",
                        event.target.value,
                      )
                    }
                  >
                    <Radio value="APPEND">Add marks to existing report</Radio>
                    <br />
                    <Radio value="CLEAR">
                      Remove previous marks from the report
                    </Radio>
                  </Radio.Group>
                  <br />
                  <br />
                </>
              )}
            Choose your preferred report type:
            <br />
            <Radio.Group
              value={this.props.searchState.customReportData.reportType}
              onChange={(event) =>
                this.changeStateValue_L1(
                  "customReportData",
                  "reportType",
                  event.target.value,
                )
              }
            >
              <Radio value="URGENT">Urgent Report</Radio>
              <br />
              <Radio value="REALTIME">Realtime report</Radio>
            </Radio.Group>
            <br />
            <br />
            {this.props.searchState.customReportData.selectAll ||
            TS_multiwordSearch.some(
              (x) =>
                this.props.searchState.customReportData[x.toLocaleLowerCase()]
                  .select_all,
            ) ? (
              this.props.searchState.customReportData.reportType !==
                "REALTIME" && (
                <>
                  {" "}
                  Choose your preferred report format: <br />
                  <Checkbox
                    checked={this.props.searchState.customReportData.reportFormat.includes(
                      EXCEL,
                    )}
                    onChange={(event) => {
                      if (event.target.checked) {
                        this.changeStateValue_L1(
                          "customReportData",
                          "reportFormat",
                          [
                            ...this.props.searchState.customReportData
                              .reportFormat,
                            EXCEL,
                          ],
                        );
                      } else {
                        this.changeStateValue_L1(
                          "customReportData",
                          "reportFormat",
                          this.props.searchState.customReportData.reportFormat.filter(
                            (e) => e !== EXCEL,
                          ),
                        );
                      }
                    }}
                  >
                    EXCEL
                  </Checkbox>
                  <br />
                  <Tooltip
                    placement="left"
                    title="Generated bifurcated excel based on each Search algorithm"
                  >
                    <Checkbox
                      checked={this.props.searchState.customReportData.reportFormat.includes(
                        BI_EXCEL,
                      )}
                      onChange={(event) => {
                        if (event.target.checked) {
                          this.changeStateValue_L1(
                            "customReportData",
                            "reportFormat",
                            [
                              ...this.props.searchState.customReportData
                                .reportFormat,
                              BI_EXCEL,
                            ],
                          );
                        } else {
                          this.changeStateValue_L1(
                            "customReportData",
                            "reportFormat",
                            this.props.searchState.customReportData.reportFormat.filter(
                              (e) => e !== BI_EXCEL,
                            ),
                          );
                        }
                      }}
                    >
                      BIFURCATED EXCEL
                    </Checkbox>
                  </Tooltip>
                  <br />
                  <br />
                  <Alert
                    type="warning"
                    message="Note: If no format is selected, the report won't be sent to your e-mail but the changes will get reflected in the reports section."
                  />
                </>
              )
            ) : (
              <>
                {" "}
                Choose your preferred report format: <br />
                <Checkbox
                  checked={this.props.searchState.customReportData.reportFormat.includes(
                    PDF,
                  )}
                  onChange={(event) => {
                    if (event.target.checked) {
                      this.changeStateValue_L1(
                        "customReportData",
                        "reportFormat",
                        [
                          ...this.props.searchState.customReportData
                            .reportFormat,
                          PDF,
                        ],
                      );
                    } else {
                      this.changeStateValue_L1(
                        "customReportData",
                        "reportFormat",
                        this.props.searchState.customReportData.reportFormat.filter(
                          (e) => e !== PDF,
                        ),
                      );
                    }
                  }}
                >
                  PDF
                </Checkbox>
                <br />
                <Checkbox
                  checked={this.props.searchState.customReportData.reportFormat.includes(
                    EXCEL,
                  )}
                  onChange={(event) => {
                    if (event.target.checked) {
                      this.changeStateValue_L1(
                        "customReportData",
                        "reportFormat",
                        [
                          ...this.props.searchState.customReportData
                            .reportFormat,
                          EXCEL,
                        ],
                      );
                    } else {
                      this.changeStateValue_L1(
                        "customReportData",
                        "reportFormat",
                        this.props.searchState.customReportData.reportFormat.filter(
                          (e) => e !== EXCEL,
                        ),
                      );
                    }
                  }}
                >
                  EXCEL
                </Checkbox>
                <br />
                <br />
                <Alert
                  type="warning"
                  message="Note: If no format is selected, the report won't be sent to your e-mail but the changes will get reflected in the reports section."
                />
              </>
            )}
            <br />
            <br />
            <Button
              type="primary"
              ghost
              style={{ marginRight: "10px" }}
              disabled={
                this.props.reportAvailabilityStatus !== SUCCESS ||
                (this.props.reportAvailability &&
                  this.props.reportAvailability.id &&
                  this.props.reportAvailability.available === false)
              }
              onClick={() => this.handleConfirmReport_Debounced()}
            >
              Confirm
            </Button>
            <Button
              danger
              ghost
              onClick={() =>
                this.changeStateValue_L1(
                  "drawersVisibility",
                  "customReport",
                  false,
                )
              }
            >
              Cancel
            </Button>
          </form>
        </Drawer>

        {/* This Modal will open when the question mark in searches menu is clicked */}
        <TrademarksAlgosInfo
          visible={this.props.searchState.modalsVisibility.trademarksAlgosInfo}
          onClose={() =>
            this.changeStateValue_L1(
              "modalsVisibility",
              "trademarksAlgosInfo",
              false,
            )
          }
        />

        {/* Search refresh reminder modal */}
        <Modal
          title="Search Refresh"
          closable={false}
          maskClosable={false}
          open={this.props.reminderStamp < new Date().getTime()}
          onOk={() => this.handleSearchRefresh(true)}
          onCancel={() => this.handleSearchRefresh(false)}
          okText="Refresh"
          cancelText="Snooze"
        >
          <p>
            It has been more than 2 hours since you searched for{" "}
            <strong>{`${this.props?.userTrademark?.term ?? ""}`}</strong>.
          </p>
          <p>
            There is a chance that some new trademarks has been added to the
            database.
          </p>
          <p>
            Do you want us to refresh the data for you or do you want this
            reminder to be snoozed?
          </p>
          <br />
          <br />
          <Alert
            type="warning"
            message={
              <div>
                Note:
                <br />
                <p>
                  <strong>1.</strong> Refreshing the data will take you to the
                  first page.
                </p>
                <p>
                  <strong>2.</strong> Marks selected for the report will remain
                  selected.
                </p>
                <p>
                  <strong>3.</strong> Your filters will reset but they will stay
                  selected in the dropdowns so you can simply click apply if you
                  ant to filter them.
                </p>
              </div>
            }
          />
        </Modal>

        {/* Need Search refresh to chane query reminder modal */}
        <Modal
          title="Need Search Refresh"
          closable={false}
          maskClosable={false}
          open={this.props.searchState.modalsVisibility.needSearchRefresh}
          onOk={() => {
            this.handleSearchRefresh(true);
            this.changeStateValue_L1(
              "modalsVisibility",
              "needSearchRefresh",
              false,
            );
          }}
          onCancel={() =>
            this.changeStateValue_L1(
              "modalsVisibility",
              "needSearchRefresh",
              false,
            )
          }
          okText="Refresh"
          cancelText="Not now"
        >
          <p>
            It has been more than 2 hours since you searched or refreshed search
            for <strong>{`${this.props?.userTrademark?.term ?? ""}`}</strong>.
          </p>
          <p>
            To change the queries you need to refresh the search first. After
            that you can change the query.
          </p>
          <p>Do you want us to refresh the data for you?</p>
          <br />
          <br />
          <Alert
            type="warning"
            message={
              <div>
                Note:
                <br />
                <p>
                  <strong>1.</strong> Refreshing the data will take you to the
                  first page.
                </p>
                <p>
                  <strong>2.</strong> Marks selected for the report will remain
                  selected.
                </p>
                <p>
                  <strong>3.</strong> Your filters will reset but they will stay
                  selected in the dropdowns so you can simply click apply if you
                  ant to filter them.
                </p>
              </div>
            }
          />
        </Modal>
      </>
    );
  }

  componentWillUnmount() {}
}

const mapDispatchToProps = {
  getSearchResults,
  getTrademarkReportStatus,
  TPAction,
  TPSAction,
  postReport,
  patchTrademarkReport,
  logOut,
  handleSearchInfoDispatch,
  getImageSearchResults,
};

function mapStateToProps(state) {
  const {
    Fuzzy,
    Standard,
    Contains,
    Wildcard,
    Phonetic,
    Initials,
    Boolean,
    ImageSearch,
    imageFile,
    trademarksSearchAlgo,
    activeTab,
    userTrademark,
    multiwordTrademark,
    filters,
    selectedView,
    axiosCancelSearches,
    reportAvailability,
    reportAvailabilityStatus,
    searchCounter,
    filtersStatus,
    reminderStamp,
    axiosCancelAnalysis,
    axiosCancelFilters,
    searchStamp,
    searchState,
  } = state.searchState.trademarksSearch;
  return deepFreeze({
    Fuzzy,
    Standard,
    Contains,
    Wildcard,
    Phonetic,
    Initials,
    Boolean,
    ImageSearch,
    imageFile,
    trademarksSearchAlgo,
    activeTab,
    userTrademark,
    multiwordTrademark,
    filters,
    selectedView,
    axiosCancelSearches,
    reportAvailability,
    reportAvailabilityStatus,
    searchCounter,
    filtersStatus,
    reminderStamp,
    searchPath: state.searchState.searchBar.searchPath,
    axiosCancelAnalysis,
    axiosCancelFilters,
    searchStamp,
    searchAlert: state.alertMessages.search,
    searchState,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(TrademarksSearch);
