import { 
    CORRESPONDENCE,
    PSEUDO_CORRESPONDENCE,
    OPPOSED_CORRESPONDENCE,
    PSEUDO_OPPOSED_CORRESPONDENCE,

    PENDING_EVIDENCE,
    PSEUDO_PENDING_EVIDENCE,
    OPPOSED_PENDING_EVIDENCE,
    PSEUDO_OPPOSED_PENDING_EVIDENCE,

    OBJECTED,
    PSEUDO_OBJECTED,
    OPPOSED_OBJECTED,
    PSEUDO_OPPOSED_OBJECTED,

    CHECK_FAIL,
    PSEUDO_CHECK_FAIL,
    OPPOSED_CHECK_FAIL,
    PSEUDO_OPPOSED_CHECK_FAIL,

    OPPOSED,
    PSEUDO_OPPOSED,
    OPPOSED_OPPOSED,
    PSEUDO_OPPOSED_OPPOSED,

    DASHBOARD_REPLY_FILED_TO_CORRESPONDENCE

} from '../../actions/managerActions/trademarkDashboard/types'; 
//--------IP-SUITE CONSTANTS--------
import { LOADING, SUCCESS, ERROR } from '../../../constants/commonConstants/generalConstants'; 

export default function( state = {
        pseudo: false,

        correspondence: null,
        correspondenceStatus: null,
        correspondencePageNumber: 1,

        pseudoCorrespondence: null,
        pseudoCorrespondenceStatus: null,
        pseudoCorrespondencePageNumber: 1,

        opposedCorrespondence: null,
        opposedCorrespondenceStatus: null,
        opposedCorrespondencePageNumber: 1,

        pseudoOpposedCorrespondence: null,
        pseudoOpposedCorrespondenceStatus: null,
        pseudoOpposedCorrespondencePageNumber: 1,

        pendingEvidence: null,
        pendingEvidenceStatus: null,
        pendingEvidencePageNumber: 1,

        pseudoPendingEvidence: null,
        pseudoPendingEvidenceStatus: null,
        pseudoPendingEvidencePageNumber: 1,

        opposedPendingEvidence: null,
        opposedPendingEvidenceStatus: null,
        opposedPendingEvidencePageNumber: 1,

        pseudoOpposedPendingEvidence: null,
        pseudoOpposedPendingEvidenceStatus: null,
        pseudoOpposedPendingEvidencePageNumber: 1,

        objected: null,
        objectedStatus: null,
        objectedPageNumber: 1,

        pseudoObjected: null,
        pseudoObjectedStatus: null,
        pseudoObjectedPageNumber: 1,

        opposedObjected: null,
        opposedObjectedStatus: null,
        opposedObjectedPageNumber: 1,

        pseudoOpposedObjected: null,
        pseudoOpposedObjectedStatus: null,
        pseudoOpposedObjectedPageNumber: 1,

        checkFail: null,
        checkFailStatus: null,
        checkFailPageNumber: 1,

        pseudoCheckFail: null,
        pseudoCheckFailStatus: null,
        pseudoCheckFailPageNumber: 1,

        opposedCheckFail: null,
        opposedCheckFailStatus: null,
        opposedCheckFailPageNumber: 1,

        pseudoOpposedCheckFail: null,
        pseudoOpposedCheckFailStatus: null,
        pseudoOpposedCheckFailPageNumber: 1,

        opppsed: null,
        opppsedStatus: null,
        opppsedPageNumber: 1,

        pseudoOpppsed: null,
        pseudoOpppsedStatus: null,
        pseudoOpppsedPageNumber: 1,

        opposedOpppsed: null,
        opposedOpppsedStatus: null,
        opposedOpppsedPageNumber: 1,

        pseudoOpposedOpppsed: null,
        pseudoOpposedOpppsedStatus: null,
        pseudoOpposedOpppsedPageNumber: 1,

        replyFiledMessage: null, 
        dashboardReplyFiledStatus: null,

    }, action ){
    
    switch (action.type) {
        case CORRESPONDENCE:
            return {
                ...state,
                pseudo: action.pseudo,
                correspondence: action.payload,
                correspondenceStatus: action.status,
                correspondencePageNumber: action.pageNumber
            };

        case PSEUDO_CORRESPONDENCE:
            return {
                ...state,
                pseudo: action.pseudo,
                pseudoCorrespondence: action.payload,
                pseudoCorrespondenceStatus: action.status,
                pseudoCorrespondencePageNumber: action.pageNumber
            };

        case OPPOSED_CORRESPONDENCE:
            return {
                ...state,
                pseudo: action.pseudo,
                opposedCorrespondence: action.payload,
                opposedCorrespondenceStatus: action.status,
                opposedCorrespondencePageNumber: action.pageNumber
            };

        case PSEUDO_OPPOSED_CORRESPONDENCE:
            return {
                ...state,
                pseudo: action.pseudo,
                pseudoOpposedCorrespondence: action.payload,
                pseudoOpposedCorrespondenceStatus: action.status,
                pseudoOpposedCorrespondencePageNumber: action.pageNumber
            };

        
        case PENDING_EVIDENCE:
            return {
                ...state,
                pseudo: action.pseudo,
                pendingEvidence: action.payload,
                pendingEvidenceStatus: action.status,
                pendingEvidencePageNumber: action.pageNumber
            };

        case PSEUDO_PENDING_EVIDENCE:
            return {
                ...state,
                pseudo: action.pseudo,
                pseudoPendingEvidence: action.payload,
                pseudoPendingEvidenceStatus: action.status,
                pseudoPendingEvidencePageNumber: action.pageNumber
            };

        case OPPOSED_PENDING_EVIDENCE:
            return {
                ...state,
                pseudo: action.pseudo,
                opposedPendingEvidence: action.payload,
                opposedPendingEvidenceStatus: action.status,
                opposedPendingEvidencePageNumber: action.pageNumber
            };

        case PSEUDO_OPPOSED_PENDING_EVIDENCE:
            return {
                ...state,
                pseudo: action.pseudo,
                pseudoOpposedPendingEvidence: action.payload,
                pseudoOpposedPendingEvidenceStatus: action.status,
                pseudoOpposedPendingEvidencePageNumber: action.pageNumber
            };


        case OBJECTED:
            return {
                ...state,
                pseudo: action.pseudo,
                objected: action.payload,
                objectedStatus: action.status,
                objectedPageNumber: action.pageNumber
            };

        case PSEUDO_OBJECTED:
            return {
                ...state,
                pseudo: action.pseudo,
                pseudoObjected: action.payload,
                pseudoObjectedStatus: action.status,
                pseudoObjectedPageNumber: action.pageNumber
            };

        case OPPOSED_OBJECTED:
            return {
                ...state,
                pseudo: action.pseudo,
                opposedObjected: action.payload,
                opposedObjectedStatus: action.status,
                opposedObjectedPageNumber: action.pageNumber
            };

        case PSEUDO_OPPOSED_OBJECTED:
            return {
                ...state,
                pseudo: action.pseudo,
                pseudoObjected: action.payload,
                pseudoOpposedObjectedStatus: action.status,
                pseudoOpposedObjectedPageNumber: action.pageNumber
            };


        case CHECK_FAIL:
            return {
                ...state,
                pseudo: action.pseudo,
                checkFail: action.payload,
                checkFailStatus: action.status,
                checkFailPageNumber: action.pageNumber
            };

        case PSEUDO_CHECK_FAIL:
            return {
                ...state,
                pseudo: action.pseudo,
                pseudoCheckFail: action.payload,
                pseudoCheckFailStatus: action.status,
                pseudoCheckFailPageNumber: action.pageNumber
            };

        case OPPOSED_CHECK_FAIL:
            return {
                ...state,
                pseudo: action.pseudo,
                opposedCheckFail: action.payload,
                opposedCheckFailStatus: action.status,
                opposedCheckFailPageNumber: action.pageNumber
            };

        case PSEUDO_OPPOSED_CHECK_FAIL:
            return {
                ...state,
                pseudo: action.pseudo,
                pseudoCheckFail: action.payload,
                pseudoOpposedCheckFailStatus: action.status,
                pseudoOpposedCheckFailPageNumber: action.pageNumber
            };

         
        case OPPOSED:
            return {
                ...state,
                pseudo: action.pseudo,
                opppsed: action.payload,
                opppsedStatus: action.status,
                opppsedPageNumber: action.pageNumber
            };

        case PSEUDO_OPPOSED:
            return {
                ...state,
                pseudo: action.pseudo,
                pseudoOpppsed: action.payload,
                pseudoOpppsedStatus: action.status,
                pseudoOpppsedPageNumber: action.pageNumber
            };

        case OPPOSED_OPPOSED:
            return {
                ...state,
                pseudo: action.pseudo,
                opposedOpppsed: action.payload,
                opposedOpppsedStatus: action.status,
                opposedOpppsedPageNumber: action.pageNumber
            };

        case PSEUDO_OPPOSED_OPPOSED:
            return {
                ...state,
                pseudo: action.pseudo,
                pseudoOpposedOpppsed: action.payload,
                pseudoOpposedOpppsedStatus: action.status,
                pseudoOpposedOpppsedPageNumber: action.pageNumber
            };

          
        case DASHBOARD_REPLY_FILED_TO_CORRESPONDENCE:
            switch (action.status) {
                case LOADING:
                    return {
                        ...state, 
                        dashboardReplyFiledStatus: LOADING,
                    };
                    
                case SUCCESS:
                   if(action.mm_type === 'correspondance') {
                        return {
                            ...state,
                            replyFiledMessage: action.payload, 
                            dashboardReplyFiledStatus: SUCCESS,
                            correspondence: {
                                ...state.correspondence, 
                                ip_india: {
                                    ...state.correspondence.ip_india, 
                                    trademarks: state.correspondence.ip_india.trademarks.filter(item => item.id !== action.payload.trademark_number)
                                }
                            },
                            pseudoCorrespondence: {
                                ...state.pseudoCorrespondence, 
                                pseudo: {
                                    ...state.pseudoCorrespondence.pseudo, 
                                    trademarks: state.pseudoCorrespondence.pseudo.trademarks.filter(item => item.id !== action.payload.trademark_number)
                                }
                            },
                        }
                    }
                    else if(action.mm_type === 'evidence') {
                        return {
                            ...state,
                            replyFiledMessage: action.payload, 
                            dashboardReplyFiledStatus: SUCCESS,
                            pendingEvidence: {
                                ...state.pendingEvidence, 
                                ip_india: {
                                    ...state.pendingEvidence.ip_india, 
                                    trademarks: state.pendingEvidence.ip_india.trademarks.filter(item => item.id !== action.payload.trademark_number)
                                }
                            },
                            pseudoPendingEvidence: {
                                ...state.pseudoPendingEvidence, 
                                pseudo: {
                                    ...state.pseudoPendingEvidence.pseudo, 
                                    trademarks: state.pseudoPendingEvidence.pseudo.trademarks.filter(item => item.id !== action.payload.trademark_number)
                                }
                            },
                        }
                    }
                    else if(action.mm_type === 'Chk_Fail') {
                        return {
                            ...state,
                            replyFiledMessage: action.payload, 
                            dashboardReplyFiledStatus: SUCCESS,
                            checkFail: {
                                ...state.checkFail, 
                                ip_india: {
                                    ...state.checkFail.ip_india, 
                                    trademarks: state.checkFail.ip_india.trademarks.filter(item => item.id !== action.payload.trademark_number)
                                }
                            },
                            pseudoCheckFail: {
                                ...state.pseudoCheckFail, 
                                pseudo: {
                                    ...state.pseudoCheckFail.pseudo, 
                                    trademarks: state.pseudoCheckFail.pseudo.trademarks.filter(item => item.id !== action.payload.trademark_number)
                                }
                            },
                        }
                    }
                    else if(action.mm_type === 'Objected') {
                        return {
                            ...state,
                            replyFiledMessage: action.payload, 
                            dashboardReplyFiledStatus: SUCCESS,
                            objected: {
                                ...state.objected, 
                                ip_india: {
                                    ...state.objected.ip_india, 
                                    trademarks: state.objected.ip_india.trademarks.filter(item => item.id !== action.payload.trademark_number)
                                }
                            },
                            pseudoObjected: {
                                ...state.pseudoObjected, 
                                pseudo: {
                                    ...state.pseudoObjected.pseudo, 
                                    trademarks: state.pseudoObjected.pseudo.trademarks.filter(item => item.id !== action.payload.trademark_number)
                                }
                            },
                        }
                    }
                    else if(action.mm_type === 'Opposed') {
                        return {
                            ...state,
                            replyFiledMessage: action.payload, 
                            dashboardReplyFiledStatus: SUCCESS,
                            opppsed: {
                                ...state.opppsed, 
                                ip_india: {
                                    ...state.opppsed.ip_india, 
                                    trademarks: state.opppsed.ip_india.trademarks.filter(item => item.id !== action.payload.trademark_number)
                                }
                            },
                            pseudoOpppsed: {
                                ...state.pseudoOpppsed, 
                                pseudo: {
                                    ...state.pseudoOpppsed.pseudo, 
                                    trademarks: state.pseudoOpppsed.pseudo.trademarks.filter(item => item.id !== action.payload.trademark_number)
                                }
                            },
                        }
                    }
                    else if(action.mm_type === 'opposed_correspondence') {
                        return {
                            ...state,
                            replyFiledMessage: action.payload, 
                            dashboardReplyFiledStatus: SUCCESS,
                            opposedCorrespondence: {
                                ...state.opposedCorrespondence, 
                                ip_india: {
                                    ...state.opposedCorrespondence.ip_india, 
                                    trademarks: state.opposedCorrespondence.ip_india.trademarks.filter(item => item.id !== action.payload.trademark_number),
                                }
                            },
                            pseudoOpposedCorrespondence: {
                                ...state.pseudoOpposedCorrespondence, 
                                pseudo: {
                                    ...state.pseudoOpposedCorrespondence.pseudo, 
                                    trademarks: state.pseudoOpposedCorrespondence.pseudo.trademarks.filter(item => item.id !== action.payload.trademark_number)
                                }
                            },
                        }
                    }
                    else if(action.mm_type === 'opposed_evidence') {
                        return {
                            ...state,
                            replyFiledMessage: action.payload, 
                            dashboardReplyFiledStatus: SUCCESS,
                            opposedPendingEvidence: {
                                ...state.opposedPendingEvidence, 
                                ip_india: {
                                    ...state.opposedPendingEvidence.ip_india, 
                                    trademarks: state.opposedPendingEvidence.ip_india.trademarks.filter(item => item.id !== action.payload.trademark_number),
                                }
                            },
                            pseudoOpposedPendingEvidence: {
                                ...state.pseudoOpposedPendingEvidence, 
                                pseudo: {
                                    ...state.pseudoOpposedPendingEvidence.pseudo, 
                                    trademarks: state.pseudoOpposedPendingEvidence.pseudo.trademarks.filter(item => item.id !== action.payload.trademark_number)
                                }
                            },
                        }
                    }
                    else if(action.mm_type === 'opposed_Chk_Fail') {
                        return {
                            ...state,
                            replyFiledMessage: action.payload, 
                            dashboardReplyFiledStatus: SUCCESS,
                            opposedCheckFail: {
                                ...state.opposedCheckFail, 
                                ip_india: {
                                    ...state.opposedCheckFail.ip_india, 
                                    trademarks: state.opposedCheckFail.ip_india.trademarks.filter(item => item.id !== action.payload.trademark_number),
                                }
                            },
                            pseudoOpposedCheckFail: {
                                ...state.pseudoOpposedCheckFail, 
                                pseudo: {
                                    ...state.pseudoOpposedCheckFail.pseudo, 
                                    trademarks: state.pseudoOpposedCheckFail.pseudo.trademarks.filter(item => item.id !== action.payload.trademark_number)
                                }
                            },
                        }
                    }
                    else if(action.mm_type === 'opposed_Objected') {
                        return {
                            ...state,
                            replyFiledMessage: action.payload, 
                            dashboardReplyFiledStatus: SUCCESS,
                            opposedObjected: {
                                ...state.opposedObjected, 
                                ip_india: {
                                    ...state.opposedObjected.ip_india, 
                                    trademarks: state.opposedObjected.ip_india.trademarks.filter(item => item.id !== action.payload.trademark_number),
                                }
                            },
                            pseudoOpposedObjected: {
                                ...state.pseudoOpposedObjected, 
                                pseudo: {
                                    ...state.pseudoOpposedObjected.pseudo, 
                                    trademarks: state.pseudoOpposedObjected.pseudo.trademarks.filter(item => item.id !== action.payload.trademark_number)
                                }
                            },
                        }
                    }
                    else if(action.mm_type === 'opposed_Opposed') {
                        return {
                            ...state,
                            replyFiledMessage: action.payload, 
                            dashboardReplyFiledStatus: SUCCESS,
                            opposedOpppsed: {
                                ...state.opposedOpppsed, 
                                ip_india: {
                                    ...state.opposedOpppsed.ip_india, 
                                    trademarks: state.opposedOpppsed.ip_india.trademarks.filter(item => item.id !== action.payload.trademark_number),
                                }
                            },
                            pseudoOpposedOpppsed: {
                                ...state.pseudoOpposedOpppsed, 
                                pseudo: {
                                    ...state.pseudoOpposedOpppsed.pseudo, 
                                    trademarks: state.pseudoOpposedOpppsed.pseudo.trademarks.filter(item => item.id !== action.payload.trademark_number)
                                }
                            },
                        }
                    }
                    break;

                case ERROR:
                    return {
                        ...state, 
                        dashboardReplyFiledStatus: ERROR,
                    };
            
                default:
                    break;
            }
            break;
    

        default:
            return state;
        
    }
}