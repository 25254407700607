//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';

import {
  Modal,
  Button,
  Input,
  Alert,
  message,
  Typography,
  notification,
  Select,
  List,
} from "antd";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  ERROR,
} from "../../../constants/commonConstants/generalConstants";

//--------IP-SUITE FUNCTIONS--------
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";

//--------ACTIONS--------
import {
  clientNotesUpload,
  setStoreData,
} from "../../../redux/actions/managerActions/notes/actions";

const { Text } = Typography;
const { Option } = Select;

const link = {
  TRADEMARK:
    "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/YourClientEmail.xlsx",
};

const TRADEMARK = "TRADEMARK";
const CUSTOM_TRADEMARK = "CUSTOM_TRADEMARK";
const INTERNATIONAL_TRADEMARK = "INTERNATIONAL_TRADEMARK";
const PATENT = "PATENT";
const CUSTOM_PATENT = "CUSTOM_PATENT";
const INTERNATIONAL_PATENT = "INTERNATIONAL_PATENT";
const NOFILE = "NO_FILE_SELECTED";
const PFMARK = "Portfolio trademark";
const TPMARK = "Third party Opposed trademark";
const STMARK = "Status tracker";
class AddClientNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "tm",
      file: "",
      fileValue: "",
      uploadFileError: false,
      subscription: "TRADEMARK",
      markType: "Portfolio trademark",
    };
  }

  componentDidMount() {
    this.props.setStoreData({
      clientNotesUploadData: {
        message: "",
        errors: [],
      },
    });
  }

  onUploadDocument(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    const isExcel = file.name.includes(".xlsx", ".xls");
    if (!isExcel) {
      message.error("Excel files are accepted only");
    }
    const isFileSize = file.size / (1024 * 1024) < 5;
    if (!isFileSize) {
      message.error("Excel file must be smaller than 5MB");
    }
    if (isExcel && isFileSize) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          uploadFileError: false,
        });
      };
      reader.readAsDataURL(file);
      this.setState({
        fileValue: e.target.value,
      });
    } else {
      e.target.value = "";
      this.setState({
        file: "",
        fileValue: "",
        uploadFileError: true,
      });
    }
  }

  async handleAddClientEmail(e) {
    if (this.state.file === "") {
      this.uploadMarkMessage(NOFILE);
    }
    let uploaded_file = checkValue(this.state.file) ? this.state.file : "";
    if (uploaded_file === "") {
      this.setState({
        uploadFileError: true,
      });
    } else {
      let response = await this.props.clientNotesUpload({
        uploaded_file: uploaded_file,
        subscription: this.state?.subscription,
      });

      if (response === SUCCESS) {
        this.uploadMarkMessage(SUCCESS);
        this.onCloseAddMark();
      } else {
        this.uploadMarkMessage(ERROR);
      }
    }
  }

  uploadMarkMessage(type) {
    switch (type) {
      case SUCCESS:
        notification["success"]({
          message: "Notes Uploaded",
          description: "ClientNotes has been linked with Notes successfully.",
        });
        setTimeout(() => window.location.reload(), 1500);

        break;

      case NOFILE:
        notification["error"]({
          message: "File is Required",
          description: "Please upload Valid File to add notes.",
        });
        break;
      default:
        break;
    }
  }

  onCloseAddMark() {
    this.props.setStoreData({
      clientNotesUploadData: {
        message: "",
        errors: [],
      },
    });
    this.props.onCloseModal();
    this.props.form.resetFields();
    this.setState({
      file: "",
      fileValue: "",
      uploadFileError: false,
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        <Modal
          title={
            <div className="alignC">
              <Text strong style={{ fontSize: "16px" }}>
                ADD NOTES
              </Text>
            </div>
          }
          bodyStyle={{ lineHeight: 2.5 }}
          open={this.props.visible}
          closable={false}
          width={500}
          footer={[
            <Button type="default" onClick={() => this.onCloseAddMark()}>
              Cancel
            </Button>,
            <Button
              type="primary"
              loading={
                this.props.createClientNotesStatus === LOADING ? true : false
              }
              onClick={(e) => this.handleAddClientEmail(e)}
            >
              Add
            </Button>,
          ]}
        >
          <>
            {this.props?.createClientNotesStatus === ERROR &&
              this.props?.clientNotesUploadData?.message && (
                <>
                  <Form.Item>
                    <Alert
                      message={this.props?.clientNotesUploadData?.message}
                      type="error"
                      showIcon
                    />
                    <div style={{ maxHeight: "160px", overflow: "auto" }}>
                      {this.props?.clientNotesUploadData?.errors &&
                        this.props?.clientNotesUploadData?.errors?.length >
                          0 && (
                          <List
                            size="small"
                            bordered
                            dataSource={
                              this.props?.clientNotesUploadData?.errors
                            }
                            renderItem={(item, index) => (
                              <Alert
                                message={`${index + 1}. ${item}`}
                                style={{ margin: "16px" }}
                                banner
                              />
                            )}
                          />
                        )}
                    </div>
                  </Form.Item>
                </>
              )}
            <Form.Item
              style={{
                display: "inline-block",
                width: "calc(100% - 32px)",
                margin: "0 8px",
              }}
            >
              {this.props.product === "tm-manager" && (
                <Select
                  defaultValue={TRADEMARK}
                  style={{ width: "100%" }}
                  onChange={(val) => this.setState({ subscription: val })}
                >
                  <Option value={TRADEMARK}>Trademark</Option>
                  <Option value={CUSTOM_TRADEMARK}>Custom trademark</Option>
                  <Option value={INTERNATIONAL_TRADEMARK}>
                    International trademark
                  </Option>

                  <Option value={PATENT}>Patent</Option>
                  <Option value={CUSTOM_PATENT}>Custom Patent</Option>
                  <Option value={INTERNATIONAL_PATENT}>
                    International Patent
                  </Option>
                </Select>
              )}
            </Form.Item>

            <Form.Item
              style={{
                display: "inline-block",
                margin: "0 8px",
                width: "calc(100% - 32px)",
              }}
            >
              <Text>Excel Format: </Text>
              <a
                href={
                  "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/NoteUploadSample.xlsx"
                }
              >
                Download Sample Excel
              </a>
              <div>
                <Text strong>Upload an excel in correct format:</Text>
                <div>
                  <Input
                    type="file"
                    id="input-tradmark-File"
                    className={`choose-input-file ${
                      this.state.uploadFileError ? "error" : ""
                    }`}
                    value={this.state?.fileValue}
                    onChange={(e) => this.onUploadDocument(e)}
                    style={{ backgroundColor: "#ffffff" }}
                  />
                  <Text
                    type="danger"
                    className={
                      this.state.uploadFileError ? "visible" : "not-visible"
                    }
                  >
                    Please upload only Excel File
                  </Text>
                </div>
              </div>
            </Form.Item>
          </>
        </Modal>
      </>
    );
  }

  componentWillUnmount() {}
}

const mapDispatchToProps = {
  clientNotesUpload,
  setStoreData,
};

function mapStateToProps(state) {
  const { clientNotesUploadData, createClientNotesStatus } =
    state.managerState.notesDashboard;

  console.log("STATETTTTTEEEE", clientNotesUploadData, createClientNotesStatus);

  return deepFreeze({
    clientNotesUploadData,
    createClientNotesStatus,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(AddClientNotes));
