import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { FixedSizeList } from "react-window";
import { uniq, debounce } from "lodash";
import moment from "moment";
import { CaretDownOutlined } from "@ant-design/icons";
import {
  Modal,
  Typography,
  Input,
  InputNumber,
  TimePicker,
  Button,
  message,
  Row,
  Col,
  Checkbox,
  Menu,
  Dropdown,
  AutoComplete,
  Tag,
  Tooltip,
  Select,
  Spin,
} from "antd";
// import {CKEditor} from "ckeditor4-react";

import {
  sendWorkflowEmail,
  editWorkflowEmail,
} from "../../../redux/actions/managerActions/workflow/action";
import { getEmailTemplate } from "../../../redux/actions/commonActions/actions";
import {
  GET_EMAIL_TEMPLATE,
  GET_USER_CLIENT_EMAIL,
} from "../../../redux/actions/commonActions/types";
import { EDIT_WORKFLOW_EMAIL } from "../../../redux/actions/managerActions/workflow/types";
import {
  LOADING,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";
import {
  changeStateValue_L0,
  changeStateValue_L1,
} from "../../../functions/commonFunctions/generalFunctions";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import { getUserClientEmail } from "../../../redux/actions/commonActions/actions";
import dayjs from "dayjs";
const _ = require("lodash");
const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

class WorkflowEmailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workflow_id: "",
      open: null,
      taskId: "",
      send_email_after_these_days: 1,
      maximum_send_emails_limit: 1,
      days_between_two_emails: 1,
      time: moment(),
      timing_of_email: moment().format("h:mm"),
      active: false,
      action: "",
      file: {
        file_attachment: "",
        file_name: "",
        filePreviewUrl: "",
      },
      send_to_based_on_mark: false,

      send_from: "",
      send_to: "",
      cc: "",
      mail_subject: "",
      mail_body: "",
      open: false,
      selectValue: "",
      searchValue: {
        send_to: "",
        cc: "",
      },
      cc_target_data: [],
      send_target_data: [],
      fetching: false,
      tags: {
        send_to: [],
        cc: [],
      },
      Tags: {
        Send_To: [],
        CC: [],
      },
      page: {
        send_to: 1,
        cc: 1,
      },
      send_from_input_error: false,
      send_from_error: false,
      send_to_input_error: false,
      send_to_error: false,
      cc_input_error: false,
      subject_input_error: false,
      subject_error: false,
      body_input_error: false,
      body_error: false,
      selectedKeys: [],
      disabled_mail_content: false,
      email_template_id: "",
      emailTemplatePage: 1,
    };
    this.autoRef = React.createRef();
    this.deletePrevious = React.createRef(false);
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
  }

  componentDidMount() {
    this.setState({
      tags: {
        ...this.state.tags,
        send_to: checkValue(this.props.userClientEmail)
          ? this.props?.userClientEmail?.associated_client_emails?.map(
              (eachmail) => `${eachmail.id}/${eachmail.email}`,
            )
          : [],
        cc: checkValue(this.props.userClientEmail)
          ? this.props?.userClientEmail?.team_emails?.map(
              (eachmail) => `${eachmail.id}/${eachmail.email}`,
            )
          : [],
      },
      Tags: {
        ...this.state.Tags,
        Send_To: checkValue(this.props.userClientEmail)
          ? this.props?.userClientEmail?.associated_client_emails?.map(
              (eachmail) => eachmail.email,
            )
          : [],
        CC: checkValue(this.props.userClientEmail)
          ? this.props?.userClientEmail?.team_emails?.map(
              (eachmail) => eachmail.email,
            )
          : [],
      },
    });
  }

  async shouldComponentUpdate(nextProps) {
    if (nextProps.report_tag !== this.props.report_tag) {
      const salutation = "Dear Sir/Ma'am,";
      const mail_Content = checkValue(nextProps.mailContent)
        ? nextProps.mailContent
        : "<p></p>";
      // const mail_html = '<p>'+salutation+'</p>'+mail_Content+'<p><strong>Regards</strong></p>';
      const mail_html = "";
      this.setState({
        workflow_id: nextProps.workflowId,
        taskId: checkValue(nextProps.taskData) ? nextProps.taskData.id : "",
        action: nextProps.action,
        taskData: nextProps.taskData,
        taskType: nextProps.taskType,
        editTaskFlag: nextProps.editTaskFlag,
        send_email_after_these_days: checkValue(nextProps.taskData)
          ? nextProps.taskData.send_email_after_these_days
          : 1,
        maximum_send_emails_limit: checkValue(nextProps.taskData)
          ? nextProps.taskData.maximum_send_emails_limit
          : 1,
        days_between_two_emails: checkValue(nextProps.taskData)
          ? nextProps.taskData.days_between_two_emails
          : 1,
        time: checkValue(nextProps.taskData)
          ? dayjs(nextProps.taskData.timing_of_email, "HH:mm")
          : dayjs(),
        timing_of_email: checkValue(nextProps.taskData)
          ? nextProps.taskData.timing_of_email
          : dayjs().format("HH:mm"),
        active: checkValue(nextProps.taskData)
          ? nextProps.taskData.is_active
          : false,
        send_to_based_on_mark: checkValue(nextProps.taskData)
          ? nextProps.taskData.is_send_to_based_on_mark
          : false,
        email_template_id: checkValue(
          nextProps.taskData && nextProps.taskData.email_template,
        )
          ? nextProps.taskData.email_template.id
          : "",
        disabled_mail_content: checkValue(
          nextProps.taskData && nextProps.taskData.email_template,
        )
          ? true
          : false,

        send_from: nextProps.editTaskFlag
          ? nextProps.taskData && checkValue(nextProps.taskData.send_from_email)
            ? nextProps.taskData.send_from_email
            : ""
          : checkValue(nextProps.sendFrom)
            ? nextProps.sendFrom
            : "",
        send_to: nextProps.editTaskFlag
          ? nextProps.taskData && checkValue(nextProps.taskData.send_to_emails)
            ? nextProps.taskData.send_to_emails.join(" ,")
            : ""
          : "",
        tags: {
          send_to: nextProps.editTaskFlag
            ? nextProps.taskData &&
              checkValue(nextProps.taskData.send_to_emails)
              ? nextProps.taskData.send_to_emails
              : []
            : [],
          cc: nextProps.editTaskFlag
            ? nextProps.taskData && checkValue(nextProps.taskData.cc_emails)
              ? nextProps.taskData.cc_emails
              : []
            : checkValue(nextProps.sendCC)
              ? nextProps.sendCC
              : [],
        },
        Tags: {
          Send_To: nextProps.editTaskFlag
            ? nextProps.taskData &&
              checkValue(nextProps.taskData.send_to_emails)
              ? nextProps.taskData.send_to_emails
              : []
            : [],
          CC: nextProps.editTaskFlag
            ? nextProps.taskData && checkValue(nextProps.taskData.cc_emails)
              ? nextProps.taskData.cc_emails
              : []
            : checkValue(nextProps.sendCC)
              ? nextProps.sendCC
              : [],
        },
        mail_subject: nextProps.editTaskFlag
          ? nextProps.taskData && checkValue(nextProps.taskData.email_template)
            ? nextProps.taskData.email_template.subject
            : ""
          : "",
        mail_body: nextProps.editTaskFlag
          ? nextProps.taskData && checkValue(nextProps.taskData.email_template)
            ? nextProps.taskData.email_template.content
            : ""
          : mail_html,
      });
    }

    if (nextProps.visible !== this.props.visible) {
      this.props.getEmailTemplate({
        actionType: "GET_EMAIL_TEMPLATE_WORKFLOW",
        product: nextProps.product,
        subscription: `${nextProps.subscription}`,
        page: 1,
      });
    }
    return true;
  }

  // onEditorChange = ( evt )=> {
  //     this.setState({
  //         mail_body: evt.editor.getData()
  //     });
  // }

  onDropdownVisibleChange(open, selectValue) {
    this.props.getUserClientEmail({
      actionType: GET_USER_CLIENT_EMAIL,
      product: this.props.product,
      subscription: this.props.subscription,
      report_flag: this.props.report_flag,
    });
    this.setState({
      open,
      selectValue,
    });
  }

  handleChange(event, selectValue) {
    const { value, id } = event.target;
    this.setState({
      [selectValue]: value,
    });
    setTimeout(() => {
      let element = document.getElementById(id);
      if (this.state[selectValue].length > 0) {
        element.classList.remove("email_error_input");
        this.setState({
          [`${selectValue}_error`]: false,
        });
      } else {
        element.classList.add("email_error_input");
        this.setState({
          [`${selectValue}_error`]: true,
        });
      }
    }, 500);
  }

  handleGetUserClientEmail(searchValue, selectValue) {
    if (searchValue.length !== 0) {
      this.props.getUserClientEmail({
        actionType: GET_USER_CLIENT_EMAIL,
        product: this.props.product,
        subscription: this.props.subscription,
        report_flag: this.props.report_flag,
        type: selectValue,
        contains_term: searchValue,
      });
    }
  }

  handleSearchDebounced = debounce(function (searchValue, selectValue) {
    this.handleGetUserClientEmail(searchValue, selectValue);
  }, 500);

  handleSearch(searchValue, selectValue) {
    this.setState({
      selectValue,
      searchValue: {
        ...this.state.searchValue,
        [selectValue]: searchValue,
      },
      page: {
        ...this.state.page,
        [selectValue]: 1,
      },
    });
    this.handleSearchDebounced(searchValue, selectValue);
  }

  handleSelect(value, selectValue) {
    let email_id = value.split("/");
    let email = email_id[1];
    this.setState({
      tags: {
        ...this.state.tags,
        [selectValue]: uniq([...this.state.tags[selectValue], value]),
      },
    });

    if (selectValue === "send_to") {
      this.setState({
        Tags: {
          ...this.state.Tags,
          ["Send_To"]: uniq([...this.state.Tags["Send_To"], email]),
        },
      });

      let result1 = this.props?.userClientEmail?.client_emails.forEach(
        (arrayItem) => {
          if (arrayItem.id === parseInt(email_id[0])) {
            this.setState({
              send_target_data: [...this.state.send_target_data, arrayItem],
            });
          }
        },
      );
    } else {
      this.setState({
        Tags: {
          ...this.state.Tags,
          ["CC"]: uniq([...this.state.Tags["CC"], email]),
        },
        send_to_error: false,
      });
      let result1 = this.props?.userClientEmail?.client_emails.forEach(
        (arrayItem) => {
          if (arrayItem.id === parseInt(email_id[0])) {
            this.setState({
              cc_target_data: [...this.state.cc_target_data, arrayItem],
            });
          }
        },
      );
    }
  }
  handleDeselect(value, selectValue) {
    let email_id = value.split("/");
    let email = email_id[1] === undefined ? email_id[0] : email_id[1];
    this.setState({
      tags: {
        ...this.state.tags,
        [selectValue]: uniq(
          this.state.tags[selectValue].filter((item) => item !== value),
        ),
      },
    });
    if (selectValue === "send_to") {
      this.setState({
        Tags: {
          ...this.state.Tags,
          ["Send_To"]: uniq(
            this.state.Tags["Send_To"].filter((item) => item !== email),
          ),
        },
        send_to_error: false,
      });
    } else {
      this.setState({
        Tags: {
          ...this.state.Tags,
          ["CC"]: uniq(this.state.Tags["CC"].filter((item) => item !== email)),
        },
        send_to_error: false,
      });
    }
  }

  handleKeyPress(event, selectValue) {
    const { id, value } = event.target;
    var re = /\S+@\S+\.\S+/;
    if (event.key === "Enter") {
      // this.props.getUserClientEmail({
      //   actionType: GET_USER_CLIENT_EMAIL,
      //   product: this.props.product,
      //   subscription: this.props.subscription,
      //   report_flag: this.props.report_flag,
      // });
      if (value && value.toLowerCase().match(re)) {
        if (selectValue === "send_to") {
          this.setState({
            Tags: {
              ...this.state.Tags,
              ["Send_To"]: uniq([...this.state.Tags["Send_To"], value]),
            },
          });
        } else if (selectValue === "cc") {
          this.setState({
            Tags: {
              ...this.state.Tags,
              ["CC"]: uniq([...this.state.Tags["CC"], value]),
            },
          });
        }
        this.setState({
          tags: {
            ...this.state.tags,
            [selectValue]: uniq([...this.state.tags[selectValue], value]),
          },
          send_to_error: false,
        });
      }
    }
  }

  handleDeleteTag(tag, selectValue) {
    this.deletePrevious.current = false;
    this.setState({
      tags: {
        ...this.state.tags,
        [selectValue]: this.state.tags[selectValue].filter(
          (eachTag) => eachTag !== tag,
        ),
      },
    });
  }

  handleLoadMore(event, selectValue) {
    const { target } = event;
    let pagination;
    if (selectValue === "send_to") {
      pagination = this.props?.userClientEmail?.count / 100;
    } else if (selectValue === "cc") {
      pagination = this.props?.userClientEmail?.count / 100;
    }
    if (
      pagination >= this.state.page[selectValue] &&
      target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight &&
      this.props?.userClientEmailMoreStatus !== LOADING &&
      this.props?.userClientEmailStatus !== LOADING
    ) {
      this.setState(
        {
          selectValue,
          page: {
            ...this.state.page,
            [selectValue]: this.state.page[selectValue] + 1,
          },
        },
        () => {
          this.props.getUserClientEmail({
            product: this.props.product,
            subscription: this.props.subscription,
            report_flag: this.props.report_flag,
            type: selectValue,
            page: this.state.page[selectValue],
            contains_term: this.state.contains_term,
          });
        },
      );
    }
  }

  handleUploadFile(file) {
    const isLt5M = file.size / (1024 * 1024) < 5;
    if (isLt5M) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.setState({
          file: {
            file_attachment: file,
            file_name: file.name,
            filePreviewUrl: reader.result,
          },
        });
      };
    } else {
      message.error("File size must smaller than 5MB!");
    }
  }

  removeFileUpload() {
    document.getElementById("files").value = null;
    this.setState({
      file: {
        file_attachment: "",
        file_name: "",
        filePreviewUrl: "",
      },
    });
  }

  handleSelectTemplate(data) {
    const { report_tag, product } = this.props;
    let tagValue = [];
    if (product === "MANAGER") {
      tagValue = [
        "report_name",
        "status",
        "journal_number",
        "journal_date",
        "tla_hearing_date",
        "opposition_hearing_date",
        "opponent_name",
        "proprietor_name",
        "renewal_date",
        "application_number",
        "mark_name",
        "date_of_application",
        "class",
        "date_of_usage",
      ];
    } else if (product === "SEARCH") {
      tagValue = ["mark_name", "created_date"];
    } else if (product === "WATCH") {
      tagValue = [
        "report_name",
        "journal_number",
        "journal_date",
        "opposition",
        "current_date",
        "your_mark_image",
        "threat_mark_image",
        "your_mark",
        "your_application",
        "your_DOA",
        "your_DOU",
        "your_class",
        "your_proprietor",
        "your_address",
        "threat_mark",
        "threat_application",
        "threat_DOA",
        "threat_DOU",
        "threat_class",
        "threat_proprietor",
        "threat_address",
      ];
    }
    let subjectText = data.subject.toString();
    let contentText = data.content.toString();
    tagValue.forEach((data) => {
      if (data === "your_mark_image" || data === "threat_mark_image") {
        let image = checkValue(report_tag[data])
          ? `<img src=${report_tag[data]} style="height:50px; width:50px;" alt="No Image"/>`
          : "<span>N.A.</span>";
        subjectText = subjectText.replaceAll(`@${data}`, "");
        contentText = contentText.replaceAll(`@${data}`, image);
      } else {
        subjectText = subjectText.replaceAll(`@${data}`, `${report_tag[data]}`);
        contentText = contentText.replaceAll(
          `@${data}`,
          `<span>${report_tag[data]}</span>`,
        );
      }
    });

    // this.setState({
    //     mail_subject: subjectText,
    //     mail_body: contentText,
    // })
    this.setState({
      disabled_mail_content: true,
      mail_subject: data.subject.toString(),
      mail_body: data.content.toString(),
      email_template_id: data.id,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    const {
      workflow_id,
      taskId,
      send_email_after_these_days,
      maximum_send_emails_limit,
      days_between_two_emails,
      timing_of_email,
      active,
      action,
      file,
      send_to_based_on_mark,
      send_from,
      tags,
      mail_subject,
      mail_body,
      editTaskFlag,
      email_template_id,
      Tags,
    } = this.state;

    let sendFromElement = document.getElementById("send_from_input");
    let sendToElement = document.getElementById("send_to_input");
    let subjectElement = document.getElementById("subject_input");
    let bodyElement = document.getElementById("body_input");
    if (send_from.length > 0) {
      sendFromElement.classList.remove("email_error_input");
      this.setState({
        send_from_error: false,
      });
    } else {
      sendFromElement.classList.add("email_error_input");
      this.setState({
        send_from_error: true,
      });
    }
    if (!send_to_based_on_mark) {
      if (tags.send_to.length > 0) {
        sendToElement.classList.remove("email_error_input");
        this.setState({
          send_to_error: false,
        });
      } else {
        sendToElement.classList.add("email_error_input");
        this.setState({
          send_to_error: true,
        });
      }
    }
    if (mail_subject.length > 0) {
      subjectElement.classList.remove("email_error_input");
      this.setState({
        subject_error: false,
      });
    } else {
      // subjectElement.classList.add('email_error_input');
      this.setState({
        subject_error: true,
      });
    }
    if (mail_body.length > 0) {
      bodyElement.classList.remove("email_error_input");
      this.setState({
        body_error: false,
      });
    } else {
      // bodyElement.classList.add('email_error_input');
      this.setState({
        body_error: true,
      });
    }

    if (
      send_from.length > 0 &&
      mail_subject.length > 0 &&
      mail_body.length > 0 &&
      (tags.send_to.length > 0 || send_to_based_on_mark)
    ) {
      if (editTaskFlag) {
        await this.props.editWorkflowEmail({
          actionType: EDIT_WORKFLOW_EMAIL,
          task_id: checkValue(taskId) ? taskId : "",
          send_email_after_these_days: checkValue(send_email_after_these_days)
            ? send_email_after_these_days
            : "",
          maximum_send_emails_limit: checkValue(maximum_send_emails_limit)
            ? maximum_send_emails_limit
            : "",
          days_between_two_emails: checkValue(days_between_two_emails)
            ? days_between_two_emails
            : "",
          timing_of_email: checkValue(timing_of_email) ? timing_of_email : "",
          active: checkValue(active) ? active : false,
          action: checkValue(action) ? action : "",
          file: checkValue(file) ? file : "",
          send_from: checkValue(send_from) ? send_from : "",
          send_to_based_on_mark: checkValue(send_to_based_on_mark)
            ? send_to_based_on_mark
            : false,
          send_to: checkValue(Tags.Send_To) ? `${Tags.Send_To}` : "",
          cc: checkValue(Tags.CC) ? `${Tags.CC}` : "",
          mail_subject: checkValue(mail_subject) ? mail_subject : "",
          mail_body: checkValue(mail_body) ? mail_body : "",
          email_template: checkValue(email_template_id)
            ? email_template_id
            : "",
        });
        message[this.props.workflowEmailStatus.toLowerCase()](
          this.props.workflowEmailMessage,
        );
      } else {
        await this.props.sendWorkflowEmail({
          actionType: this.props.actionType,
          workflow_id: checkValue(workflow_id) ? workflow_id : "",
          send_email_after_these_days: checkValue(send_email_after_these_days)
            ? send_email_after_these_days
            : "",
          maximum_send_emails_limit: checkValue(maximum_send_emails_limit)
            ? maximum_send_emails_limit
            : "",
          days_between_two_emails: checkValue(days_between_two_emails)
            ? days_between_two_emails
            : "",
          timing_of_email: checkValue(timing_of_email) ? timing_of_email : "",
          active: checkValue(active) ? active : false,
          action: checkValue(action) ? action : "",
          file: checkValue(file) ? file : "",
          send_from: checkValue(send_from) ? send_from : "",
          send_to_based_on_mark: checkValue(send_to_based_on_mark)
            ? send_to_based_on_mark
            : false,
          send_to: checkValue(Tags.Send_To) ? `${Tags.Send_To}` : "",
          cc: checkValue(Tags.CC) ? `${Tags.CC}` : "",
          mail_subject: checkValue(mail_subject) ? mail_subject : "",
          mail_body: checkValue(mail_body) ? mail_body : "",
          email_template: checkValue(email_template_id)
            ? email_template_id
            : "",
        });
        message[this.props.workflowEmailStatus.toLowerCase()](
          this.props.workflowEmailMessage,
        );
        this.setState({
          open: false,
          selectValue: "",
          searchValue: {
            send_to: "",
            cc: "",
          },
          tags: {
            send_to: [],
            cc: [],
          },
          page: {
            send_to: 1,
            cc: 1,
          },
          send_from_error: false,
          send_to_error: false,
          subject_error: false,
          body_error: false,
        });
      }
    }
  }

  handleClose() {
    this.setState({
      workflow_id: "",
      send_email_after_these_days: 1,
      maximum_send_emails_limit: 1,
      days_between_two_emails: 1,
      time: moment(),
      timing_of_email: moment().format("h:mm"),
      active: false,
      send_to_based_on_mark: false,
      file: {
        file_attachment: "",
        file_name: "",
        filePreviewUrl: "",
      },
      cc_target_data: [],
      send_target_data: [],
      send_from: "",
      send_to: "",
      cc: "",
      mail_subject: "",
      mail_body: "",
      open: false,
      selectValue: "",
      searchValue: {
        send_to: "",
        cc: "",
      },
      tags: {
        send_to: [],
        cc: [],
      },
      page: {
        send_to: 1,
        cc: 1,
      },
      send_from_input_error: false,
      send_from_error: false,
      send_to_input_error: false,
      send_to_error: false,
      cc_input_error: false,
      subject_input_error: false,
      subject_error: false,
      body_input_error: false,
      body_error: false,
      selectedKeys: [],
      disabled_mail_content: false,
      email_template_id: "",
    });
    this.props.onCloseModal();
  }

  handleOpenChange = (flag, key) => {
    if (flag) {
      this.setState({
        open: key,
      });
    } else {
      this.setState({
        open: null,
      });
    }
  };

  render() {
    const {
      emailTemplate,
      emailTemplateStatus,
      userClientEmail,
      userClientEmailStatus,
    } = this.props;

    let data_cc;
    let data_send_to;

    data_send_to = this.props?.userClientEmail?.associated_client_emails.concat(
      this.state.send_target_data,
    );

    data_send_to = data_send_to?.concat(userClientEmail?.client_emails);

    data_send_to = _.uniqWith(data_send_to, _.isEqual);

    data_cc = userClientEmail?.team_emails?.concat(this.state.cc_target_data);
    data_cc = data_cc?.concat(userClientEmail?.client_emails);

    data_cc = _.uniqWith(data_cc, _.isEqual);

    let dataSource_sendTo;
    let dataSource_cc;

    let no_data_Menu = (
      <Menu>
        <Menu.Item>
          <div className="alignC">
            <Text strong>No Data</Text>
          </div>
        </Menu.Item>
      </Menu>
    );

    let emailTemplateMenu = (
      <Menu>
        <Menu.Item>
          <div className="alignC">
            <Text strong>No Email Template Found</Text>
          </div>
        </Menu.Item>
      </Menu>
    );

    if (userClientEmailStatus === SUCCESS) {
      dataSource_sendTo =
        userClientEmail &&
        data_send_to?.map((eachmail) => (
          <Option
            key={`send_to-${eachmail?.id}`}
            id={`send_to-${eachmail?.id}`}
            value={`${eachmail?.id}/${eachmail?.email}`}
            label={eachmail?.email}
          >
            {eachmail?.email}
            {!eachmail?.person_name ? "" : ` - ${eachmail?.person_name}`}
            {!eachmail?.company_name ? "" : ` - ${eachmail?.company_name}`}
          </Option>
        ));
    }

    if (userClientEmailStatus === SUCCESS) {
      dataSource_cc =
        userClientEmail &&
        data_cc?.map((eachmail) => (
          <Option
            key={`send_to-${eachmail?.id}`}
            id={`send_to-${eachmail?.id}`}
            value={`${eachmail?.id}/${eachmail?.email}`}
            label={eachmail?.email}
          >
            {eachmail?.email}
            {!eachmail?.person_name ? "" : ` - ${eachmail?.person_name}`}
            {!eachmail?.company_name ? "" : ` - ${eachmail?.company_name}`}
          </Option>
        ));
    }

    if (emailTemplate && emailTemplate.email_template.length > 0) {
      emailTemplateMenu = (
        <Menu
          style={{ width: 800, height: 500 }}
          selectable={true}
          multiple={false}
          selectedKeys={this.state.selectedKeys}
          onScroll={(x) => {
            const { scrollTop, scrollHeight, clientHeight } = x.target;
            if (scrollTop + clientHeight >= scrollHeight - 20) {
              if (this.props.emailTemplateStatus !== "LOADING") {
                const page_size = 10;
                let total_pages = 0;

                let page = this.state.emailTemplatePage + 1;
                let count = this.props.templateCount;
                this.setState({ emailTemplatePage: page });

                if (count !== undefined && count !== null && count > 0) {
                  total_pages = Math.ceil(count / page_size);
                }
                if (
                  page <= total_pages &&
                  this.props.emailTemplateStatus !== "LOADING"
                ) {
                  this.props.getEmailTemplate({
                    actionType: "GET_EMAIL_TEMPLATE_WORKFLOW",
                    product: this.props.product,
                    subscription: `${this.props.subscription}`,
                    page,
                  });
                }
              }
              // This will be triggered after hitting the last element.
              // API call should be made here while implementing pagination.
            }
          }}
          onSelect={(event) => {
            this.changeStateValue_L0("selectedKeys", event.selectedKeys);
            this.setState({ subject_error: false });
          }}
          onDeselect={(event) =>
            this.changeStateValue_L0("selectedKeys", event.selectedKeys)
          }
        >
          {emailTemplate.email_template?.map((data) => (
            <Menu.Item
              key={data.id}
              style={{
                borderBottom: "1px solid #ddd",
              }}
              onClick={() => this.handleSelectTemplate(data)}
              className="menu-items-list"
            >
              <div>
                <div style={{ padding: "5px 0px" }}>
                  <Text strong>Subject :- </Text>
                  <Text>{data.subject}</Text>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  <div
                    id={`select-template-id-${data.id}`}
                    dangerouslySetInnerHTML={{
                      __html: data.content,
                    }}
                  />
                </div>
              </div>
            </Menu.Item>
          ))}
        </Menu>
      );
    }

    return (
      <Modal
        title={
          <Row gutter={24}>
            <Col span={8} className="alignL">
              <Dropdown
                dropdownRender={() => emailTemplateMenu}
                overlayClassName="filter-menu"
                autoAdjustOverflow
                onOpenChange={(flag) =>
                  this.handleOpenChange(flag, "emailTemplate")
                }
                open={this.state.open === "emailTemplate"}
              >
                <Button
                  type={
                    this.state.selectedKeys.length > 0 ? "primary" : "default"
                  }
                  ghost={this.state.selectedKeys.length > 0}
                >
                  Select Email Template <CaretDownOutlined />
                </Button>
              </Dropdown>
              {!this.state.subject_error?.length > 0 &&
                this.state.subject_error && (
                  <div
                    className="email_error_box"
                    style={{ display: "flex", fontSize: "12px" }}
                  >
                    <Text className="email_error_text">
                      Please Select Email Template
                    </Text>
                  </div>
                )}
            </Col>
            <Col span={8} className="alignC">
              <Title level={4} style={{ margin: 0 }}>
                {this.state.editTaskFlag ? "Edit" : "Create"} Workflow Email
              </Title>
            </Col>
            <Col span={8} className="alignR">
              <Checkbox
                checked={this.state.active}
                onChange={(event) =>
                  this.changeStateValue_L0("active", event.target.checked)
                }
              >
                Active
              </Checkbox>
            </Col>
          </Row>
        }
        open={this.props.visible}
        onOk={this.props.onCloseModal}
        onCancel={this.props.onCloseModal}
        closable={false}
        bodyStyle={{ padding: 0 }}
        width={"60%"}
        footer={false}
        maskClosable={false}
        className="workflow_email_modal"
      >
        <div class="workflow_email_modal_container vertical_scroll">
          <Row gutter={24}>
            <Col span={8} className="workflow_email_items">
              <Text strong>Send Email After These Days</Text>
              <InputNumber
                min={1}
                max={500}
                style={{ width: "100%" }}
                value={this.state.send_email_after_these_days}
                onChange={(value) =>
                  this.changeStateValue_L0("send_email_after_these_days", value)
                }
                placeholder="Send Email After These Days"
              />
            </Col>
            <Col span={8} className="workflow_email_items">
              <Text strong>Maximum Send Emails Limit</Text>
              <InputNumber
                min={1}
                max={500}
                style={{ width: "100%" }}
                value={this.state.maximum_send_emails_limit}
                onChange={(value) =>
                  this.changeStateValue_L0("maximum_send_emails_limit", value)
                }
                placeholder="Maximum Send Emails Limit"
              />
            </Col>
            <Col span={8} className="workflow_email_items">
              <Text strong>Days Between Two Emails</Text>
              <InputNumber
                min={1}
                max={500}
                style={{ width: "100%" }}
                value={this.state.days_between_two_emails}
                onChange={(value) =>
                  this.changeStateValue_L0("days_between_two_emails", value)
                }
                placeholder="Days Between Two Emails"
              />
            </Col>
            <Col span={8} className="workflow_email_items">
              <Text strong>Timing of Email</Text>
              <TimePicker
                format="HH:mm"
                style={{ width: "100%" }}
                placeholder="Select Time"
                value={
                  this.state.timing_of_email
                    ? dayjs(this.state.timing_of_email, "HH:mm")
                    : null
                }
                onSelect={(value) => {
                  const timeString = dayjs(value).format("HH:mm");
                  this.setState({ timing_of_email: timeString });
                }}
                onChange={(time, timeString) =>
                  this.setState({
                    timing_of_email: dayjs(timeString, "HH:mm").format("HH:mm"),
                  })
                }
              />
            </Col>
            {/* <Col span={16} className="workflow_email_items">
                            <Text strong>File Upload</Text>
                            <div>
                                <Button>
                                    <input 
                                        type="file" 
                                        id="files" 
                                        name="files"
                                        accept="image/*, .pdf, .xlsx, .xls, .doc, .docx"
                                        onChange={(event)=> this.handleUploadFile(event.target.files[0])}
                                    />
                                </Button>
                                <Icon 
                                    type="rest" 
                                    theme="filled" 
                                    style={{
                                        marginRight: 5, 
                                        fontSize: 20, 
                                        color: '#f81d22', 
                                        cursor: 'pointer',
                                        visibility: `${checkValue(this.state.file.file_name) ? 'visible' : 'hidden'}`
                                    }}
                                    onClick={()=> this.removeFileUpload()}
                                />
                            </div>
                        </Col> */}
            <Col span={24} className="workflow_email_items">
              <Text strong>Send From</Text>
              <div className="custom-send-email-Input-Field">
                {/* <Tooltip placement="topLeft" title="Please enter email address"> */}
                <Input
                  id="send_from_input"
                  // prefix={<Text strong style={{ color: 'rgba(0,0,0,.25)' }}>From</Text>}
                  type="email"
                  className="input-box send_from_box"
                  defaultValue={
                    checkValue(this.props.sendFrom) ? this.props.sendFrom : ""
                  }
                  onChange={(event) => this.handleChange(event, "send_from")}
                  placeholder="Please enter email address"
                />
                {/* </Tooltip> */}
              </div>
              {!this.state.send_from.length > 0 &&
                this.state.send_from_error && (
                  <div className="email_error_box">
                    <Text className="email_error_text">
                      Please enter email address
                    </Text>
                  </div>
                )}
            </Col>

            {!this.state.send_to_based_on_mark && (
              <Col span={24} className="workflow_email_items">
                <Text strong>Send To</Text>
                <div className="custom-send-email-Input-Field">
                  {/* <div style={{margin: 5}}>
                                        {this.state.tags.send_to.map(eachTag=> 
                                            <Tag 
                                                style={{display: 'inline'}}
                                                key={eachTag}
                                                closable
                                                onClose={() => this.handleDeleteTag(eachTag, 'send_to')}>
                                                {eachTag}
                                            </Tag>
                                        )}
                                    </div> */}
                  <Select
                    id="send_to_input"
                    value={this.state?.tags?.send_to}
                    mode="multiple"
                    // allowClear
                    style={{ width: "100%" }}
                    placeholder="Select or search email"
                    key={`MultiSelect${this.state.tags?.send_to?.length}`}
                    ref={this.autoRef}
                    autoClearSearchValue={true}
                    dataSource={dataSource_sendTo}
                    onInputKeyDown={(event) =>
                      this.handleKeyPress(event, "send_to")
                    }
                    onSearch={(value) => this.handleSearch(value, "send_to")}
                    onSelect={(value) => this.handleSelect(value, "send_to")}
                    onDeselect={(value) =>
                      this.handleDeselect(value, "send_to")
                    }
                    onDropdownVisibleChange={(open) =>
                      this.onDropdownVisibleChange(open, "send_to")
                    }
                    onPopupScroll={(event) =>
                      this.handleLoadMore(event, "send_to")
                    }
                    getPopupContainer={(trigger) => trigger.parentNode}
                    optionLabelProp="label"
                    optionFilterProp="children"
                    showSearch
                    notFoundContent={
                      this.props.userClientEmailMoreStatus === LOADING ||
                      this.props.userClientEmailStatus === LOADING ? (
                        <Spin size="small" />
                      ) : (
                        no_data_Menu
                      )
                    }
                    loading={
                      this.props.userClientEmailMoreStatus === LOADING &&
                      this.props.userClientEmailStatus === LOADING
                        ? true
                        : false
                    }
                  >
                    {dataSource_sendTo}
                  </Select>
                </div>
                {!this.state.tags.send_to.length > 0 &&
                  this.state.send_to_error &&
                  !this.state.send_to_based_on_mark && (
                    <div className="email_error_box">
                      <Text className="email_error_text">
                        Please enter Send to emails
                      </Text>
                    </div>
                  )}
              </Col>
            )}
            <Col span={24} className="workflow_email_items">
              <Checkbox
                checked={this.state.send_to_based_on_mark}
                onChange={(event) =>
                  this.changeStateValue_L0(
                    "send_to_based_on_mark",
                    event.target.checked,
                  )
                }
              >
                Send To Based On Mark
              </Checkbox>
            </Col>
            <Col span={24} className="workflow_email_items">
              <Text strong>CC</Text>
              <div className="custom-send-email-Input-Field">
                {/* <div style={{margin: 5}}>
                                        {this.state.tags.cc.map(eachTag=> 
                                            <Tag 
                                                key={eachTag}
                                                style={{display: 'inline'}}
                                                closable
                                                onClose={() => this.handleDeleteTag(eachTag, 'cc')}>
                                                {eachTag}
                                            </Tag>
                                        )}
                                    </div> */}
                <Select
                  value={this.state?.tags?.cc}
                  mode="multiple"
                  allowClear
                  placeholder="Select or search email"
                  style={{ width: "100%" }}
                  key={`Multiselect${this.state.tags?.cc?.length}`}
                  ref={this.autoRef}
                  backfill={true}
                  autoFocus={true}
                  autoClearSearchValue={true}
                  dataSource={dataSource_cc}
                  onInputKeyDown={(event) => this.handleKeyPress(event, "cc")}
                  onSearch={(value) => this.handleSearch(value, "cc")}
                  onSelect={(value) => this.handleSelect(value, "cc")}
                  onDeselect={(value) => this.handleDeselect(value, "cc")}
                  onDropdownVisibleChange={(open) =>
                    this.onDropdownVisibleChange(open, "cc")
                  }
                  onPopupScroll={(event) => this.handleLoadMore(event, "cc")}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  optionLabelProp="label"
                  optionFilterProp="children"
                  showSearch
                  notFoundContent={
                    this.props.userClientEmailMoreStatus === LOADING ||
                    this.props.userClientEmailStatus === LOADING ? (
                      <Spin size="small" />
                    ) : (
                      no_data_Menu
                    )
                  }
                  loading={
                    this.props.userClientEmailMoreStatus === LOADING &&
                    this.props.userClientEmailStatus === LOADING
                      ? true
                      : false
                  }
                >
                  {dataSource_cc}
                </Select>
              </div>
            </Col>
            {this.state.email_template_id && (
              <div style={{ width: "100%" }}>
                <Col span={24} className="workflow_email_items">
                  <Text strong>Subject</Text>
                  <div className="custom-send-email-Input-Field">
                    <Tooltip
                      placement="topLeft"
                      title="Please enter mail subject"
                    >
                      <Input
                        id="subject_input"
                        // prefix={<Text strong style={{ color: 'rgba(0,0,0,.25)' }}>Subject</Text>}
                        type="text"
                        className="input-box subject_box"
                        value={this.state.mail_subject}
                        onChange={(event) =>
                          this.handleChange(event, "mail_subject")
                        }
                        disabled={this.state.disabled_mail_content}
                      />
                    </Tooltip>
                  </div>
                  {!this.state.mail_subject.length > 0 &&
                    this.state.subject_error && (
                      <div className="email_error_box">
                        <Text className="email_error_text">
                          Please select email template
                        </Text>
                      </div>
                    )}
                </Col>
                <Col span={24} className="workflow_email_items">
                  <Text strong>Email Body</Text>
                  <div className="custom-send-email-Input-Field">
                    <Tooltip
                      placement="topLeft"
                      title="Please enter mail subject"
                    >
                      <TextArea
                        id="body_input"
                        className="input-box body_box"
                        rows={5}
                        autoSize={{
                          minRows: 4,
                          maxRows: 10,
                        }}
                        value={this.state.mail_body}
                        onChange={(event) =>
                          this.handleChange(event, "mail_body")
                        }
                        disabled={this.state.disabled_mail_content}
                      />
                    </Tooltip>
                  </div>
                  {!this.state.mail_body.length > 0 &&
                    this.state.body_error && (
                      <div className="email_error_box">
                        <Text className="email_error_text">
                          Please select email template
                        </Text>
                      </div>
                    )}
                </Col>
              </div>
            )}
            {/* <Col span={24} className="workflow_email_items m-top-20">
                            <div className="text-editor" style={{pointerEvents: `${this.state.disabled_mail_content ? 'none' : 'auto'}`}}>
                                <CKEditor
                                    data={this.state.mail_body}
                                    onChange={this.onEditorChange} 
                                    editorUrl = {'https://cdn.ckeditor.com/4.16.0/full-all/ckeditor.js'}
                                    config={{
                                        extraAllowedContent: 'div(*)',
                                        extraAllowedContent: '*',
                                        extraPlugins:  ['editorplaceholder'],
                                        editorplaceholder: 'Start typing here...',
                                        readOnly: true
                                    }}
                                />
                            </div>
                        </Col> */}
            <Col span={24} className="workflow_email_items alignR">
              <Button
                type="primary"
                key="submit"
                htmlType="submit"
                onClick={(event) => this.handleSubmit(event)}
                style={{ margin: "0px 10px" }}
                loading={this.props.workflowEmailStatus === LOADING}
              >
                <i
                  className="fa fa-paper-plane"
                  style={{ padding: "0px 5px" }}
                  aria-hidden="true"
                ></i>
                Add to Workflow
              </Button>
              <Button onClick={() => this.handleClose()}>Close</Button>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  sendWorkflowEmail,
  editWorkflowEmail,
  getEmailTemplate,
  getUserClientEmail,
};

function mapStateToProps(state) {
  const { workflowEmailMessage, workflowEmailStatus } =
    state.managerState.workflowState;
  const { emailTemplate, emailTemplateStatus, templateCount } =
    state.emailTemplateState;

  const {
    userClientEmail,
    userClientEmailStatus,
    clientEmail,
    clientEmailCount,
    clientEmailStatus,
    clientEmailMoreStatus,
    clientEmailCC,
    clientEmailCCCount,
    clientEmailCCStatus,
    clientEmailCCMoreStatus,
  } = state.sendEmailState;
  return deepFreeze({
    userClientEmail,
    userClientEmailStatus,
    workflowEmailMessage,
    workflowEmailStatus,

    emailTemplate,
    emailTemplateStatus,
    templateCount,
    clientEmail,
    clientEmailCount,
    clientEmailStatus,
    clientEmailMoreStatus,
    clientEmailCC,
    clientEmailCCCount,
    clientEmailCCStatus,
    clientEmailCCMoreStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowEmailModal);
