//--------ROOT URL--------
export const ROOT_URL = 'https://miketmapi.mikelegal.com/api';

//--------MANAGER--------
export const MGR_RT_URL = `${ROOT_URL}/manager`;


//--------SEARCH--------
export const SRCH_RT_URL = `${ROOT_URL}/search`;

//--------WATCH--------
export const WAT_RT_URL = `${ROOT_URL}/watch`;
