import React, { Component } from "react";
import { CarryOutTwoTone, MessageTwoTone } from "@ant-design/icons";
import {
  Row,
  Col,
  Card,
  Tooltip,
  Descriptions,
  Radio,
  Typography,
  Switch,
  Empty,
  Table,
  message,
} from "antd";

import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";
import { getReplyToCorrespondence } from "../../../../redux/actions/managerActions/trademarkDashboard/actions";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import ReplyFiledModal from "../../../managerComponents/trademark/ReplyFiled";
import { REPLY_FILED_TO_CORRESPONDENCE } from "../../../../redux/actions/managerActions/trademarkDashboard/types";
import {
  changeStateValue_L0,
  changeStateValue_L1,
  changeStateValue_L2,
} from "../../../../functions/commonFunctions/generalFunctions";

import ManagerNotes from "../../subComponents/Modal/managerNotes";
import CreateTask from "../../to-do-task/CreateTask";
//Actions
import {
  POST_TM_NOTES,
  PATCH_TM_NOTES,
} from "../../../../redux/actions/managerActions/commonAction/types";
//Constants
import { TM } from "../../../../constants/managerConstants/managerConstants";
import SendEmail from "../../../commonComponents/Modals/sendMail";
import SendEmailNew from "../../../commonComponents/Modals/SendEmailNew";

const { Text } = Typography;

class CorrespondenceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterDocDOD: "doc_name",
      filterRemainingDate: "remaining_days",
      modalsVisibility: {
        sendEmail: false,
        mailContent: "",
        report_tag: {},
      },
    };
    this.changeStateValue_L2 = changeStateValue_L2.bind(this);
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
  }

  handlePdfClick(data) {
    let url = "";
    if (data?.file) {
      url = data?.file;
    } else {
      url = data?.url;
    }
    if (url !== null) window.open(url, "_blank");
  }

  handlePdfClickExam(data) {
    let url = "";
    if (data?.url.includes("http://")) {
      url = data?.url;
    } else {
      url = data?.url;
    }
    if (url !== null) window.open(url, "_blank");
  }
  componentDidMount() {
    let id = sessionStorage.getItem("management_trademark_id");
    this.props.getReplyToCorrespondence({
      management_mark_id: id,
    });
  }

  replyFiled(data) {
    this.setState({
      add_trademark_id: data.id,
      replyFiledVisible: true,
      pending_reply: false,
    });
  }
  handleManagerNotes(data) {
    this.setState({
      managerNotes: true,
      application_number: data.application_number,
    });
  }
  handleEmail(modalsVisibility, modal, value, data) {
    let mailContent = "<p><strong>Trademark Manager</strong></p>";
    this.setState({
      [modalsVisibility]: {
        ...this.state[modalsVisibility],
        [modal]: value,
        mailContent: mailContent,
        product: "MANAGER",
        subscription: [
          this.state.changeSwitch ? "PSEUDO_TRADEMARK" : "TRADEMARK",
        ],
        report_tag: {
          journal_number: checkValue(data.journal) ? data.journal.number : "",
          journal_date: checkValue(data.journal)
            ? data.journal.date_of_publication
            : "",
          status: checkValue(data.status) ? data.status : "",
          renewal_date: checkValue(data.valid_upto) ? data.valid_upto : "",
          application_number: checkValue(data.application_number)
            ? data.application_number
            : "",
          mark_name: checkValue(data.applied_for) ? data.applied_for : "",
          date_of_application: checkValue(data.application_date)
            ? data.application_date
            : "",
          class: checkValue(data.associated_class)
            ? data.associated_class.join(", ")
            : "",
          date_of_usage: checkValue(data.date_of_usage)
            ? data.date_of_usage
            : "",
          proprietor_name: checkValue(data.proprietor)
            ? data.proprietor.map((prop) => prop.name).join(", ")
            : "",
          opponent_name: checkValue(data.opponent_name)
            ? data.opponent_name
            : "",
          tla_hearing_date: "",
          opposition_hearing_date: "",
          report_name: "",
        },
        objectType: this.state.changeSwitch ? "pseudo_trademark" : "trademark",
        applicationNumber: data.application_number,
        markId: data.management_mark_id,
        sourceId: data.management_mark_id,
      },
    });
  }

  reRender() {
    let id = sessionStorage.getItem("management_trademark_id");
    this.props.getReplyToCorrespondence({
      management_mark_id: id,
    });
  }

  render() {
    const {
      correspondenceData,
      correspondenceType,
      customProfile,
      changeSwitch,
    } = this.props;

    //ant d table
    const totalReplyToCorrespondenceColumns = [
      {
        title: "STATUS | APPLICATION DATE | CLASS",
        className: "reply-to-correspondence-column-2",
        render: (text, record) => {
          return (
            <div>
              <p className="text-camelCase">
                {checkValue(record.status)
                  ? record.status.toLowerCase()
                  : "N.A."}
              </p>
              <p className="text-camelCase">
                {checkValue(record.application_date)
                  ? record.application_date
                  : "N.A."}
              </p>
              <p className="text-camelCase">
                {checkValue(record.associated_class)
                  ? record.associated_class.join(", ")
                  : "N.A."}
              </p>
            </div>
          );
        },
      },

      {
        title: "OPPONENT NAME | OPPONENT NUMBER",
        className: "reply-to-correspondence-column-2",
        render: (text, record) => {
          return (
            <div>
              <span className="text-camelCase">
                {checkValue(record.opponent_name)
                  ? record.opponent_name.toLowerCase()
                  : "N.A."}
              </span>
              {record.opponent_number && (
                <p>
                  (
                  {checkValue(record.opponent_number)
                    ? record.opponent_number
                    : "N.A."}
                  )
                </p>
              )}
            </div>
          );
        },
      },

      {
        title: "DOCUMENT | TIME REMAINING",
        className: "reply-to-correspondence-column-2",
        render: (text, record) => {
          return (
            <div>
              {record.documents &&
                record.documents.length > 0 &&
                record.documents.map((doc) => (
                  <p className="h_80px vertical_scroll">
                    <span
                      onClick={() => this.handlePdfClick(doc)}
                      className="application-text"
                    >
                      {doc.description}
                    </span>
                    <p>({checkValue(doc.date) ? doc.date : "N.A."})</p>
                    <div>
                      <span>{doc.remaining_days} Days</span>
                      <span className={doc.alert ? "visible" : "not-visible"}>
                        <Tooltip
                          title="Deadline to file reply for this mark has passed"
                          className="toolTip-custom"
                        >
                          <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                            style={{
                              color: "#e1001b",
                              paddingLeft: "5px",
                              cursor: "pointer",
                            }}
                          ></i>
                        </Tooltip>
                      </span>
                    </div>
                  </p>
                ))}
              {record.correspondence_notices &&
                record.correspondence_notices.length > 0 &&
                record.correspondence_notices.map((doc) => (
                  <p className="h_80px vertical_scroll">
                    <div>
                      <span
                        onClick={() => this.handlePdfClick(doc)}
                        className="application-text"
                      >
                        {doc.subject}
                      </span>
                      <p>
                        (
                        {checkValue(doc.date_of_correspondence)
                          ? doc.date_of_correspondence
                          : "N.A."}
                        )
                      </p>
                      <div>
                        <span>{doc.remaining_days} Days</span>
                        <span className={doc.alert ? "visible" : "not-visible"}>
                          <Tooltip
                            title="Deadline to file reply for this mark has passed"
                            className="toolTip-custom"
                          >
                            <i
                              className="fa fa-exclamation-circle"
                              aria-hidden="true"
                              style={{
                                color: "#e1001b",
                                paddingLeft: "5px",
                                cursor: "pointer",
                              }}
                            ></i>
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                  </p>
                ))}
              {record.examination_report_documents &&
                record.examination_report_documents.length > 0 &&
                record.examination_report_documents.map((doc) => (
                  <p className="h_80px vertical_scroll">
                    <span
                      onClick={
                        checkValue(doc.url)
                          ? () => this.handlePdfClick(doc)
                          : ""
                      }
                      style={
                        checkValue(doc.url)
                          ? { cursor: "pointer", color: "#4285f4" }
                          : {}
                      }
                    >
                      EXAMINATION REPORT
                    </span>
                    <p>({checkValue(doc.date) ? doc.date : "N.A."})</p>
                    <div>
                      <span>{doc.remaining_days} Days</span>
                      <span className={doc.alert ? "visible" : "not-visible"}>
                        <Tooltip
                          title="Deadline to file reply for this mark has passed"
                          className="toolTip-custom"
                        >
                          <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                            style={{
                              color: "#e1001b",
                              paddingLeft: "5px",
                              cursor: "pointer",
                            }}
                          ></i>
                        </Tooltip>
                      </span>
                    </div>
                  </p>
                ))}
              {record.documents.length === 0 &&
                record.correspondence_notices.length === 0 &&
                record.examination_report_documents.length === 0 && (
                  <p className="h_80px vertical_scroll">N.A.</p>
                )}
            </div>
          );
        },
      },

      {
        title: "DISPATCH DOCUMENT | TIME REMAINING",
        className: "reply-to-correspondence-column-2",
        render: (text, record) => {
          return (
            <div>
              {record.correspondence_notices &&
                record.correspondence_notices.length > 0 &&
                record.correspondence_notices.map((doc) => (
                  <p className="h_80px vertical_scroll">
                    <div>
                      <span
                        onClick={() => this.handlePdfClick(doc)}
                        className="application-text"
                      >
                        {doc.subject}
                      </span>
                      <p>
                        (
                        {checkValue(doc.date_of_dispatch)
                          ? doc.date_of_dispatch
                          : "N.A."}
                        )
                      </p>
                    </div>
                    <div>
                      <span>{doc.remaining_days_wrt_dod} Days</span>
                      <span
                        className={
                          doc.alert_wrt_dod ? "visible" : "not-visible"
                        }
                      >
                        <Tooltip
                          title="Deadline to file reply for this mark has passed"
                          className="toolTip-custom"
                        >
                          <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                            style={{
                              color: "#e1001b",
                              paddingLeft: "5px",
                              cursor: "pointer",
                            }}
                          ></i>
                        </Tooltip>
                      </span>
                    </div>
                  </p>
                ))}
              {record.correspondence_notices.length === 0 && (
                <p className="h_80px vertical_scroll">N.A.</p>
              )}
            </div>
          );
        },
      },

      {
        title: "TAGS",
        dataIndex: "tags",
        className: "reply-to-correspondence-column-1",
        render: (tags) => {
          return (
            <div>
              {tags.length > 0
                ? tags.map((tag) => (
                    <span
                      key={tag.id}
                      className="Tag"
                      style={{
                        color: `${tag.color_code}`,
                        border: `1px solid ${tag.color_code}`,
                      }}
                    >
                      {tag.name.toUpperCase()}
                    </span>
                  ))
                : "N.A."}
            </div>
          );
        },
      },

      {
        title: "ACTION",
        className: "reply-to-correspondence-column-1",
        render: (action) => {
          return (
            <div
              className="iconTable"
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "column",
              }}
            >
              <p
                className="application-text alignC"
                onClick={() => this.replyFiled(action)}
              >
                Reply Filed
              </p>
              <Tooltip placement="top" title={"Create To-do Task"}>
                <p className="alignC">
                  <CarryOutTwoTone
                    onClick={() =>
                      this.changeStateValue_L0("addTaskVisible", true)
                    }
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Notes"}>
                <p className="alignC">
                  <MessageTwoTone
                    onClick={() => this.handleManagerNotes(action)}
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Send email"}>
                <p
                  className="alignC"
                  onClick={() =>
                    this.handleEmail(
                      "modalsVisibility",
                      "sendEmail",
                      true,
                      action,
                    )
                  }
                >
                  <i
                    className="fa fa-paper-plane"
                    style={{
                      padding: "0px 5px",
                      color: "#1890ff",
                      fontSize: 20,
                      cursor: "pointer",
                    }}
                    aria-hidden="true"
                  ></i>
                </p>
              </Tooltip>
            </div>
          );
        },
      },
    ];

    const opposedPendingReply = [
      {
        title: "STATUS",
        className: "reply-to-correspondence-column-2",
        render: (text, record) => {
          return (
            <div>
              <p className="text-camelCase">
                {checkValue(record.status) ? record.status : "N.A."}
              </p>
            </div>
          );
        },
      },

      {
        title: "DOCUMENT | TIME REMAINING",
        className: "reply-to-correspondence-column-2",
        render: (text, record) => {
          return (
            <div>
              {record.opposition_documents &&
                record.opposition_documents.length > 0 &&
                record.opposition_documents.map((doc, docKey) => (
                  <p key={docKey}>
                    <span
                      onClick={() => this.handlePdfClick(doc)}
                      className="application-text"
                    >
                      {doc.description}
                    </span>
                    <p>({checkValue(doc.date) ? doc.date : "N.A."})</p>
                  </p>
                ))}

              {record.opposition_correspondence_notices &&
                record.opposition_correspondence_notices.length > 0 &&
                record.opposition_correspondence_notices.map(
                  (doc, docKey) =>
                    doc.subject !== "Opposition Notice" && (
                      <p key={docKey}>
                        <span
                          onClick={() => this.handlePdfClick(doc)}
                          className="application-text"
                        >
                          {doc.subject}
                        </span>
                        <p>
                          (
                          {checkValue(doc.date_of_correspondence)
                            ? doc.date_of_correspondence
                            : "N.A."}
                          )
                        </p>
                      </p>
                    ),
                )}

              {record.check_fail_documents &&
                record.check_fail_documents.length > 0 &&
                record.check_fail_documents.map((doc, docKey) => (
                  <p key={docKey}>
                    <span
                      onClick={() => this.handlePdfClick(doc)}
                      className="application-text"
                    >
                      {doc.description}
                    </span>
                  </p>
                ))}

              {record.check_fail_correspondence_notices &&
                record.check_fail_correspondence_notices.length > 0 &&
                record.check_fail_correspondence_notices.length > 0 &&
                record.check_fail_correspondence_notices.map((doc, docKey) => (
                  <p key={docKey}>
                    <span
                      onClick={() => this.handlePdfClick(doc)}
                      className="application-text"
                    >
                      {doc.subject}
                    </span>
                  </p>
                ))}

              {record.evidence_documents &&
                record.evidence_documents.length > 0 &&
                record.evidence_documents.map((doc, docKey) => (
                  <p key={docKey}>
                    <span
                      onClick={() => this.handlePdfClick(doc)}
                      className="application-text"
                    >
                      {doc.description}
                    </span>
                    <p>({checkValue(doc.date) ? doc.date : "N.A."})</p>
                  </p>
                ))}

              {record.examination_report_documents &&
                record.examination_report_documents?.length > 0 &&
                record.examination_report_documents.map((doc, docKey) => (
                  <p key={docKey}>
                    <span
                      onClick={
                        checkValue(doc.url)
                          ? () => this.handlePdfClickExam(doc)
                          : ""
                      }
                      style={
                        checkValue(doc.url)
                          ? {
                              cursor: "pointer",
                              color: "#4285f4",
                            }
                          : {}
                      }
                    >
                      EXAMINATION REPORT
                    </span>
                    <p>({checkValue(doc.date) ? doc.date : "N.A."})</p>
                  </p>
                ))}

              {record.examination_report_documents.length === 0 &&
                record.opposition_correspondence_notices.length === 0 &&
                record.opposition_documents.length === 0 &&
                record.check_fail_documents.length === 0 &&
                record.check_fail_correspondence_notices.length === 0 &&
                record.evidence_documents.length === 0 && (
                  <p className="m-0">N.A.</p>
                )}

              {record.opposition_documents &&
                record.opposition_documents.length > 0 &&
                record.opposition_documents.map((doc, docKey) => (
                  <p key={docKey}>
                    <span>
                      {doc.remaining_days ? doc.remaining_days : 0} Days
                    </span>
                    <span className={doc.alert ? "visible" : "not-visible"}>
                      <Tooltip
                        title="Deadline to file reply for this mark has passed"
                        className="toolTip-custom"
                      >
                        <i
                          className="fa fa-exclamation-circle"
                          aria-hidden="true"
                          style={{
                            color: "#e1001b",
                            paddingLeft: "5px",
                            cursor: "pointer",
                          }}
                        ></i>
                      </Tooltip>
                    </span>
                  </p>
                ))}

              {record.check_fail_correspondence_notices &&
                record.check_fail_correspondence_notices.length > 0 &&
                record.check_fail_correspondence_notices.map((doc, docKey) => (
                  <p key={docKey}>
                    <span>
                      {doc.remaining_days ? doc.remaining_days : 0} Days
                    </span>
                    <span className={doc.alert ? "visible" : "not-visible"}>
                      <Tooltip
                        title="Deadline to file reply for this mark has passed"
                        className="toolTip-custom"
                      >
                        <i
                          className="fa fa-exclamation-circle"
                          aria-hidden="true"
                          style={{
                            color: "#e1001b",
                            paddingLeft: "5px",
                            cursor: "pointer",
                          }}
                        ></i>
                      </Tooltip>
                    </span>
                  </p>
                ))}

              {record.evidence_documents &&
                record.evidence_documents.length > 0 &&
                record.evidence_documents.map((doc, docKey) => (
                  <p key={docKey}>
                    <span>
                      {doc.remaining_days ? doc.remaining_days : 0} Days
                    </span>
                    <span className={doc.alert ? "visible" : "not-visible"}>
                      <Tooltip
                        title="Deadline to file reply for this mark has passed"
                        className="toolTip-custom"
                      >
                        <i
                          className="fa fa-exclamation-circle"
                          aria-hidden="true"
                          style={{
                            color: "#e1001b",
                            paddingLeft: "5px",
                            cursor: "pointer",
                          }}
                        ></i>
                      </Tooltip>
                    </span>
                  </p>
                ))}

              {record.examination_report_documents &&
                record.examination_report_documents.length > 0 &&
                record.examination_report_documents.map((doc, docKey) => (
                  <p key={docKey}>
                    <span>
                      {doc.remaining_days ? doc.remaining_days : 0} Days
                    </span>
                    <span className={doc.alert ? "visible" : "not-visible"}>
                      <Tooltip
                        title="Deadline to file reply for this mark has passed"
                        className="toolTip-custom"
                      >
                        <i
                          className="fa fa-exclamation-circle"
                          aria-hidden="true"
                          style={{
                            color: "#e1001b",
                            paddingLeft: "5px",
                            cursor: "pointer",
                          }}
                        ></i>
                      </Tooltip>
                    </span>
                  </p>
                ))}

              {record.opposition_documents.length === 0 &&
                record.check_fail_correspondence_notices.length === 0 &&
                record.evidence_documents.length === 0 &&
                record.examination_report_documents.length === 0 &&
                record.objected_correspondence_notices.length === 0 && (
                  <p className="m-0">N.A.</p>
                )}
            </div>
          );
        },
      },
      {
        title: "DISPATCH DOCUMENT | TIME REMAINING",
        className: "reply-to-correspondence-column-2",
        render: (text, record) => {
          return (
            <div>
              {record.evidence_correspondence_notices &&
                record.evidence_correspondence_notices.length > 0 &&
                record.evidence_correspondence_notices.map((doc, docKey) => (
                  <p key={docKey}>
                    <span
                      onClick={() => this.handlePdfClick(doc)}
                      className="application-text"
                    >
                      {doc.subject}
                    </span>
                    <p>
                      (
                      {checkValue(doc.date_of_dispatch)
                        ? doc.date_of_dispatch
                        : "N.A."}
                      )
                    </p>
                  </p>
                ))}

              {record.check_fail_correspondence_notices &&
                record.check_fail_correspondence_notices.length > 0 &&
                record.check_fail_correspondence_notices.map((doc, docKey) => (
                  <p key={docKey}>
                    <span
                      onClick={() => this.handlePdfClick(doc)}
                      className="application-text"
                    >
                      {doc.subject}
                    </span>
                    <p>
                      (
                      {checkValue(doc.date_of_dispatch)
                        ? doc.date_of_dispatch
                        : "N.A."}
                      )
                    </p>
                  </p>
                ))}

              {record.objected_correspondence_notices &&
                record.objected_correspondence_notices.length > 0 &&
                record.objected_correspondence_notices.map((doc, docKey) => (
                  <p key={docKey}>
                    <span
                      onClick={() => this.handlePdfClick(doc)}
                      className="application-text"
                    >
                      {doc.subject}
                    </span>
                    <p>
                      (
                      {checkValue(doc.date_of_dispatch)
                        ? doc.date_of_dispatch
                        : "N.A."}
                      )
                    </p>
                  </p>
                ))}

              {record.opposition_correspondence_notices &&
                record.opposition_correspondence_notices.length > 0 &&
                record.opposition_correspondence_notices.map((doc, docKey) => (
                  <p key={docKey}>
                    <span
                      onClick={() => this.handlePdfClick(doc)}
                      className="application-text"
                    >
                      {doc.subject}
                    </span>
                    <p>
                      (
                      {checkValue(doc.date_of_dispatch)
                        ? doc.date_of_dispatch
                        : "N.A."}
                      )
                    </p>
                  </p>
                ))}

              {record.objection_documents &&
                record.objection_documents.length > 0 &&
                record.objection_documents.map((doc, docKey) => (
                  <p key={docKey}>
                    <span
                      onClick={
                        checkValue(doc.examination_report)
                          ? () => this.handlePdfClick(doc)
                          : ""
                      }
                      style={
                        checkValue(doc.examination_report)
                          ? {
                              cursor: "pointer",
                              color: "#4285f4",
                            }
                          : {}
                      }
                    >
                      Dispatch of Examination Report
                    </span>
                  </p>
                ))}

              {record.evidence_correspondence_notices.length === 0 &&
                record.check_fail_correspondence_notices.length === 0 &&
                record.objected_correspondence_notices.length === 0 &&
                record.opposition_correspondence_notices.length === 0 &&
                record.objection_documents.length === 0 && (
                  <p className="m-0">N.A.</p>
                )}

              {record.evidence_correspondence_notices &&
                record.evidence_correspondence_notices.length > 0 &&
                record.evidence_correspondence_notices.map((doc, docKey) => (
                  <p key={docKey}>
                    <span>
                      {doc.remaining_days_wrt_dod
                        ? doc.remaining_days_wrt_dod
                        : 0}{" "}
                      Days
                    </span>
                    <span className={doc.alert ? "visible" : "not-visible"}>
                      <Tooltip
                        title="Deadline to file reply for this mark has passed"
                        className="toolTip-custom"
                      >
                        <i
                          className="fa fa-exclamation-circle"
                          aria-hidden="true"
                          style={{
                            color: "#e1001b",
                            paddingLeft: "5px",
                            cursor: "pointer",
                          }}
                        ></i>
                      </Tooltip>
                    </span>
                  </p>
                ))}

              {record.check_fail_correspondence_notices &&
                record.check_fail_correspondence_notices.length > 0 &&
                record.check_fail_correspondence_notices.map((doc, docKey) => (
                  <p key={docKey}>
                    <span>
                      {doc.remaining_days_wrt_dod
                        ? doc.remaining_days_wrt_dod
                        : 0}{" "}
                      Days
                    </span>
                    <span className={doc.alert ? "visible" : "not-visible"}>
                      <Tooltip
                        title="Deadline to file reply for this mark has passed"
                        className="toolTip-custom"
                      >
                        <i
                          className="fa fa-exclamation-circle"
                          aria-hidden="true"
                          style={{
                            color: "#e1001b",
                            paddingLeft: "5px",
                            cursor: "pointer",
                          }}
                        ></i>
                      </Tooltip>
                    </span>
                  </p>
                ))}

              {record.objected_correspondence_notices &&
                record.objected_correspondence_notices.length > 0 &&
                record.objected_correspondence_notices.map((doc, docKey) => (
                  <p key={docKey}>
                    <span>
                      {doc.remaining_days_wrt_dod
                        ? doc.remaining_days_wrt_dod
                        : 0}{" "}
                      Days
                    </span>
                    <span className={doc.alert ? "visible" : "not-visible"}>
                      <Tooltip
                        title="Deadline to file reply for this mark has passed"
                        className="toolTip-custom"
                      >
                        <i
                          className="fa fa-exclamation-circle"
                          aria-hidden="true"
                          style={{
                            color: "#e1001b",
                            paddingLeft: "5px",
                            cursor: "pointer",
                          }}
                        ></i>
                      </Tooltip>
                    </span>
                  </p>
                ))}

              {record.opposition_correspondence_notices &&
                record.opposition_correspondence_notices.length > 0 &&
                record.opposition_correspondence_notices.map((doc, docKey) => (
                  <p key={docKey}>
                    <span>
                      {doc.remaining_days_wrt_dod
                        ? doc.remaining_days_wrt_dod
                        : 0}{" "}
                      Days
                    </span>
                    <span className={doc.alert ? "visible" : "not-visible"}>
                      <Tooltip
                        title="Deadline to file reply for this mark has passed"
                        className="toolTip-custom"
                      >
                        <i
                          className="fa fa-exclamation-circle"
                          aria-hidden="true"
                          style={{
                            color: "#e1001b",
                            paddingLeft: "5px",
                            cursor: "pointer",
                          }}
                        ></i>
                      </Tooltip>
                    </span>
                  </p>
                ))}

              {record.evidence_correspondence_notices.length === 0 &&
                record.check_fail_correspondence_notices.length === 0 &&
                record.objected_correspondence_notices.length === 0 &&
                record.opposition_correspondence_notices.length === 0 && (
                  <p className="m-0">N.A.</p>
                )}
            </div>
          );
        },
      },
      {
        title: "REPLY TO DOCUMENTS",
        className: "reply-to-correspondence-column-2",
        render: (text, record) => {
          return (
            <div>
              {record.opposition_counter_documents &&
                record.opposition_counter_documents.length > 0 &&
                record.opposition_counter_documents.map((doc, docKey) => (
                  <p key={docKey}>
                    <span
                      onClick={() => this.handlePdfClick(doc)}
                      className="application-text"
                    >
                      {doc.description}
                    </span>
                  </p>
                ))}

              {record.opposition_counter_correspondence_notices &&
                record.opposition_counter_correspondence_notices.length > 0 &&
                record.opposition_counter_correspondence_notices.map(
                  (doc, docKey) => (
                    <p key={docKey}>
                      <span
                        onClick={() => this.handlePdfClick(doc)}
                        className="application-text"
                      >
                        {doc.subject}
                      </span>
                    </p>
                  ),
                )}

              {record.check_fail_counter_documents &&
                record.check_fail_counter_documents.length > 0 &&
                record.check_fail_counter_documents.map((doc, docKey) => (
                  <p key={docKey}>
                    <span
                      onClick={() => this.handlePdfClick(doc)}
                      className="application-text"
                    >
                      {doc.description}
                    </span>
                  </p>
                ))}

              {record.check_fail_counter_correspondence_notices &&
                record.check_fail_counter_correspondence_notices.length > 0 &&
                record.check_fail_counter_correspondence_notices.map(
                  (doc, docKey) => (
                    <p key={docKey}>
                      <span
                        onClick={() => this.handlePdfClick(doc)}
                        className="application-text"
                      >
                        {doc.subject}
                      </span>
                    </p>
                  ),
                )}

              {record.objection_counter_documents &&
                record.objection_counter_documents.length > 0 &&
                record.objection_counter_documents.map((doc, docKey) => (
                  <p key={docKey}>
                    <span
                      onClick={() => this.handlePdfClick(doc)}
                      className="application-text"
                    >
                      {doc.description}
                    </span>
                  </p>
                ))}

              {record.evidence_counter_documents &&
                record.evidence_counter_documents.length > 0 &&
                record.evidence_counter_documents.map((doc, docKey) => (
                  <p key={docKey}>
                    <span
                      onClick={() => this.handlePdfClick(doc)}
                      className="application-text"
                    >
                      {doc.description}
                    </span>
                  </p>
                ))}

              {record.objection_counter_documents.length === 0 &&
                record.opposition_counter_documents.length === 0 &&
                record.opposition_counter_correspondence_notices.length === 0 &&
                record.check_fail_counter_documents.length === 0 &&
                record.check_fail_counter_correspondence_notices.length === 0 &&
                record.evidence_counter_documents.length === 0 && (
                  <p className="m-0">N.A.</p>
                )}
            </div>
          );
        },
      },

      {
        title: "ACTION",
        className: "reply-to-correspondence-column-2",
        render: (text, record) => {
          return (
            <div>
              <p
                className="application-text"
                onClick={() => this.props.replyFiled(record)}
              >
                Reply Filed
              </p>
              <p>
                <Tooltip placement="top" title={"Create To-do Task"}>
                  <CarryOutTwoTone
                    onClick={this.props.changeStateValue_L0}
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </Tooltip>
              </p>
              <Tooltip placement="top" title={"Send email"}>
                <span onClick={() => this.props.handleEmail(record)}>
                  <i
                    className="fa fa-paper-plane"
                    style={{
                      padding: "0px 5px",
                      color: "#1890ff",
                      fontSize: 20,
                    }}
                    aria-hidden="true"
                  ></i>
                </span>
              </Tooltip>
            </div>
          );
        },
      },
    ];

    //------------------------

    return (
      <div>
        <Row gutter={16} className="m-top-20">
          <Col span={24}>
            <Card
              title={correspondenceType}
              headStyle={{ background: "#e8e8e8" }}
              extra={
                customProfile && (
                  <div>
                    <Text strong>IP INDIA</Text>
                    <Switch
                      className="change-manager-type"
                      onChange={() => this.props.handleChangeSwitch()}
                      checked={changeSwitch}
                    />
                    <Text strong>CUSTOM</Text>
                  </div>
                )
              }
            >
              {correspondenceType === "REPLY TO CORRESPONDENCE" ? (
                <Table
                  rowKey={(record) => record.id}
                  columns={totalReplyToCorrespondenceColumns}
                  dataSource={
                    this.props.replyToCorrespondence
                      ? this.props.replyToCorrespondence.ip_india.trademarks
                      : null
                  }
                />
              ) : correspondenceData ? (
                <Descriptions
                  title={
                    <Row>
                      <Col span={12} className="alignL">
                        <Radio.Group
                          onChange={(event) =>
                            this.setState({ filterDocDOD: event.target.value })
                          }
                          value={this.state.filterDocDOD}
                        >
                          <Radio value="doc_name">Document Name</Radio>
                          <Radio value="dispatch_doc_name">
                            Dispatch Document Name
                          </Radio>
                        </Radio.Group>
                      </Col>
                      <Col span={12} className="alignR">
                        <Radio.Group
                          onChange={(event) =>
                            this.setState({
                              filterRemainingDate: event.target.value,
                            })
                          }
                          value={this.state.filterRemainingDate}
                        >
                          <Radio value="remaining_days">
                            Time remaining (DoU)
                          </Radio>
                          <Radio value="remaining_days_wrt_dod">
                            Time remaining (DoS)
                          </Radio>
                        </Radio.Group>
                      </Col>
                    </Row>
                  }
                  bordered
                  size="small"
                  layout="horizontal"
                  column={1}
                  className="description-table"
                >
                  <>
                    <Descriptions.Item label={<Text strong>STATUS</Text>}>
                      {checkValue(correspondenceData.status)
                        ? correspondenceData.status
                        : "N.A."}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text strong>DOCUMENT</Text>}>
                      {this.state.filterDocDOD === "doc_name" ? (
                        <div className="max_h_100px vertical_scroll">
                          {correspondenceData.opposition_documents &&
                            correspondenceData.opposition_documents.length >
                              0 &&
                            correspondenceData.opposition_documents.map(
                              (doc, docKey) => (
                                <p key={docKey}>
                                  <span
                                    onClick={() => this.handlePdfClick(doc)}
                                    className="application-text"
                                  >
                                    {doc.description}
                                  </span>
                                  <p>
                                    ({checkValue(doc.date) ? doc.date : "N.A."})
                                  </p>
                                </p>
                              ),
                            )}

                          {correspondenceData.opposition_correspondence_notices &&
                            correspondenceData.opposition_correspondence_notices
                              .length > 0 &&
                            correspondenceData.opposition_correspondence_notices.map(
                              (doc, docKey) =>
                                doc.subject !== "Opposition Notice" && (
                                  <p key={docKey}>
                                    <span
                                      onClick={() => this.handlePdfClick(doc)}
                                      className="application-text"
                                    >
                                      {doc.subject}
                                    </span>
                                    <p>
                                      (
                                      {checkValue(doc.date_of_correspondence)
                                        ? doc.date_of_correspondence
                                        : "N.A."}
                                      )
                                    </p>
                                  </p>
                                ),
                            )}

                          {correspondenceData.check_fail_documents &&
                            correspondenceData.check_fail_documents.length >
                              0 &&
                            correspondenceData.check_fail_documents.map(
                              (doc, docKey) => (
                                <p key={docKey}>
                                  <span
                                    onClick={() => this.handlePdfClick(doc)}
                                    className="application-text"
                                  >
                                    {doc.description}
                                  </span>
                                </p>
                              ),
                            )}

                          {correspondenceData.check_fail_correspondence_notices &&
                            correspondenceData.check_fail_correspondence_notices
                              .length > 0 &&
                            correspondenceData.check_fail_correspondence_notices
                              .length > 0 &&
                            correspondenceData.check_fail_correspondence_notices.map(
                              (doc, docKey) => (
                                <p key={docKey}>
                                  <span
                                    onClick={() => this.handlePdfClick(doc)}
                                    className="application-text"
                                  >
                                    {doc.subject}
                                  </span>
                                </p>
                              ),
                            )}

                          {correspondenceData.evidence_documents &&
                            correspondenceData.evidence_documents.length > 0 &&
                            correspondenceData.evidence_documents.map(
                              (doc, docKey) => (
                                <p key={docKey}>
                                  <span
                                    onClick={() => this.handlePdfClick(doc)}
                                    className="application-text"
                                  >
                                    {doc.description}
                                  </span>
                                  <p>
                                    ({checkValue(doc.date) ? doc.date : "N.A."})
                                  </p>
                                </p>
                              ),
                            )}

                          {correspondenceData.examination_report_documents &&
                            correspondenceData.examination_report_documents
                              .length > 0 &&
                            correspondenceData.examination_report_documents.map(
                              (doc, docKey) => (
                                <p key={docKey}>
                                  <span
                                    onClick={
                                      checkValue(doc.url)
                                        ? () => this.handlePdfClickExam(doc)
                                        : ""
                                    }
                                    style={
                                      checkValue(doc.url)
                                        ? {
                                            cursor: "pointer",
                                            color: "#4285f4",
                                          }
                                        : {}
                                    }
                                  >
                                    EXAMINATION REPORT
                                  </span>
                                  <p>
                                    ({checkValue(doc.date) ? doc.date : "N.A."})
                                  </p>
                                </p>
                              ),
                            )}

                          {correspondenceData.examination_report_documents
                            .length === 0 &&
                            correspondenceData.opposition_correspondence_notices
                              .length === 0 &&
                            correspondenceData.opposition_documents.length ===
                              0 &&
                            correspondenceData.check_fail_documents.length ===
                              0 &&
                            correspondenceData.check_fail_correspondence_notices
                              .length === 0 &&
                            correspondenceData.evidence_documents.length ===
                              0 && <p className="m-0">N.A.</p>}
                        </div>
                      ) : this.state.filterDocDOD === "dispatch_doc_name" ? (
                        <div className="max_h_100px vertical_scroll">
                          {correspondenceData.evidence_correspondence_notices &&
                            correspondenceData.evidence_correspondence_notices
                              .length > 0 &&
                            correspondenceData.evidence_correspondence_notices.map(
                              (doc, docKey) => (
                                <p key={docKey}>
                                  <span
                                    onClick={() => this.handlePdfClick(doc)}
                                    className="application-text"
                                  >
                                    {doc.subject}
                                  </span>
                                  <p>
                                    (
                                    {checkValue(doc.date_of_dispatch)
                                      ? doc.date_of_dispatch
                                      : "N.A."}
                                    )
                                  </p>
                                </p>
                              ),
                            )}

                          {correspondenceData.check_fail_correspondence_notices &&
                            correspondenceData.check_fail_correspondence_notices
                              .length > 0 &&
                            correspondenceData.check_fail_correspondence_notices.map(
                              (doc, docKey) => (
                                <p key={docKey}>
                                  <span
                                    onClick={() => this.handlePdfClick(doc)}
                                    className="application-text"
                                  >
                                    {doc.subject}
                                  </span>
                                  <p>
                                    (
                                    {checkValue(doc.date_of_dispatch)
                                      ? doc.date_of_dispatch
                                      : "N.A."}
                                    )
                                  </p>
                                </p>
                              ),
                            )}

                          {correspondenceData.objected_correspondence_notices &&
                            correspondenceData.objected_correspondence_notices
                              .length > 0 &&
                            correspondenceData.objected_correspondence_notices.map(
                              (doc, docKey) => (
                                <p key={docKey}>
                                  <span
                                    onClick={() => this.handlePdfClick(doc)}
                                    className="application-text"
                                  >
                                    {doc.subject}
                                  </span>
                                  <p>
                                    (
                                    {checkValue(doc.date_of_dispatch)
                                      ? doc.date_of_dispatch
                                      : "N.A."}
                                    )
                                  </p>
                                </p>
                              ),
                            )}

                          {correspondenceData.opposition_correspondence_notices &&
                            correspondenceData.opposition_correspondence_notices
                              .length > 0 &&
                            correspondenceData.opposition_correspondence_notices.map(
                              (doc, docKey) => (
                                <p key={docKey}>
                                  <span
                                    onClick={() => this.handlePdfClick(doc)}
                                    className="application-text"
                                  >
                                    {doc.subject}
                                  </span>
                                  <p>
                                    (
                                    {checkValue(doc.date_of_dispatch)
                                      ? doc.date_of_dispatch
                                      : "N.A."}
                                    )
                                  </p>
                                </p>
                              ),
                            )}

                          {correspondenceData.objection_documents &&
                            correspondenceData.objection_documents.length > 0 &&
                            correspondenceData.objection_documents.map(
                              (doc, docKey) => (
                                <p key={docKey}>
                                  <span
                                    onClick={
                                      checkValue(doc.examination_report)
                                        ? () => this.handlePdfClick1(doc)
                                        : ""
                                    }
                                    style={
                                      checkValue(doc.examination_report)
                                        ? {
                                            cursor: "pointer",
                                            color: "#4285f4",
                                          }
                                        : {}
                                    }
                                  >
                                    Dispatch of Examination Report
                                  </span>
                                </p>
                              ),
                            )}

                          {correspondenceData.evidence_correspondence_notices
                            .length === 0 &&
                            correspondenceData.check_fail_correspondence_notices
                              .length === 0 &&
                            correspondenceData.objected_correspondence_notices
                              .length === 0 &&
                            correspondenceData.opposition_correspondence_notices
                              .length === 0 &&
                            correspondenceData.objection_documents.length ===
                              0 && <p className="m-0">N.A.</p>}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<Text strong>REPLY TO DOCUMENTS</Text>}
                    >
                      <div className="max_h_100px vertical_scroll">
                        {correspondenceData.opposition_counter_documents &&
                          correspondenceData.opposition_counter_documents
                            .length > 0 &&
                          correspondenceData.opposition_counter_documents.map(
                            (doc, docKey) => (
                              <p key={docKey}>
                                <span
                                  onClick={() => this.handlePdfClick(doc)}
                                  className="application-text"
                                >
                                  {doc.description}
                                </span>
                              </p>
                            ),
                          )}

                        {correspondenceData.opposition_counter_correspondence_notices &&
                          correspondenceData
                            .opposition_counter_correspondence_notices.length >
                            0 &&
                          correspondenceData.opposition_counter_correspondence_notices.map(
                            (doc, docKey) => (
                              <p key={docKey}>
                                <span
                                  onClick={() => this.handlePdfClick(doc)}
                                  className="application-text"
                                >
                                  {doc.subject}
                                </span>
                              </p>
                            ),
                          )}

                        {correspondenceData.check_fail_counter_documents &&
                          correspondenceData.check_fail_counter_documents
                            .length > 0 &&
                          correspondenceData.check_fail_counter_documents.map(
                            (doc, docKey) => (
                              <p key={docKey}>
                                <span
                                  onClick={() => this.handlePdfClick(doc)}
                                  className="application-text"
                                >
                                  {doc.description}
                                </span>
                              </p>
                            ),
                          )}

                        {correspondenceData.check_fail_counter_correspondence_notices &&
                          correspondenceData
                            .check_fail_counter_correspondence_notices.length >
                            0 &&
                          correspondenceData.check_fail_counter_correspondence_notices.map(
                            (doc, docKey) => (
                              <p key={docKey}>
                                <span
                                  onClick={() => this.handlePdfClick(doc)}
                                  className="application-text"
                                >
                                  {doc.subject}
                                </span>
                              </p>
                            ),
                          )}

                        {correspondenceData.objection_counter_documents &&
                          correspondenceData.objection_counter_documents
                            .length > 0 &&
                          correspondenceData.objection_counter_documents.map(
                            (doc, docKey) => (
                              <p key={docKey}>
                                <span
                                  onClick={() => this.handlePdfClick(doc)}
                                  className="application-text"
                                >
                                  {doc.description}
                                </span>
                              </p>
                            ),
                          )}

                        {correspondenceData.evidence_counter_documents &&
                          correspondenceData.evidence_counter_documents.length >
                            0 &&
                          correspondenceData.evidence_counter_documents.map(
                            (doc, docKey) => (
                              <p key={docKey}>
                                <span
                                  onClick={() => this.handlePdfClick(doc)}
                                  className="application-text"
                                >
                                  {doc.description}
                                </span>
                              </p>
                            ),
                          )}

                        {correspondenceData.objection_counter_documents
                          .length === 0 &&
                          correspondenceData.opposition_counter_documents
                            .length === 0 &&
                          correspondenceData
                            .opposition_counter_correspondence_notices
                            .length === 0 &&
                          correspondenceData.check_fail_counter_documents
                            .length === 0 &&
                          correspondenceData
                            .check_fail_counter_correspondence_notices
                            .length === 0 &&
                          correspondenceData.evidence_counter_documents
                            .length === 0 && <p className="m-0">N.A.</p>}
                      </div>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<Text strong>TIME REMAINING</Text>}
                    >
                      {this.state.filterRemainingDate === "remaining_days" ? (
                        <div className="max_h_100px vertical_scroll">
                          {correspondenceData.opposition_documents &&
                            correspondenceData.opposition_documents.length >
                              0 &&
                            correspondenceData.opposition_documents.map(
                              (doc, docKey) => (
                                <p key={docKey}>
                                  <span>
                                    {doc.remaining_days
                                      ? doc.remaining_days
                                      : 0}{" "}
                                    Days
                                  </span>
                                  <span
                                    className={
                                      doc.alert ? "visible" : "not-visible"
                                    }
                                  >
                                    <Tooltip
                                      title="Deadline to file reply for this mark has passed"
                                      className="toolTip-custom"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                        style={{
                                          color: "#e1001b",
                                          paddingLeft: "5px",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </Tooltip>
                                  </span>
                                </p>
                              ),
                            )}

                          {correspondenceData.check_fail_correspondence_notices &&
                            correspondenceData.check_fail_correspondence_notices
                              .length > 0 &&
                            correspondenceData.check_fail_correspondence_notices.map(
                              (doc, docKey) => (
                                <p key={docKey}>
                                  <span>
                                    {doc.remaining_days
                                      ? doc.remaining_days
                                      : 0}{" "}
                                    Days
                                  </span>
                                  <span
                                    className={
                                      doc.alert ? "visible" : "not-visible"
                                    }
                                  >
                                    <Tooltip
                                      title="Deadline to file reply for this mark has passed"
                                      className="toolTip-custom"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                        style={{
                                          color: "#e1001b",
                                          paddingLeft: "5px",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </Tooltip>
                                  </span>
                                </p>
                              ),
                            )}

                          {correspondenceData.evidence_documents &&
                            correspondenceData.evidence_documents.length > 0 &&
                            correspondenceData.evidence_documents.map(
                              (doc, docKey) => (
                                <p key={docKey}>
                                  <span>
                                    {doc.remaining_days
                                      ? doc.remaining_days
                                      : 0}{" "}
                                    Days
                                  </span>
                                  <span
                                    className={
                                      doc.alert ? "visible" : "not-visible"
                                    }
                                  >
                                    <Tooltip
                                      title="Deadline to file reply for this mark has passed"
                                      className="toolTip-custom"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                        style={{
                                          color: "#e1001b",
                                          paddingLeft: "5px",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </Tooltip>
                                  </span>
                                </p>
                              ),
                            )}

                          {correspondenceData.examination_report_documents &&
                            correspondenceData.examination_report_documents
                              .length > 0 &&
                            correspondenceData.examination_report_documents.map(
                              (doc, docKey) => (
                                <p key={docKey}>
                                  <span>
                                    {doc.remaining_days
                                      ? doc.remaining_days
                                      : 0}{" "}
                                    Days
                                  </span>
                                  <span
                                    className={
                                      doc.alert ? "visible" : "not-visible"
                                    }
                                  >
                                    <Tooltip
                                      title="Deadline to file reply for this mark has passed"
                                      className="toolTip-custom"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                        style={{
                                          color: "#e1001b",
                                          paddingLeft: "5px",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </Tooltip>
                                  </span>
                                </p>
                              ),
                            )}

                          {correspondenceData.opposition_documents.length ===
                            0 &&
                            correspondenceData.check_fail_correspondence_notices
                              .length === 0 &&
                            correspondenceData.evidence_documents.length ===
                              0 &&
                            correspondenceData.examination_report_documents
                              .length === 0 &&
                            correspondenceData.objected_correspondence_notices
                              .length === 0 && <p className="m-0">N.A.</p>}
                        </div>
                      ) : this.state.filterRemainingDate ===
                        "remaining_days_wrt_dod" ? (
                        <div className="max_h_100px vertical_scroll">
                          {correspondenceData.evidence_correspondence_notices &&
                            correspondenceData.evidence_correspondence_notices
                              .length > 0 &&
                            correspondenceData.evidence_correspondence_notices.map(
                              (doc, docKey) => (
                                <p key={docKey}>
                                  <span>
                                    {doc.remaining_days_wrt_dod
                                      ? doc.remaining_days_wrt_dod
                                      : 0}{" "}
                                    Days
                                  </span>
                                  <span
                                    className={
                                      doc.alert ? "visible" : "not-visible"
                                    }
                                  >
                                    <Tooltip
                                      title="Deadline to file reply for this mark has passed"
                                      className="toolTip-custom"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                        style={{
                                          color: "#e1001b",
                                          paddingLeft: "5px",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </Tooltip>
                                  </span>
                                </p>
                              ),
                            )}

                          {correspondenceData.check_fail_correspondence_notices &&
                            correspondenceData.check_fail_correspondence_notices
                              .length > 0 &&
                            correspondenceData.check_fail_correspondence_notices.map(
                              (doc, docKey) => (
                                <p key={docKey}>
                                  <span>
                                    {doc.remaining_days_wrt_dod
                                      ? doc.remaining_days_wrt_dod
                                      : 0}{" "}
                                    Days
                                  </span>
                                  <span
                                    className={
                                      doc.alert ? "visible" : "not-visible"
                                    }
                                  >
                                    <Tooltip
                                      title="Deadline to file reply for this mark has passed"
                                      className="toolTip-custom"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                        style={{
                                          color: "#e1001b",
                                          paddingLeft: "5px",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </Tooltip>
                                  </span>
                                </p>
                              ),
                            )}

                          {correspondenceData.objected_correspondence_notices &&
                            correspondenceData.objected_correspondence_notices
                              .length > 0 &&
                            correspondenceData.objected_correspondence_notices.map(
                              (doc, docKey) => (
                                <p key={docKey}>
                                  <span>
                                    {doc.remaining_days_wrt_dod
                                      ? doc.remaining_days_wrt_dod
                                      : 0}{" "}
                                    Days
                                  </span>
                                  <span
                                    className={
                                      doc.alert ? "visible" : "not-visible"
                                    }
                                  >
                                    <Tooltip
                                      title="Deadline to file reply for this mark has passed"
                                      className="toolTip-custom"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                        style={{
                                          color: "#e1001b",
                                          paddingLeft: "5px",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </Tooltip>
                                  </span>
                                </p>
                              ),
                            )}

                          {correspondenceData.opposition_correspondence_notices &&
                            correspondenceData.opposition_correspondence_notices
                              .length > 0 &&
                            correspondenceData.opposition_correspondence_notices.map(
                              (doc, docKey) => (
                                <p key={docKey}>
                                  <span>
                                    {doc.remaining_days_wrt_dod
                                      ? doc.remaining_days_wrt_dod
                                      : 0}{" "}
                                    Days
                                  </span>
                                  <span
                                    className={
                                      doc.alert ? "visible" : "not-visible"
                                    }
                                  >
                                    <Tooltip
                                      title="Deadline to file reply for this mark has passed"
                                      className="toolTip-custom"
                                    >
                                      <i
                                        className="fa fa-exclamation-circle"
                                        aria-hidden="true"
                                        style={{
                                          color: "#e1001b",
                                          paddingLeft: "5px",
                                          cursor: "pointer",
                                        }}
                                      ></i>
                                    </Tooltip>
                                  </span>
                                </p>
                              ),
                            )}

                          {correspondenceData.evidence_correspondence_notices
                            .length === 0 &&
                            correspondenceData.check_fail_correspondence_notices
                              .length === 0 &&
                            correspondenceData.objected_correspondence_notices
                              .length === 0 &&
                            correspondenceData.opposition_correspondence_notices
                              .length === 0 && <p className="m-0">N.A.</p>}
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text strong>ACTION</Text>}>
                      <div>
                        <p
                          className="application-text"
                          onClick={() =>
                            this.props.replyFiled(correspondenceData)
                          }
                        >
                          Reply Filed
                        </p>
                        <Tooltip placement="top" title={"Create To-do Task"}>
                          <p>
                            <CarryOutTwoTone
                              onClick={this.props.changeStateValue_L0}
                              style={{ cursor: "pointer", fontSize: "30px" }}
                            />
                          </p>
                        </Tooltip>
                        <Tooltip placement="top" title={"Send email"}>
                          <p
                            onClick={() =>
                              this.props.handleEmail(correspondenceData)
                            }
                          >
                            <i
                              className="fa fa-paper-plane"
                              style={{
                                padding: "0px 5px",
                                color: "#1890ff",
                                fontSize: 20,
                              }}
                              aria-hidden="true"
                            ></i>
                          </p>
                        </Tooltip>
                      </div>
                    </Descriptions.Item>
                  </>
                </Descriptions>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Card>
          </Col>
        </Row>
        <ReplyFiledModal
          // visible={this.state.replyFiledVisible}
          // onCloseModal = {()=> this.changeStateValue_L0('replyFiledVisible', false)}
          // mm_type = {'reply_to_correspondance'}
          // pending_reply = {this.state.pending_reply}
          // application_number = {this.state.application_number}
          // applied_for = {this.state.applied_for}
          // pseudo = {this.state.pseudo}
          // mark_type={'Portfolio trademark'}
          // actionType={REPLY_FILED_TO_CORRESPONDENCE}
          //-----------------------------------------------------------------------
          visible={this.state.replyFiledVisible}
          onCloseModal={() =>
            this.changeStateValue_L0("replyFiledVisible", false)
          }
          mm_type={"reply_to_correspondance"}
          application_number={this.state.add_trademark_id}
          pseudo={false}
          mark_type={"Portfolio trademark"}
          actionType={REPLY_FILED_TO_CORRESPONDENCE}
          pending_reply={false}
          reRender={() => this.reRender()}
        />
        <CreateTask
          visible={this.state.addTaskVisible}
          onCloseModal={() => this.changeStateValue_L0("addTaskVisible", false)}
          type={"trademark"}
        />

        <ManagerNotes
          visible={this.state.managerNotes}
          onCloseModal={() => this.changeStateValue_L0("managerNotes", false)}
          actionType={[POST_TM_NOTES, PATCH_TM_NOTES]}
          email={
            checkValue(this.props.userDetails)
              ? this.props.userDetails.email
              : ""
          }
          note_for={TM}
          application_number={this.state.application_number}
          type={"ADD"}
        />

        <SendEmailNew
          visible={this.state.modalsVisibility.sendEmail}
          onCloseModal={() =>
            this.handleEmail("modalsVisibility", "sendEmail", false, {})
          }
          sendFrom={
            this.props.userDetails && this.props.userDetails.communication_email
          }
          sendCC={this.props.userTeam && this.props.userTeam.manager}
          mailContent={this.state.modalsVisibility.mailContent}
          product={this.state.modalsVisibility.product}
          subscription={this.state.modalsVisibility.subscription}
          report_tag={this.state.modalsVisibility.report_tag}
          objectType={this.state.modalsVisibility.objectType}
          applicationNumber={this.state.modalsVisibility.applicationNumber}
          markId={this.state.modalsVisibility.markId}
          doc_link={true}
          sourceId={this.state.modalsVisibility.sourceId}
          source={`TRADEMARK`}
          sub_section="DASHBOARD"
          managerEmail={true}
        />
      </div>
    );
  }
}
const mapDispatchToProps = {
  getReplyToCorrespondence,
};
function mapStateToProps(state) {
  const { replyToCorrespondence } = state.managerState.trademarkDashboardState;

  return deepFreeze({
    replyToCorrespondence,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CorrespondenceTable);
