import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import _ from "lodash";

import { CarryOutTwoTone, MessageTwoTone } from "@ant-design/icons";

import {
  Row,
  Col,
  Table,
  Card,
  Button,
  message,
  Switch,
  Tooltip,
  Typography,
  notification,
  Modal,
} from "antd";

//--------IP-SUITE CONSTANTS--------
import { LOADING } from "../../../../constants/commonConstants/generalConstants";

//--------ACTIONS--------
import { getReplyToCorrespondenceDashboard } from "../../../../redux/actions/managerActions/trademarkDashboard/actions";

//--------HISTORY--------
import { history } from "../../../../history";

//--------CHECK VALUE FUNCTIONS--------
import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";
import {
  changeStateValue_L0,
  changeStateValue_L1,
} from "../../../../functions/commonFunctions/generalFunctions";

//--------MODALS--------
import CreateTask from "../../to-do-task/CreateTask";
import DownloadReportModal from "../../report/DownloadReportModal";
import ReplyFiledModal from "../ReplyFiled";
import { logOut } from "../../../../redux/actions/commonActions/actions";

import ManagerNotes from "../../subComponents/Modal/managerNotes";
import { TM } from "../../../../constants/managerConstants/managerConstants";
import {
  POST_TM_NOTES,
  PATCH_TM_NOTES,
} from "../../../../redux/actions/managerActions/commonAction/types";
import {
  OPPOSED,
  PSEUDO_OPPOSED,
  DASHBOARD_REPLY_FILED_TO_CORRESPONDENCE,
} from "../../../../redux/actions/managerActions/trademarkDashboard/types";
import { TM_DefaultSelectedFilters } from "../../../../constants/managerConstants/trademarkConstants";
import SendEmail from "../../../commonComponents/Modals/sendMail";
import SendEmailNew from "../../../commonComponents/Modals/SendEmailNew";
import { getEmailTemplateData } from "../../../../redux/actions/commonActions/actions";

const { Text } = Typography;

class OpposedStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      pageNumber: 1,
      add_trademark_id: [],
      selectedRowKeys: [],
      selectedRows: [],
      management_trademark_id: "",
      checkedList: [],
      selectAll: false,
      addTaskVisible: false,
      changeSwitch: false,
      replyFiledVisible: false,
      selectedFilters: {
        ip_india: {
          filterFlag: true,
          trademarkStatus: "Opposed,Rectification Filed,Opposed*",
        },
        pseudo: {
          filterFlag: true,
          trademarkStatus: "Opposed,Rectification Filed,Opposed*",
        },
      },
      filterDocDOD: "doc_name",
      filterRemainingDate: "remaining_days",
      managerNotes: false,
      modalsVisibility: {
        sendEmail: false,
        mailContent: "",
        report_tag: {},
      },
    };
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.userTrademark === null) {
        this.props.getReplyToCorrespondenceDashboard({
          actionType: OPPOSED,
          mmType: "trademark",
          pseudo: false,
          filterFlag: true,
          filters: {
            ...TM_DefaultSelectedFilters,
            trademarkStatus: "Opposed,Rectification Filed,Opposed*",
          },
        });
      }
      if (this.props.pseudoTrademark === null) {
        this.props.getReplyToCorrespondenceDashboard({
          actionType: PSEUDO_OPPOSED,
          mmType: "trademark",
          pseudo: true,
          filterFlag: true,
          filters: {
            ...TM_DefaultSelectedFilters,
            trademarkStatus: "Opposed,Rectification Filed,Opposed*",
          },
        });
      }
    } else {
      this.props.logOut();
    }
  }

  handleTrademarkProfile(data) {
    if (data) {
      sessionStorage.setItem(
        "management_trademark_id",
        data.management_mark_id,
      );
      sessionStorage.setItem(
        "trademark_application_number",
        data.application_number,
      );
      window.open(
        "/manager/trademark/trademark-profile/" + data.management_mark_id,
        "_blank",
      );
      this.setState({
        management_trademark_id: data.management_mark_id,
      });
    }
  }

  handlePdfClick(data) {
    let url = "";
    if (data?.file) {
      url = data?.file;
    } else {
      url = data?.url;
    }
    if (url !== null) window.open(url, "_blank");
  }

  handlePdfClick1(data) {
    let url = "";
    if (data?.url.includes("http://")) {
      url = data?.url;
    } else {
      url = data?.url;
    }
    if (url !== null) window.open(url, "_blank");
  }

  downloadReport() {
    if (this.state.add_trademark_id.length > 0 || this.state.selectAll) {
      this.setState({
        visible: true,
      });
    } else {
      message.error("no mark selected");
    }
  }

  reasonShow(data) {
    Modal.info({
      title: "Reason for Notice",
      content: (
        <div>
          <p>{data}</p>
        </div>
      ),
      onOk() {},
    });
  }

  replyFiled() {
    if (this.state.add_trademark_id.length <= 0) {
      message.error("no mark selected");
      return;
    }
    this.setState({
      replyFiledVisible: true,
      pending_reply: false,
      // application_number: [data.id],
      // applied_for: data.applied_for,
      // pseudo: this.state.changeSwitch,
      // filter_flag: true,
      // trademark_status: 'Opposed',
    });
  }
  reRender() {
    this.props.getReplyToCorrespondenceDashboard({
      actionType: PSEUDO_OPPOSED,
      mmType: "trademark",
      pseudo: true,
      filterFlag: true,
      filters: {
        ...TM_DefaultSelectedFilters,
        trademarkStatus: "Opposed,Rectification Filed,Opposed*",
      },
    });

    this.props.getReplyToCorrespondenceDashboard({
      actionType: OPPOSED,
      mmType: "trademark",
      pseudo: false,
      filterFlag: true,
      filters: {
        ...TM_DefaultSelectedFilters,
        trademarkStatus: "Opposed,Rectification Filed,Opposed*",
      },
    });
    this.setState({
      add_trademark_id: [],
      selectAll: false,
      selectedRowKeys: [],
    });
  }

  handleNextpage = (pageNumber) => {
    this.props.getReplyToCorrespondenceDashboard({
      actionType: this.state.changeSwitch ? PSEUDO_OPPOSED : OPPOSED,
      mmType: "trademark",
      pseudo: this.state.changeSwitch,
      filterFlag: true,
      filters: {
        ...TM_DefaultSelectedFilters,
        trademarkStatus: "Opposed,Rectification Filed,Opposed*",
      },
      pageNumber: pageNumber,
    });
    this.setState({
      pageNumber: pageNumber,
    });
  };

  changeSwitch() {
    this.setState({
      changeSwitch: !this.state.changeSwitch,
      add_trademark_id: [],
      selectAll: false,
      selectedRowKeys: [],
      pageNumber: 1,
    });
  }

  handleManagerNotes(data) {
    this.setState({
      managerNotes: true,
      application_number: data.application_number,
    });
  }

  async handleEmail(modalsVisibility, modal, value, data) {
    let mailContent = "<p><strong>Trademark Manager</strong></p>";
    this.setState({
      [modalsVisibility]: {
        ...this.state[modalsVisibility],
        [modal]: value,
        mailContent: mailContent,
        product: "MANAGER",
        subscription: [
          this.state.changeSwitch ? "PSEUDO_TRADEMARK" : "TRADEMARK",
        ],
        report_tag: {
          journal_number: checkValue(data.journal) ? data.journal.number : "",
          journal_date: checkValue(data.journal)
            ? data.journal.date_of_publication
            : "",
          status: checkValue(data.status) ? data.status : "",
          renewal_date: checkValue(data.valid_upto) ? data.valid_upto : "",
          application_number: checkValue(data.application_number)
            ? data.application_number
            : "",
          mark_name: checkValue(data.applied_for) ? data.applied_for : "",
          date_of_application: checkValue(data.application_date)
            ? data.application_date
            : "",
          class: checkValue(data.associated_class)
            ? data.associated_class.join(", ")
            : "",
          date_of_usage: checkValue(data.date_of_usage)
            ? data.date_of_usage
            : "",
          proprietor_name: checkValue(data.proprietor)
            ? data.proprietor.map((prop) => prop.name).join(", ")
            : "",
          opponent_name: checkValue(data.opponent_name)
            ? data.opponent_name
            : "",
          tla_hearing_date: "",
          opposition_hearing_date: "",
          report_name: "",
        },
        objectType: this.state.changeSwitch ? "pseudo_trademark" : "trademark",
        applicationNumber: data.application_number,
        markId: data.management_mark_id,
        sourceId: data.management_mark_id,
      },
    });
    if (value) {
      await this.props.getEmailTemplateData({
        subscription: "TRADEMARK",
        sub_section: "DASHBOARD",
        product: "MANAGER",
        object_id: data?.application_number,
      });
    }
  }

  render() {
    const {
      userTrademark,
      userTrademarkStatus,
      pseudoTrademark,
      pseudoTrademarkStatus,
    } = this.props;

    const TrademarkColumns = [
      {
        title: "TRADEMARK",
        className: "reply-to-correspondence-column-1",
        render: (text, record) => {
          return (
            <div>
              <p
                className="application-text"
                onClick={() => this.handleTrademarkProfile(record)}
              >
                <span>
                  {checkValue(record.application_number)
                    ? record.application_number
                    : "N.A."}{" "}
                  -{" "}
                </span>
                <span className="text-camelCase">
                  {checkValue(record.applied_for)
                    ? record.applied_for.toLowerCase()
                    : "N.A."}
                </span>
              </p>
              <p>
                <img
                  className="trademark-image"
                  src={
                    checkValue(record.associated_image)
                      ? record.associated_image
                      : ""
                  }
                  alt=""
                />
              </p>
            </div>
          );
        },
      },

      {
        title: "STATUS | APPLICATION DATE | CLASS",
        className: "reply-to-correspondence-column-2",
        render: (text, record) => {
          return (
            <div>
              <p className="text-camelCase">
                {checkValue(record.status)
                  ? record.status.toLowerCase()
                  : "N.A."}
              </p>
              <p className="text-camelCase">
                {checkValue(record.application_date)
                  ? record.application_date
                  : "N.A."}
              </p>
              <p className="text-camelCase">
                {checkValue(record.associated_class)
                  ? record.associated_class.join(", ")
                  : "N.A."}
              </p>
            </div>
          );
        },
      },

      {
        title: "OPPONENT NAME | OPPONENT NUMBER",
        className: "reply-to-correspondence-column-2",
        render: (text, record) => {
          return (
            <div>
              <span className="text-camelCase">
                {checkValue(record.opponent_name)
                  ? record.opponent_name.toLowerCase()
                  : "N.A."}
              </span>
              {record.opponent_number && (
                <p>
                  (
                  {checkValue(record.opponent_number)
                    ? record.opponent_number
                    : "N.A."}
                  )
                </p>
              )}
            </div>
          );
        },
      },

      {
        title: "DOCUMENT | TIME REMAINING",
        className: "reply-to-correspondence-column-2",
        render: (text, record) => {
          return (
            <div>
              {record.documents &&
                record.documents.length > 0 &&
                record.documents.map((doc) => (
                  <p className="h_80px vertical_scroll">
                    <span
                      onClick={() => this.handlePdfClick(doc)}
                      className="application-text"
                    >
                      {doc.description}
                    </span>
                    <p>({checkValue(doc.date) ? doc.date : "N.A."})</p>
                    <div>
                      <span>{doc.remaining_days} Days</span>
                      <span className={doc.alert ? "visible" : "not-visible"}>
                        <Tooltip
                          title="Deadline to file reply for this mark has passed"
                          className="toolTip-custom"
                        >
                          <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                            style={{
                              color: "#e1001b",
                              paddingLeft: "5px",
                              cursor: "pointer",
                            }}
                          ></i>
                        </Tooltip>
                      </span>
                    </div>
                  </p>
                ))}
              {/* { record.correspondence_notices && record.correspondence_notices.length > 0 && record.correspondence_notices.map(doc => 
                                <p className="h_80px vertical_scroll">
                                    <div>
                                        <span onClick={()=> this.handlePdfClick(doc)} className="application-text">{doc.subject}</span>
                                        <p>({checkValue(doc.date_of_correspondence) ? doc.date_of_correspondence : 'N.A.'})</p>
                                        <div>
                                            <span>{doc.remaining_days} Days</span>
                                            <span className={doc.alert ? 'visible' : 'not-visible'}>
                                                <Tooltip title="Deadline to file reply for this mark has passed" className="toolTip-custom">
                                                    <i className="fa fa-exclamation-circle" aria-hidden="true" style={{color:'#e1001b', paddingLeft: '5px', cursor: 'pointer'}}></i>
                                                </Tooltip>
                                            </span>
                                        </div>
                                    </div>
                                </p>
                            )} */}
              {record.documents.length === 0 && (
                <p className="h_80px vertical_scroll">N.A.</p>
              )}
            </div>
          );
        },
      },

      {
        title: "DISPATCH DOCUMENT | TIME REMAINING",
        className: "reply-to-correspondence-column-2",
        render: (text, record) => {
          return (
            <div>
              {record.correspondence_notices &&
                record.correspondence_notices.length > 0 &&
                record.correspondence_notices.map((doc) => (
                  <p className="h_80px vertical_scroll">
                    <div>
                      <span
                        onClick={() => this.handlePdfClick(doc)}
                        className="application-text"
                      >
                        {doc.subject}
                      </span>
                      <p>
                        (
                        {checkValue(doc.date_of_dispatch)
                          ? doc.date_of_dispatch
                          : "N.A."}
                        )
                      </p>
                    </div>
                    <div>
                      <span>{doc.remaining_days_wrt_dod} Days</span>
                      <span
                        className={
                          doc.alert_wrt_dod ? "visible" : "not-visible"
                        }
                      >
                        <Tooltip
                          title="Deadline to file reply for this mark has passed"
                          className="toolTip-custom"
                        >
                          <i
                            className="fa fa-exclamation-circle"
                            aria-hidden="true"
                            style={{
                              color: "#e1001b",
                              paddingLeft: "5px",
                              cursor: "pointer",
                            }}
                          ></i>
                        </Tooltip>
                      </span>
                    </div>
                  </p>
                ))}
              {record.correspondence_notices.length === 0 && (
                <p className="h_80px vertical_scroll">N.A.</p>
              )}
            </div>
          );
        },
      },

      {
        title: "TAGS",
        dataIndex: "tags",
        className: "reply-to-correspondence-column-1",
        render: (tags) => {
          return (
            <div>
              {tags.length > 0
                ? tags.map((tag) => (
                    <span
                      key={tag.id}
                      className="Tag"
                      style={{
                        color: `${tag.color_code}`,
                        border: `1px solid ${tag.color_code}`,
                      }}
                    >
                      {tag.name.toUpperCase()}
                    </span>
                  ))
                : "N.A."}
            </div>
          );
        },
      },

      {
        title: "ACTION",
        className: "reply-to-correspondence-column-1",
        render: (action) => {
          return (
            <div>
              {/* <p className="application-text alignC" onClick={() => this.replyFiled(action)}>Reply Filed</p> */}
              <Tooltip placement="top" title={"Create To-do Task"}>
                <p className="alignC">
                  <CarryOutTwoTone
                    onClick={() =>
                      this.changeStateValue_L0("addTaskVisible", true)
                    }
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Notes"}>
                <p className="alignC">
                  <MessageTwoTone
                    onClick={() => this.handleManagerNotes(action)}
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Send email"}>
                <p
                  className="alignC"
                  onClick={() =>
                    this.handleEmail(
                      "modalsVisibility",
                      "sendEmail",
                      true,
                      action,
                    )
                  }
                >
                  <i
                    className="fa fa-paper-plane"
                    style={{
                      padding: "0px 5px",
                      color: "#1890ff",
                      fontSize: 20,
                    }}
                    aria-hidden="true"
                  ></i>
                </p>
              </Tooltip>
            </div>
          );
        },
      },
    ];

    const TrademarkRowSelection = {
      onSelect: (record, selected, selectedRows, nativeEvent) => {
        if (selected) {
          let newSelected = [...this.state.selectedRows, record];

          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            add_trademark_id: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        } else {
          let newSelected = this.state.selectedRows.filter(
            (x) => x.id !== record.id,
          );
          this.setState({
            selectedRowKeys: newSelected.map((x) => x.id),
            add_trademark_id: newSelected.map((x) => x.id),
            selectedRows: newSelected,
          });
        }
      },

      onSelectAll: (selected, selectedRows) => {
        let data = this.state.changeSwitch
          ? pseudoTrademark &&
            pseudoTrademark.pseudo &&
            pseudoTrademark.pseudo.trademarks
          : userTrademark &&
            userTrademark.ip_india &&
            userTrademark.ip_india.trademarks;

        this.setState({
          selectAll: selected,
          selectedRows: selectedRows,
          selectedRowKeys: data.map((row) => row.id),
        });
        if (selected) {
          notification.open({
            message: "Deselect All Mark",
            description:
              "Click on the arrow near the select all checkbox to deselect all marks",
          });
        }
      },

      hideDefaultSelections: true,
      selections: this.state.selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All Data",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  selectedRowKeys: changeableRowKeys,
                  selectAll: !this.state.selectAll,
                  add_trademark_id: [],
                  selectedRows: [],
                });
              },
            },
          ]
        : false,

      selectedRowKeys: this.state.selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled: this.state.selectAll,
      }),
    };

    return (
      <div>
        {/* Change-Switch */}
        <Row
          className={
            checkValue(this.props.userSubscriptions)
              ? this.props.userSubscriptions.manager.includes(
                  "PSEUDO_TRADEMARK",
                )
                ? ""
                : "display_none"
              : "display_none"
          }
        >
          <Col span={24} className="alignR m-top-10">
            <Text strong>IP INDIA</Text>
            <Switch
              className="change-manager-type"
              onChange={() => this.changeSwitch()}
              checked={this.state.changeSwitch}
            />
            <Text strong>CUSTOM</Text>
          </Col>
        </Row>
        {/* Change-Switch */}

        {/* OPPOSED */}
        <Row>
          <Col span={24} className="m-top-10">
            <Card
              title="OPPOSED"
              extra={
                <div>
                  <Text style={{ padding: "0px 5px" }}>
                    {this.state.changeSwitch
                      ? this.state.selectAll
                        ? pseudoTrademark && pseudoTrademark.pseudo.count
                        : _.uniq(this.state.add_trademark_id).length
                      : this.state.selectAll
                        ? userTrademark && userTrademark.ip_india.count
                        : _.uniq(this.state.add_trademark_id).length}{" "}
                    Mark(s)
                  </Text>
                  <Button
                    type="primary"
                    onClick={() => this.downloadReport()}
                    style={{ marginRight: "10px" }}
                  >
                    Report
                  </Button>
                  <Button
                    type="primary"
                    disabled={this.state?.selectAll}
                    onClick={() => this.replyFiled()}
                  >
                    Reply Filed
                  </Button>
                </div>
              }
            >
              <Table
                bordered
                rowKey={(record) => record.id}
                rowSelection={TrademarkRowSelection}
                columns={TrademarkColumns}
                dataSource={
                  this.state.changeSwitch
                    ? pseudoTrademark &&
                      pseudoTrademark.pseudo &&
                      pseudoTrademark.pseudo.trademarks
                    : userTrademark &&
                      userTrademark.ip_india &&
                      userTrademark.ip_india.trademarks
                }
                pagination={{
                  pageSize: 100,
                  showQuickJumper: true,
                  showSizeChanger: false,
                  defaultCurrent: 1,
                  total: this.state.changeSwitch
                    ? pseudoTrademark &&
                      pseudoTrademark.pseudo &&
                      pseudoTrademark.pseudo.count
                    : userTrademark &&
                      userTrademark.ip_india &&
                      userTrademark.ip_india.count,
                  onChange: this.handleNextpage,
                }}
                scroll={{ y: "calc(70vh - 200px)" }}
                loading={
                  this.state.changeSwitch
                    ? pseudoTrademarkStatus === LOADING
                    : userTrademarkStatus === LOADING
                }
              />
            </Card>
          </Col>
        </Row>
        {/* Opposed */}

        {this.state.visible && (
          <DownloadReportModal
            visible={this.state.visible}
            onCloseModal={() => this.setState({ visible: false })}
            type={"trademark"}
            mm_type={"trademark"}
            add_trademark_id={this.state.add_trademark_id}
            selected_pending_replies={this.state.add_trademark_id}
            all_pending_replies={this.state.selectAll}
            pending_reply_to_correspondence={true}
            changeSwitch={this.state.changeSwitch}
            comparative={
              checkValue(this.props.userSubscriptions)
                ? this.props.userSubscriptions.manager.includes(
                    "PSEUDO_TRADEMARK",
                  )
                : false
            }
            filters={
              this.state.changeSwitch
                ? this.state.selectedFilters.pseudo
                : this.state.selectedFilters.ip_india
            }
            reRender={() => this.reRender()}
          />
        )}

        <ReplyFiledModal
          visible={this.state.replyFiledVisible}
          onCloseModal={() =>
            this.changeStateValue_L0("replyFiledVisible", false)
          }
          mm_type={"Opposed"}
          pending_reply={this.state.pending_reply}
          application_number={this.state.add_trademark_id}
          applied_for={this.state.applied_for}
          pseudo={this.state.changeSwitch}
          filter_flag={this.state.filter_flag}
          trademark_status={"Opposed"}
          mark_type={"Portfolio trademark"}
          actionType={DASHBOARD_REPLY_FILED_TO_CORRESPONDENCE}
          reRender={() => this.reRender()}
          count={_.uniq(this.state.add_trademark_id)?.length}
        />

        <CreateTask
          visible={this.state.addTaskVisible}
          onCloseModal={() => this.changeStateValue_L0("addTaskVisible", false)}
          type={"trademark"}
        />

        <ManagerNotes
          visible={this.state.managerNotes}
          onCloseModal={() => this.changeStateValue_L0("managerNotes", false)}
          actionType={[POST_TM_NOTES, PATCH_TM_NOTES]}
          email={
            checkValue(this.props.userDetails)
              ? this.props.userDetails.email
              : ""
          }
          note_for={TM}
          application_number={this.state.application_number}
          type={"ADD"}
        />

        <SendEmailNew
          visible={this.state.modalsVisibility.sendEmail}
          onCloseModal={() =>
            this.handleEmail("modalsVisibility", "sendEmail", false, {})
          }
          sendFrom={
            this.props.userDetails && this.props.userDetails.communication_email
          }
          sendCC={this.props.userTeam && this.props.userTeam.manager}
          mailContent={this.state.modalsVisibility.mailContent}
          product={this.state.modalsVisibility.product}
          subscription={this.state.modalsVisibility.subscription}
          report_tag={this.state.modalsVisibility.report_tag}
          objectType={this.state.modalsVisibility.objectType}
          mm_type={"Portfolio trademark"}
          applicationNumber={this.state.modalsVisibility.applicationNumber}
          markId={this.state.modalsVisibility.markId}
          doc_link={true}
          sourceId={this.state.modalsVisibility.sourceId}
          source={`TRADEMARK`}
          sub_section="DASHBOARD"
          managerEmail={true}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getReplyToCorrespondenceDashboard,
  logOut,
  getEmailTemplateData,
};

function mapStateToProps(state) {
  const { tmDashboard, tmDashboardStatus } = state.managerState.profileState;
  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,

    tmDashboard,
    tmDashboardStatus,

    pseudo: state.managerState.correspondanceState.pseudo,

    userTrademark: state.managerState.correspondanceState.opppsed,
    userTrademarkStatus: state.managerState.correspondanceState.opppsedStatus,
    userTrademarkPageNumber:
      state.managerState.correspondanceState.opppsedPageNumber,

    pseudoTrademark: state.managerState.correspondanceState.pseudoOpppsed,
    pseudoTrademarkStatus:
      state.managerState.correspondanceState.pseudoOpppsedStatus,
    pseudoTrademarkPageNumber:
      state.managerState.correspondanceState.pseudoOpppsedPageNumber,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(OpposedStatus);
