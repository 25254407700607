export const reportCols = [
  "Trademark Name",
  "Application Number",
  "IR Number",
  "Status",
  "Opponent Name",
  "Notice Document",
  "Date of Notice Document",
  "Time Remaining as per Date of Upload",
  "Due Date as per Date of Upload",
  "Reasons for Notice",
  "Dispatch Notice",
  "Date of Dispatch",
  "Time Remaining as per Date of Dispatch",
  "Due Date as per Date of Dispatch",
  "Classes",
  "Trademark Type",
  "Trademark Category",
  "Date Of Application",
  "Date of Usage",
  "Valid Upto",
  "Proprietor Name",
  "Proprietor Address",
  "Proprietor Trading As",
  "Proprietor Type Details",
  "Description",
  "Application Office",
  "State",
  "Country",
  "Email",
  "Attorney Name",
  "Attorney Address",
  "Journal Number",
  "Journal Publication Date",
  "Documents",
  "Correspondence Notices",
  "Oppositions",
  "Opposition Documents",
  "Opposition Correspondence Notices",
  "Tags",
  "Refresh Date",
];
