//--------LIBRARIES--------
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MikeContainer from "../../CommonComponents/Layout/MikeContainer";
import { Row, Col, Button, Pagination, Radio, Empty } from "antd";
import SendEmailNew from "../../../commonComponents/Modals/SendEmailNew";
import dayjs from "dayjs";
import { date_format } from "../../watchFilter/utils/utils";
import Tour from "reactour";
import ListFilter from "../../watchFilter/newCommonComponent/ListLayout/Filters";
import ListContent from "./Components/Content";
import GridContent from "./Components/ContentGrid";
import {
  addListSampleData,
  changeListDataView,
  getListLayoutData,
} from "../../../../redux/actions/watchActions/listDashboardActions";
import TrademarkJournalPage from "../../subComponents/Drawers/TrademarkJournalPage";
import {
  changeFilterAndOr,
  changeFilterApplied,
  changeListExcludeDropdownValues,
  changeListSelectedDropdownValues,
  changeSearchType,
  changeSearchValue,
  updateBooleanFilters,
  updateDateProposedValues,
  updateDateValues,
  updateNoTags,
  updateSimilarityConfig,
  updateimageTextPercentageConfig,
  updatethreatImageTextPercentageConfig,
} from "../../../../redux/actions/watchActions/watchActions";

const ListLayoutDashboard = () => {
  let urlKey = window.location.pathname.split("/")[2] || "";
  let dashboardLayout = window.location.pathname.split("/")[3] || "";
  const {
    tableData,
    status,
    count,
    pageNumber,
    journalNumber,
    bookmarkTemplate,
    listLayoutView,
    isTableDataPresent,
  } =
    useSelector(
      (state) =>
        state.watchState.listLayoutData?.[dashboardLayout]?.[urlKey].config,
    ) || "";
  const [journal, setJournal] = React.useState(
    journalNumber?.[`${urlKey}`] || "",
  );
  const [tmTypes, setTMTypes] = React.useState([]);
  const [threatTMTypes, setThreatTMTypes] = React.useState([]);
  const [tmCategories, setTMCategories] = React.useState([]);
  const [threatTMCategories, setThreatTMCategories] = React.useState([]);
  const [tmOffices, setTMOffices] = React.useState([]);
  const [threatTMOffices, setThreatTMOffices] = React.useState([]);
  const [infringement, setInfringement] = React.useState([]);
  const [searchType, setSearchType] = React.useState("Application No/Name");
  const [searchValue, setSearchValue] = React.useState("");
  const [excludeReported, setExcludeReported] = React.useState(false);
  const [shortlisted, setShortlisted] = React.useState(false);
  const [excludeShortlisted, setExcludeShortlisted] = React.useState(false);
  const [showDescription, setShowDescription] = React.useState(false);
  const [bookmark, setBookmark] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [filterApplied, setFilterApplied] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  // Advance Filters
  const [threatAttorneyValue, setThreatAttorneyValue] = React.useState([]);
  const [proprietors, setProprietors] = React.useState([]);
  const [threatProprietors, setThreatProprietors] = React.useState([]);
  const [excludeMarkProprietors, setExcludeMarkProprietors] = React.useState(
    [],
  );
  const [excludeThreatProprietors, setExcludeThreatProprietors] =
    React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [tmClass, setTMClass] = React.useState([]);
  const [threatTMClass, setThreatClass] = React.useState([]);
  const [similarity, setSimilarity] = React.useState({
    from: 0,
    to: 100,
  });
  const [yourDateOfApplication, setYourDateOfApplication] = React.useState({
    from: "",
    to: "",
    dateString: "",
  });
  const [threatDateOfApplication, setThreatDateOfApplication] = React.useState({
    from: "",
    to: "",
    dateString: "",
  });
  const [yourDateOfUsage, setYourDateOfUsage] = React.useState({
    from: "",
    to: "",
    dateString: "",
  });
  const [threatDateOfUsage, setThreatDateOfUsage] = React.useState({
    from: "",
    to: "",
    dateString: "",
  });
  const [yourDateOfUsageProposed, setYourDateOfUsageProposed] =
    React.useState(false);
  const [threatDateOfUsageProposed, setThreatDateOfUsageProposed] =
    React.useState(false);
  const [noTags, setNoTags] = React.useState(false);
  const [sameClassInfringement, setSameClassInfringement] =
    React.useState(false);
  const [excludeSameClassInfringement, setExcludeSameClassInfringement] =
    React.useState(false);
  const [publishedDate, setPublishedDate] = React.useState("");

  // AND - OR  states
  const [andOrTags, setAndOrTags] = React.useState(false);
  const [andOrClass, setAndOrClass] = React.useState(false);
  const [andOrThreatClass, setAndOrThreatClass] = React.useState(false);

  // Take Tour States
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [openTour3, setopenTour3] = useState(false);
  const [openTour4, setopenTour4] = useState(false);
  const [showSelectIcons, setshowSelectIcons] = useState(false);

  const [journalPageVisible, setJournalPageVisible] = useState(null);

  // For Image Similarity
  const [imageTextPercentage, setImageTextPercentage] = React.useState({
    from: 0,
    to: 100,
  });
  const [threatImageTextPercentage, setThreatImageTextPercentage] =
    React.useState({
      from: 0,
      to: 100,
    });

  // view states
  const [dataView, setDataView] = useState(
    urlKey === "journals" ? "card" : "table",
  );

  const dispatch = useDispatch();

  React.useState(() => {
    if (urlKey === "journals" && listLayoutView) {
      setDataView(listLayoutView);
    }
  }, []);

  const { journalConfig } =
    useSelector(
      (state) =>
        state.watchState.watchFilters?.[dashboardLayout]?.[urlKey].config,
    ) || {};

  const {
    markTypeConfig,
    threatTypeConfig,
    markCategoryConfig,
    threatCategoryConfig,
    markOfficeConfig,
    threatOfficeConfig,
    infringementConfig,
    markproprietorConfig,
    threatproprietorConfig,
    markClassConfig,
    threatClassConfig,
    tagsConfig,
    threatAttorneyConfig,
  } = useSelector(
    (state) => state.watchState.watchFilters[dashboardLayout][urlKey].config,
  );

  const { userDetails } = useSelector((state) => state?.userSubscriptions);

  const getRealSearchParameters = () => {
    const defaultSearchParameters = {
      contains_term: {
        value: null,
      },
      mark_desc_contains_term: {
        value: null,
      },
      threat_desc_contains_term: {
        value: null,
      },
    };

    switch (searchType) {
      case "Application No/Name":
        return Object.assign({}, defaultSearchParameters, {
          contains_term: {
            value: searchValue,
          },
        });
      case "Your Mark Description Search":
        return Object.assign({}, defaultSearchParameters, {
          mark_desc_contains_term: {
            value: searchValue,
          },
        });
      case "Your Threat Description Search":
        return Object.assign({}, defaultSearchParameters, {
          threat_desc_contains_term: {
            value: searchValue,
          },
        });
      case "Description Search":
        return Object.assign({}, defaultSearchParameters, {
          threat_desc_contains_term: {
            value: searchValue,
          },
        });
      default:
        return defaultSearchParameters;
    }
  };
  const generateFilterObject = () => {
    const filters = {
      tags: {
        value: tags,
        logic: andOrTags ? "AND" : "OR",
      },
      no_tags: {
        value: noTags,
      },
      mark_classes: {
        value: tmClass,
        logic: andOrClass ? "AND" : "OR",
      },
      threat_classes: {
        value: threatTMClass,
        logic: andOrThreatClass ? "AND" : "OR",
      },
      threat_sources: {
        value: infringement,
      },
      threat_similarity: {
        value: {
          to: similarity.to,
          from: similarity.from,
        },
      },
      mark_types: {
        value: tmTypes,
      },
      threat_types: {
        value: threatTMTypes,
      },
      mark_proprietors: {
        value: proprietors,
      },
      threat_proprietors: {
        value: threatProprietors,
      },
      mark_categories: {
        value: tmCategories,
      },
      threat_categories: {
        value: threatTMCategories,
      },
      mark_offices: {
        value: tmOffices,
      },
      threat_offices: {
        value: threatTMOffices,
      },
      mark_date_of_application: {
        value: {
          from: yourDateOfApplication.from
            ? dayjs(yourDateOfApplication.from).format(date_format)
            : "",
          to: yourDateOfApplication.to
            ? dayjs(yourDateOfApplication.to).format(date_format)
            : "",
        },
      },
      threat_date_of_application: {
        value: {
          from: threatDateOfApplication.from
            ? dayjs(threatDateOfApplication.from).format(date_format)
            : "",
          to: threatDateOfApplication.to
            ? dayjs(threatDateOfApplication.to).format(date_format)
            : "",
        },
      },
      mark_date_of_usage: {
        value: {
          from: yourDateOfUsage.from
            ? dayjs(yourDateOfUsage.from).format(date_format)
            : "",
          to: yourDateOfUsage.to
            ? dayjs(yourDateOfUsage.to).format(date_format)
            : "",
        },
      },
      threat_date_of_usage: {
        value: {
          from: threatDateOfUsage.from
            ? dayjs(threatDateOfUsage.from).format(date_format)
            : "",
          to: threatDateOfUsage.to
            ? dayjs(threatDateOfUsage.to).format(date_format)
            : "",
        },
      },
      mark_date_of_usage_proposed: {
        value: yourDateOfUsageProposed,
      },
      threat_date_of_usage_proposed: {
        value: threatDateOfUsageProposed,
      },
      red_band: {
        value: sameClassInfringement,
      },
      exclude_same_class: {
        value: excludeSameClassInfringement,
      },
      exclude_marks_wrt_custom_report: {
        value: excludeReported,
      },
      shortlisted: {
        value: shortlisted,
      },
      exclude_shortlisted: {
        value: excludeShortlisted,
      },
      exclude_mark_proprietors: {
        value: excludeMarkProprietors,
      },
      exclude_threat_proprietors: {
        value: excludeThreatProprietors,
      },
      threat_attorney: {
        value: threatAttorneyValue,
      },
      ...getRealSearchParameters(),
    };
    if (urlKey === "journals") {
      delete filters.threat_similarity;
    }

    if (urlKey === "image_similarity") {
      filters.image_text_percentage = {
        value: {
          to: imageTextPercentage.to,
          from: imageTextPercentage.from,
        },
      };
      filters.threat_image_text_percentage = {
        value: {
          to: threatImageTextPercentage.to,
          from: threatImageTextPercentage.from,
        },
      };
    }

    let brandFilters = {
      own_brand_classes: {
        value: tmClass,
        logic: andOrClass ? "AND" : "OR",
      },
      threat_brand_classes: {
        value: threatTMClass,
        logic: andOrThreatClass ? "AND" : "OR",
      },
      threat_similarity: {
        value: {
          to: similarity.to,
          from: similarity.from,
        },
      },
      own_brand_proprietors: {
        value: proprietors,
      },
      threat_brand_proprietors: {
        value: threatProprietors,
      },
      brand_date_of_application: {
        value: {
          from: yourDateOfApplication.from
            ? dayjs(yourDateOfApplication.from).format(date_format)
            : "",
          to: yourDateOfApplication.to
            ? dayjs(yourDateOfApplication.to).format(date_format)
            : "",
        },
      },
      threat_date_of_application: {
        value: {
          from: threatDateOfApplication.from
            ? dayjs(threatDateOfApplication.from).format(date_format)
            : "",
          to: threatDateOfApplication.to
            ? dayjs(threatDateOfApplication.to).format(date_format)
            : "",
        },
      },
      brand_date_of_usage: {
        value: {
          from: yourDateOfUsage.from
            ? dayjs(yourDateOfUsage.from).format(date_format)
            : "",
          to: yourDateOfUsage.to
            ? dayjs(yourDateOfUsage.to).format(date_format)
            : "",
        },
      },
      threat_date_of_usage: {
        value: {
          from: threatDateOfUsage.from
            ? dayjs(threatDateOfUsage.from).format(date_format)
            : "",
          to: threatDateOfUsage.to
            ? dayjs(threatDateOfUsage.to).format(date_format)
            : "",
        },
      },
      brand_date_of_usage_proposed: {
        value: yourDateOfUsageProposed,
      },
      threat_date_of_usage_proposed: {
        value: threatDateOfUsageProposed,
      },
      // red_band: {
      //   value: sameClassInfringement,
      // },
      // exclude_same_class: {
      //   value: excludeSameClassInfringement,
      // },
      exclude_brands_wrt_custom_report: {
        value: excludeReported,
      },
      shortlisted: {
        value: shortlisted,
      },
      exclude_shortlisted: {
        value: excludeShortlisted,
      },
      exclude_brand_proprietors: {
        value: excludeMarkProprietors,
      },
      exclude_threat_proprietors: {
        value: excludeThreatProprietors,
      },
      ...getRealSearchParameters(),
    };
    return urlKey === "brand_compare" ? brandFilters : filters;
  };

  React.useEffect(() => {
    if (journalNumber?.[urlKey] === null) {
      setJournal(journalConfig?.data[0]?.value ?? "");
    }
  }, [journalConfig, journalNumber]);

  const updateReduxStore = () => {
    // code to update redux store with the help of states
    dispatch(changeSearchType(searchType));
    dispatch(changeSearchValue(searchValue));
    dispatch(updateNoTags(noTags));
    dispatch(updateBooleanFilters("excludeReportedConfig", excludeReported));
    dispatch(updateBooleanFilters("shortlistedConfig", shortlisted));
    dispatch(
      updateBooleanFilters("excludeShortlistedConfig", excludeShortlisted),
    );
    dispatch(
      updateBooleanFilters(
        "sameClassInfringementConfig",
        sameClassInfringement,
      ),
    );
    dispatch(
      updateBooleanFilters(
        "excludeSameClassInfringementConfig",
        excludeSameClassInfringement,
      ),
    );
    dispatch(changeFilterAndOr("tags", andOrTags));
    dispatch(changeFilterAndOr("mark_classes", andOrClass));
    dispatch(changeFilterAndOr("threat_classes", andOrThreatClass));
    dispatch(updateSimilarityConfig(similarity));
    dispatch(
      updateDateValues("your_date_of_application", yourDateOfApplication),
    );
    dispatch(
      updateDateValues("threat_date_of_application", threatDateOfApplication),
    );
    dispatch(updateDateValues("your_date_of_usage", yourDateOfUsage));
    dispatch(updateDateValues("threat_date_of_usage", threatDateOfUsage));
    dispatch(
      updateDateProposedValues("your_date_of_usage", yourDateOfUsageProposed),
    );
    dispatch(
      updateDateProposedValues(
        "threat_date_of_usage",
        threatDateOfUsageProposed,
      ),
    );

    if (urlKey === "image_similarity") {
      dispatch(updateimageTextPercentageConfig(imageTextPercentage));
      dispatch(
        updatethreatImageTextPercentageConfig(threatImageTextPercentage),
      );
    }

    // For brand compare start
    if (urlKey === "brand_compare") {
      dispatch(changeFilterAndOr("own_brand_classes", andOrClass));
      dispatch(changeFilterAndOr("threat_brand_classes", andOrThreatClass));
    }
    // For brand compare ends

    const getArrayOfObjectWithValues = (
      dataArray,
      values,
      functionName = "Number",
    ) => {
      const existsInDataArray = (val) =>
        functionName === "String"
          ? values.includes(val.toString())
          : values.includes(val);
      let result = dataArray.filter((obj) => existsInDataArray(obj.value));
      return result;
    };
    dispatch(
      changeListSelectedDropdownValues(
        "threat_attorney",
        getArrayOfObjectWithValues(
          [
            ...threatAttorneyConfig.data,
            ...threatAttorneyConfig.selectedValues,
          ],
          threatAttorneyValue,
        ),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "mark_proprietors",
        getArrayOfObjectWithValues(
          [
            ...markproprietorConfig.data,
            ...markproprietorConfig.selectedValues,
          ],
          proprietors,
        ),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "threat_proprietors",
        getArrayOfObjectWithValues(
          [
            ...threatproprietorConfig.data,
            ...threatproprietorConfig.selectedValues,
          ],
          threatProprietors,
        ),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "tags",
        getArrayOfObjectWithValues(
          [...tagsConfig.data, ...tagsConfig.selectedValues],
          tags,
        ),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "mark_classes",
        getArrayOfObjectWithValues(markClassConfig.data, tmClass),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "threat_classes",
        getArrayOfObjectWithValues(threatClassConfig.data, threatTMClass),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "mark_types",
        getArrayOfObjectWithValues(markTypeConfig.data, tmTypes, "String"),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "threat_types",
        getArrayOfObjectWithValues(
          threatTypeConfig.data,
          threatTMTypes,
          "String",
        ),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "mark_categories",
        getArrayOfObjectWithValues(
          markCategoryConfig.data,
          tmCategories,
          "String",
        ),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "threat_categories",
        getArrayOfObjectWithValues(
          threatCategoryConfig.data,
          threatTMCategories,
          "String",
        ),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "mark_offices",
        getArrayOfObjectWithValues(markOfficeConfig.data, tmOffices, "String"),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "threat_offices",
        getArrayOfObjectWithValues(
          threatOfficeConfig.data,
          threatTMOffices,
          "String",
        ),
      ),
    );
    dispatch(
      changeListSelectedDropdownValues(
        "threat_sources",
        getArrayOfObjectWithValues(
          infringementConfig.data,
          infringement,
          "String",
        ),
      ),
    );

    dispatch(
      changeListExcludeDropdownValues(
        "mark_proprietors",
        getArrayOfObjectWithValues(
          [...markproprietorConfig.data, ...markproprietorConfig.excludeValues],
          excludeMarkProprietors,
        ),
      ),
    );
    dispatch(
      changeListExcludeDropdownValues(
        "threat_proprietors",
        getArrayOfObjectWithValues(
          [
            ...threatproprietorConfig.data,
            ...threatproprietorConfig.excludeValues,
          ],
          excludeThreatProprietors,
        ),
      ),
    );
    // For brand compare starts
    if (urlKey === "brand_compare") {
      dispatch(
        changeListSelectedDropdownValues(
          "own_brand_proprietors",
          getArrayOfObjectWithValues(
            [
              ...markproprietorConfig.data,
              ...markproprietorConfig.selectedValues,
            ],
            proprietors,
          ),
        ),
      );
      dispatch(
        changeListSelectedDropdownValues(
          "threat_brand_proprietors",
          getArrayOfObjectWithValues(
            [
              ...threatproprietorConfig.data,
              ...threatproprietorConfig.selectedValues,
            ],
            threatProprietors,
          ),
        ),
      );
      dispatch(
        changeListExcludeDropdownValues(
          "own_brand_proprietors",
          getArrayOfObjectWithValues(
            [
              ...markproprietorConfig.data,
              ...markproprietorConfig.excludeValues,
            ],
            excludeMarkProprietors,
          ),
        ),
      );
      dispatch(
        changeListExcludeDropdownValues(
          "threat_brand_proprietors",
          getArrayOfObjectWithValues(
            [
              ...threatproprietorConfig.data,
              ...threatproprietorConfig.excludeValues,
            ],
            excludeThreatProprietors,
          ),
        ),
      );
    }
    // For brand compare ends
  };

  const isAnyFilterSelected = (filterObject) => {
    const {
      contains_term,
      exclude_mark_proprietors,
      exclude_marks_wrt_custom_report,
      exclude_same_class,
      exclude_shortlisted,
      exclude_threat_proprietors,
      mark_categories,
      mark_classes,
      mark_date_of_application,
      mark_date_of_usage,
      threat_date_of_application,
      threat_date_of_usage,
      mark_date_of_usage_proposed,
      mark_desc_contains_term,
      mark_offices,
      mark_proprietors,
      mark_types,
      no_tags,
      red_band,
      shortlisted,
      tags,
      threat_attorney,
      threat_categories,
      threat_classes,
      threat_date_of_usage_proposed,
      threat_desc_contains_term,
      threat_offices,
      threat_proprietors,
      threat_similarity,
      threat_sources,
      threat_types,
      brand_date_of_application,
      brand_date_of_usage,
      brand_date_of_usage_proposed,
      exclude_brand_proprietors,
      exclude_brands_wrt_custom_report,
      own_brand_classes,
      own_brand_proprietors,
      threat_brand_proprietors,
      threat_brand_classes,
      image_text_percentage,
      threat_image_text_percentage,
    } = filterObject;

    let anyFilterSelected =
      contains_term?.value ||
      exclude_mark_proprietors?.value.length ||
      exclude_marks_wrt_custom_report?.value ||
      exclude_same_class?.value ||
      exclude_shortlisted?.value ||
      exclude_threat_proprietors?.value.length ||
      mark_categories?.value.length ||
      mark_classes?.value.length ||
      mark_date_of_application?.value.from ||
      mark_date_of_application?.value.to ||
      mark_date_of_usage?.value.from ||
      mark_date_of_usage?.value.to ||
      threat_date_of_application?.value.from ||
      threat_date_of_application?.value.to ||
      threat_date_of_usage?.value.from ||
      threat_date_of_usage?.value.to ||
      mark_date_of_usage_proposed?.value ||
      mark_desc_contains_term?.value?.length ||
      mark_offices?.value.length ||
      mark_proprietors?.value?.length ||
      mark_types?.value.length ||
      no_tags?.value ||
      red_band?.value ||
      shortlisted?.value ||
      tags?.value.length ||
      threat_attorney?.value.length ||
      threat_categories?.value.length ||
      threat_classes?.value.length ||
      threat_date_of_usage_proposed?.value ||
      threat_desc_contains_term?.value ||
      threat_offices?.value.length ||
      threat_proprietors?.value.length ||
      threat_similarity?.value.from > 0 ||
      threat_similarity?.value.to < 100 ||
      threat_sources?.value.length ||
      threat_types?.value.length ||
      brand_date_of_application?.value.from ||
      brand_date_of_application?.value.to ||
      brand_date_of_usage?.value.from ||
      brand_date_of_usage?.value.to ||
      brand_date_of_usage_proposed?.value ||
      exclude_brand_proprietors?.value.length ||
      exclude_brands_wrt_custom_report?.value ||
      own_brand_classes?.value.length ||
      own_brand_proprietors?.value?.length ||
      threat_brand_proprietors?.value?.length ||
      threat_brand_classes?.value.length ||
      image_text_percentage?.value.from > 0 ||
      image_text_percentage?.value.to < 100 ||
      threat_image_text_percentage?.value.from > 0 ||
      threat_image_text_percentage?.value.to < 100;
    return Boolean(anyFilterSelected);
  };

  const propsForFilters = {
    selectedRowKeys,
    setSelectedRowKeys,
    selectedRows,
    setSelectedRows,
    isTourOpen,
    setIsTourOpen,
    openTour4,
    openTour3,
    showSelectIcons,
    tmTypes,
    setTMTypes,
    threatTMTypes,
    setThreatTMTypes,
    tmCategories,
    setTMCategories,
    threatTMCategories,
    setThreatTMCategories,
    tmOffices,
    setTMOffices,
    threatTMOffices,
    setThreatTMOffices,
    infringement,
    setInfringement,
    journal,
    setJournal,
    searchType,
    setSearchType,
    searchValue,
    setSearchValue,
    excludeReported,
    setExcludeReported,
    shortlisted,
    setShortlisted,
    showDescription,
    setShowDescription,
    bookmark,
    setBookmark,
    selected,
    setSelected,
    threatAttorneyValue,
    setThreatAttorneyValue,
    proprietors,
    setProprietors,
    threatProprietors,
    setThreatProprietors,
    tags,
    setTags,
    tmClass,
    setTMClass,
    threatTMClass,
    setThreatClass,
    similarity,
    setSimilarity,
    yourDateOfApplication,
    setYourDateOfApplication,
    threatDateOfApplication,
    setThreatDateOfApplication,
    yourDateOfUsage,
    setYourDateOfUsage,
    threatDateOfUsage,
    setThreatDateOfUsage,
    yourDateOfUsageProposed,
    setYourDateOfUsageProposed,
    threatDateOfUsageProposed,
    setThreatDateOfUsageProposed,
    noTags,
    setNoTags,
    excludeMarkProprietors,
    setExcludeMarkProprietors,
    excludeThreatProprietors,
    setExcludeThreatProprietors,
    sameClassInfringement,
    setSameClassInfringement,
    excludeSameClassInfringement,
    setExcludeSameClassInfringement,
    publishedDate,
    setPublishedDate,
    excludeShortlisted,
    setExcludeShortlisted,
    selectAll,
    setSelectAll,
    filterApplied,
    setFilterApplied,
    andOrTags,
    setAndOrTags,
    andOrClass,
    setAndOrClass,
    andOrThreatClass,
    setAndOrThreatClass,
    generateFilterObject,
    isAnyFilterSelected,
    dataView,
    setDataView,
    updateReduxStore,
    imageTextPercentage,
    setImageTextPercentage,
    threatImageTextPercentage,
    setThreatImageTextPercentage,
  };

  const Filters = (
    <React.Fragment>
      <Row>
        <Col span={24}>
          {/* {urlKey === "tm_protect"?<>Filters</>:<ListFilter {...propsForFilters} />} */}
          <ListFilter {...propsForFilters} />
        </Col>
      </Row>
    </React.Fragment>
  );

  const [mailObj, setMailObj] = React.useState({ visible: false });

  const propModals = {
    setMailObj,
  };

  const useMemoizedComponent = (Component, deps) => {
    return React.useMemo(() => Component, deps);
  };

  const Modals = useMemoizedComponent(
    <SendEmailNew
      visible={mailObj?.visible}
      onCloseModal={() => setMailObj({ visible: false })}
      product={mailObj?.product}
      subscription={mailObj?.subscription}
      mailContent={mailObj?.mailContent}
      report_tag={mailObj?.report_tag}
      journal_copy={urlKey === "tm_protect" ? "" : mailObj?.journal_copy}
      objectType={mailObj?.objectType}
      applicationNumber={mailObj?.applicationNumber}
      sendFrom={userDetails && userDetails?.communication_email}
      mark_type={mailObj?.mark_type}
      sub_section="DASHBOARD"
    />,
    [mailObj],
  );

  let filterSelected = isAnyFilterSelected(generateFilterObject());

  const scrollToTopOfTable = () => {
    const tableElements = document.getElementsByClassName(
      "ant-table-row ant-table-row-level-0",
    );
    const cardElements = document.getElementsByClassName("ThreatCard");
    if (tableElements.length > 0) {
      const tableElement = tableElements[0];
      tableElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    if (cardElements.length > 0) {
      const cardElement = cardElements[0];
      cardElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleNextpage = (pageNumber) => {
    const filters = generateFilterObject();
    dispatch(getListLayoutData(journal, pageNumber, filterSelected, filters));
    // Scroll to top of the table in case of jounral dashboard
    urlKey === "journals" && scrollToTopOfTable();
  };

  const onSelectChange = (record, selected) => {
    if (selected) {
      let newSelected = [...selectedRows, record];
      setSelected(newSelected.map((x) => x.id));
      setSelectedRowKeys(newSelected.map((x) => x.id));
      setSelectedRows(newSelected);
    } else {
      let newSelected = selectedRows.filter((x) => x.id !== record.id);

      setSelected(newSelected.map((x) => x.id));
      setSelectedRowKeys(newSelected.map((x) => x.id));
      setSelectedRows(newSelected);
    }
  };

  const Content =
    dataView === "table" ? (
      <>
        <ListContent
          rowExpanded={showDescription}
          {...propsForFilters}
          {...propModals}
        />
        {urlKey === "journals" && (
          <footer className="General-Footer Box-Shadow">
            <Radio.Group
              id="list-card-table-view-switch"
              value={dataView}
              onChange={(e) => {
                setDataView(e.target.value);
                dispatch(changeListDataView(e.target.value));
              }}
              style={{ float: "left", marginTop: "4px" }}
            >
              <Radio.Button value="card">Card</Radio.Button>
              <Radio.Button value="table">Table</Radio.Button>
            </Radio.Group>
            <Pagination
              defaultCurrent={1}
              current={pageNumber}
              total={count}
              style={{ padding: "4px" }}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} `
              }
              showQuickJumper
              pageSize={100}
              showSizeChanger={false}
              onChange={(pageNumber) => handleNextpage(pageNumber)}
            />
          </footer>
        )}
      </>
    ) : (
      <>
        {Object.values(tableData || []).length ? (
          <div
            className={"General-Content  Flex-Content  vertical_scroll"}
            style={{
              height: "calc(100% - 210px)",
            }}
          >
            {Object.values(tableData || []).map((obj, index) => (
              <GridContent
                key={`${obj.id}_${index}`}
                onSelectMark={onSelectChange}
                eachMark={obj}
                selectAllCards={selectAll}
                checked={selected?.includes(obj?.id) || selectAll}
                setJournalPageVisible={setJournalPageVisible}
                {...propsForFilters}
                {...propModals}
              />
            ))}
          </div>
        ) : (
          <div
            className={"General-Content Flex-Content vertical_scroll"}
            style={{
              height: "calc(100% - 210px)",
            }}
          >
            <Empty
              style={{
                display: "table-cell",
                verticalAlign: "middle",
                margin: "auto",
              }}
            />
          </div>
        )}
        <footer className="General-Footer Box-Shadow">
          <Radio.Group
            id="list-card-table-view-switch"
            value={dataView}
            onChange={(e) => {
              setDataView(e.target.value);
              dispatch(changeListDataView(e.target.value));
            }}
            style={{ float: "left", marginTop: "4px" }}
          >
            <Radio.Button value="card">Card</Radio.Button>
            <Radio.Button value="table">Table</Radio.Button>
          </Radio.Group>
          <Pagination
            defaultCurrent={1}
            current={pageNumber}
            total={count}
            style={{ padding: "4px" }}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} `}
            pageSize={100}
            showQuickJumper
            showSizeChanger={false}
            onChange={(pageNumber) => handleNextpage(pageNumber)}
          />
        </footer>
        {journalPageVisible !== null && (
          <TrademarkJournalPage
            journalNumber={journalNumber[urlKey]}
            applicationNumber={journalPageVisible}
            closeDrawer={() => setJournalPageVisible(null)}
          />
        )}
      </>
    );

  const steps = [
    {
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Welcome to MikeTM Watch's '
            {urlKey === "image_text" || urlKey === "image_text_new"
              ? "Image Text"
              : urlKey === "tm_protect"
                ? "TM Protect"
                : urlKey === "journals"
                  ? "Journals"
                  : urlKey === "image_similarity"
                    ? "Image Similarity"
                    : "TM Applied For"}
            ' Analysis!
          </div>
          <br />
          <p style={{ color: "white" }}>
            Here, you will be able to see all similar marks published against
            your portfolio marks. On top you will see identical marks and as you
            scroll down relevancy will decrease.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
    },
    {
      selector:
        urlKey === "tm_protect" || urlKey === "journals"
          ? ".tm-protect-table .ant-table-thead"
          : ".tm-applied-table .ant-table-thead",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Filters in the headings
          </div>
          <br />
          <p style={{ color: "white" }}>
            Now for the data available in table you can filter it by applying it
            here at the heading itself.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
      action: () => {
        setopenTour3("1");
      },
    },
    {
      selector: "#tm_applied_ll_take_tour_step3",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Ability to Shortlist marks
          </div>
          <br />
          <p style={{ color: "white" }}>
            Using this filter you can shortlist the selected marks or remove
            them from the shortlisted category. This is helpful for peer review
            or while giving a second glance to decide whether to oppose the said
            mark or not.{" "}
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
      action: () => {
        setopenTour3("2");
      },
    },
    {
      selector: "#update_shortlist_menu",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Update shortlist
          </div>
          <br />
          <p style={{ color: "white" }}>
            Use this icon to add or remove shortlisted potential infringements.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
    },
    {
      selector: ".tmapplied_selection_cols",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Select marks and create report
          </div>
          <br />
          <p style={{ color: "white" }}>
            Select on the check box to create report or shortlist them to be
            reviewed at a later stage. Once a mark is shortlisted 'Star' symbol
            is shown and if report is created then 'eye' symbol will be present.
            You can filter shortlisted marks or report created marks through the
            filters.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
      action: () => {
        setopenTour3(false);
        setopenTour4(true);
        setshowSelectIcons(true);
      },
    },
    {
      selector: ".tmapplied_ll_filter_template",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Create Filter Templates
          </div>
          <br />
          <p style={{ color: "white" }}>
            Tired of applying same filter every week? Now you can save all those
            filters in a template so that going forward with a single click the
            saved filters can be applied.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
      action: () => {
        setshowSelectIcons(false);
      },
    },
    {
      selector: ".tmapplied_ll_actions",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            {urlKey === "journals" ? "Apply Bookmark" : "Send Mails"}
          </div>
          <br />
          <p style={{ color: "white" }}>
            {urlKey === "journals"
              ? "In case you have to leave the analysis mid-way then you can use this 'Bookmark' icon to save where you are at and next time by clicking on the 'Bookmark' icon you can resume from where you had left."
              : "Use 'Send Email' icon to send emails from the dashboard to your colleague or client to consider the mark for opposing. In case you have to leave the analysis mid-way then you can use this 'Bookmark' icon to save where you are at and next time by clicking on the 'Bookmark' icon you can resume from where you had left."}
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
      action: () => {
        setopenTour4(false);
      },
    },
  ];
  if (urlKey !== "tm_protect") {
    steps.push(
      ...[
        {
          selector: ".tmapplied_ll_journal_dropdown",
          content: (
            <div>
              <div
                style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}
              >
                Access previous week journal
              </div>
              <br />
              <p style={{ color: "white" }}>
                By Default, MikeTM Watch will show you the most recent journal.
                In case you wish to go back to previously generated analysis
                then you can click on this dropdown and select the relevant
                journal number to load that analysis.
              </p>
            </div>
          ),
          style: {
            backgroundColor: "#1F3EAA",
            borderRadius: "4px",
          },
          action: () => {
            setopenTour4(false);
          },
        },
        {
          selector: "#list_filter_combine_pdf_btn",
          content: (
            <div>
              <div
                style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}
              >
                Combine PDF
              </div>
              <br />
              <p style={{ color: "white" }}>
                Use this icon to download journal copy of all the selected
                potential threats in PDF.
              </p>
            </div>
          ),
          style: {
            backgroundColor: "#1F3EAA",
            borderRadius: "4px",
          },
        },
      ],
    );
    if (urlKey === "journals") {
      steps.push({
        selector: "#list-card-table-view-switch",
        content: (
          <div>
            <div
              style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}
            >
              Multiple Views
            </div>
            <br />
            <p style={{ color: "white" }}>
              We have provided Card View and Table View to enable ease of use
              and access of more data points as per your convenience.
            </p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },
        action: () => {
          setopenTour4(false);
        },
      });
    }
  } else if (urlKey === "tm_protect") {
    steps.push({
      selector: ".tmapplied_ll_journal_dropdown",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Access previous week analysis
          </div>
          <br />
          <p style={{ color: "white" }}>
            By Default, MikeTM Protect will show you applications filed in the
            past 7 days. In case you wish to go back to previously generated
            analysis then you can click on this dropdown and select the relevant
            week/date-range to load that analysis.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
      action: () => {
        setopenTour4(false);
      },
    });
  }

  const brandCompareSteps = [
    {
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Welcome to Brand Compare Analysis
          </div>
          <br />
          <p style={{ color: "white" }}>
            Here you can Identify similar brand against your portfolio brand.
            This analysis is arranged in the manner where identical brand are
            shown first and as you scroll down relevancy decreases.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
    },
    {
      selector: ".brand-compare-table .ant-table-thead",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Filters in the headings
          </div>
          <br />
          <p style={{ color: "white" }}>
            Now for the data available in table you can filter it by applying it
            here at the heading itself.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
      action: () => {
        setopenTour3("1");
      },
    },
    {
      selector: "#tm_applied_ll_take_tour_step3",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Ability to Shortlist brands.
          </div>
          <br />
          <p style={{ color: "white" }}>
            Using this filter you can shortlist the selected brands or remove
            them from the shortlisted category. This is helpful for peer review
            or while giving a second glance to decide whether to oppose the said
            brand or not.{" "}
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
      action: () => {
        setopenTour3("2");
      },
    },
    {
      selector: "#update_shortlist_menu",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Update shortlist
          </div>
          <br />
          <p style={{ color: "white" }}>
            Use this icon to add or remove shortlisted potential infringements.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
    },
    {
      selector: ".tmapplied_selection_cols",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Select brands and create report
          </div>
          <br />
          <p style={{ color: "white" }}>
            Select on the check box to create report or shortlist them to be
            reviewed at a later stage. Once a brand is shortlisted 'Star' symbol
            is shown and if report is created then 'eye' symbol will be present.
            You can filter shortlisted brands or report created brands through
            the filters.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
      action: () => {
        setopenTour3(false);
        // setopenTour4(true);
        // setshowSelectIcons(true);
      },
    },
    {
      selector: ".tmapplied_ll_actions",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Apply Bookmark
          </div>
          <br />
          <p style={{ color: "white" }}>
            In case you have to leave the analysis mid-way then you can use this
            'Bookmark' icon to save where you are at and next time by clicking
            on the 'Bookmark' icon you can resume from where you had left.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
      action: () => {
        setopenTour4(false);
      },
    },
    {
      selector: ".tmapplied_ll_journal_dropdown",
      content: (
        <div>
          <div style={{ color: "white", fontWeight: "bold", fontSize: "18px" }}>
            Access previous analysis.
          </div>
          <br />
          <p style={{ color: "white" }}>
            By default Brand Compare will show the most recent analysis and then
            you can switch to a previously done analysis by clicking on the drop
            down.
          </p>
        </div>
      ),
      style: {
        backgroundColor: "#1F3EAA",
        borderRadius: "4px",
      },
      action: () => {
        setopenTour4(false);
      },
    },
  ];
  return (
    <>
      <MikeContainer header={Filters} content={Content} modals={Modals} />
      <Tour
        startAt={0}
        steps={urlKey === "brand_compare" ? brandCompareSteps : steps}
        isOpen={isTourOpen}
        onRequestClose={() => {
          setIsTourOpen(false);
          setopenTour3(false);
          setopenTour4(false);
          setshowSelectIcons(false);
          if (!isTableDataPresent) {
            dispatch(addListSampleData());
          }
        }}
        nextButton={<Button>Next</Button>}
        prevButton={
          <Button style={{ color: "white", display: "none" }} type="link">
            Back
          </Button>
        }
        disableDotsNavigation
        showNavigation={false}
        lastStepNextButton={<Button>Done</Button>}
        closeWithMask={false}
        badgeContent={(current, total) => `${current} of ${total}`}
        disableInteraction={true}
      />
    </>
  );
};

export default ListLayoutDashboard;
