//--------LIBRARIES--------
import React from "react";
import Axios from "axios";
import { notification, message } from "antd";
import { batch } from "react-redux";

//--------RT_CONFIG--------
import { WAT_RT_URL } from "../../../configs/rootConfigs";

//--------IP-SUITE CONSTANTS--------
import {
  LOADING,
  ERROR,
  SUCCESS,
  PROGRESSING,
} from "../../../constants/commonConstants/generalConstants";

//--------WATCH GENERAL CONSTANTS--------
import {
  GRID_LAYOUT,
  LIST_LAYOUT,
  JOURNALS,
} from "../../../constants/watchConstants/generalConstants";

//--------WATCH TYPES--------
import {
  GET_JOURNALS_LIST,
  SET_CURRENT_JOURNAL,
  SET_CURRENT_JOURNAL_DATE,
  GET_DATE_RANGE_LIST,
  SET_CURRENT_DATE_RANGE,
  GL_POST_IMAGE_TEXT_CUSTOM_REPORT,
  GL_POST_IMAGE_LABEL_CUSTOM_REPORT,
  LL_POST_IMAGE_CUSTOM_REPORT,
  GL_POST_TM_PROTECT_CUSTOM_REPORT,
  LL_POST_TM_PROTECT_CUSTOM_REPORT,
} from "./types";

//--------GRID LAYOUT TYPES--------
import { GL_GET_USER_TRADEMARKS } from "./types";

//--------JOURNALS TYPES--------
import { J_GET_USER_TRADEMARKS } from "./types";

//--------IP-SUITE ACTIONS--------
import { checkAuthorisation } from "../commonActions/actions";

//--------Get Journals List--------
export function getJournalsList(setCurrentJournalFlag, filters) {
  return async (dispatch) => {
    dispatch({ type: GET_JOURNALS_LIST, status: LOADING });
    let response;
    try {
      response = await Axios.get(`${WAT_RT_URL}/journals/`, {
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });

      switch (response.status) {
        case 200:
          dispatch({
            type: GET_JOURNALS_LIST,
            status: SUCCESS,
            payload: response?.data,
            lastAppliedFilters: {
              ...filters,
            },
          });
          if (setCurrentJournalFlag === true) {
            dispatch({
              type: SET_CURRENT_JOURNAL,
              payload: response?.data.data[0].journal_number,
            });
            dispatch({
              type: SET_CURRENT_JOURNAL_DATE,
              payload: response?.data.data[0].published_date,
            });
          }
          break;

        default:
          notification["error"]({
            message: "Unexpected Response: Journals List",
            description: "Server gave an inappropriate response.",
          });
          dispatch({ type: GET_JOURNALS_LIST, status: ERROR });
          break;
      }
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: GET_JOURNALS_LIST, status: ERROR });
    }
  };
}

//--------Get User's Trademarks--------
export function getUserTrademarks(
  { journalNumber, pageNumber, latestJournalReport, searchTerm, filters },
  journalChange = false
) {
  const search_term = `${filters?.searchTerm}`;
  const mark_desc_contains_term = `${filters?.mark_desc_contains_term}`;
  const search_type = `${filters?.primarySearchType}`;

  return async (dispatch) => {
    let layout_url = `${WAT_RT_URL}/grid_layout/?`;
    let response;
    let params = {
      page: pageNumber ? pageNumber : null,
      filter_flag:
        searchTerm?.length > 0 ||
        search_term?.length > 0 ||
        mark_desc_contains_term?.length > 0
          ? true
          : null,
      latest_journal_report: latestJournalReport ? latestJournalReport : null,
      search_term: searchTerm
        ? searchTerm
        : search_type === "Application No/Name"
        ? search_term
        : null,
      journal_number: journalNumber ? journalNumber : null,
      mark_desc_contains_term:
        search_type === "Your Mark Description Search"
          ? mark_desc_contains_term
          : null,
    };

    for (let key in params) {
      if (params[key] !== null) {
        layout_url = layout_url.concat(`${key}` + "=" + `${params[key]}` + "&");
      }
    }

    if (layout_url.length <= 2048) {
      dispatch({
        type: latestJournalReport
          ? J_GET_USER_TRADEMARKS
          : GL_GET_USER_TRADEMARKS,
        status: LOADING,
        journalChange,
      });
      try {
        response = await Axios.get(`${WAT_RT_URL}/grid_layout/`, {
          params,
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        });

        switch (response.status) {
          case 200:
            if (journalChange) {
              batch(() => {
                dispatch({
                  type: latestJournalReport
                    ? J_GET_USER_TRADEMARKS
                    : GL_GET_USER_TRADEMARKS,
                  status: SUCCESS,
                  payload: response?.data,
                  searchTerm,
                  search_term,
                  mark_desc_contains_term,
                  lastAppliedFilters: {
                    ...filters,
                  },
                  pageNumber,
                  journalNumber,
                  journalChange,
                });
                dispatch({ type: SET_CURRENT_JOURNAL, payload: journalNumber });
              });
            } else {
              dispatch({
                type: latestJournalReport
                  ? J_GET_USER_TRADEMARKS
                  : GL_GET_USER_TRADEMARKS,
                status: SUCCESS,
                payload: response?.data,
                searchTerm,
                search_term,
                mark_desc_contains_term,
                lastAppliedFilters: {
                  ...filters,
                },
                pageNumber,
                journalNumber,
                journalChange,
              });
            }
            break;

          case 202:
            if (journalChange) {
              batch(() => {
                dispatch({
                  type: latestJournalReport
                    ? J_GET_USER_TRADEMARKS
                    : GL_GET_USER_TRADEMARKS,
                  status: PROGRESSING,
                  payload: response?.data,
                  searchTerm,
                  search_term,
                  mark_desc_contains_term,
                  lastAppliedFilters: {
                    ...filters,
                  },
                  pageNumber,
                  journalNumber,
                  journalChange,
                });
                dispatch({ type: SET_CURRENT_JOURNAL, payload: journalNumber });
              });
            } else {
              dispatch({
                type: latestJournalReport
                  ? J_GET_USER_TRADEMARKS
                  : GL_GET_USER_TRADEMARKS,
                status: PROGRESSING,
                payload: response?.data,
                searchTerm,
                search_term,
                mark_desc_contains_term,
                lastAppliedFilters: {
                  ...filters,
                },
                pageNumber,
                journalNumber,
                journalChange,
              });
            }
            break;

          default:
            dispatch({
              type: latestJournalReport
                ? J_GET_USER_TRADEMARKS
                : GL_GET_USER_TRADEMARKS,
              status: ERROR,
              payload:
                "An error occured while receiving mark. Please try again after some time.",
              journalChange,
            });
            break;
        }
      } catch (err) {
        checkAuthorisation(dispatch, err);
        dispatch({
          type: latestJournalReport
            ? J_GET_USER_TRADEMARKS
            : GL_GET_USER_TRADEMARKS,
          status: ERROR,
          payload: err.response?.data.message,
          journalChange,
        });
      }
    } else {
      message.error(
        "Your Searched query has exceeded the characters. You can search upto 1000 characters here"
      );
    }
  };
}

//--------Post custom report for Watch--------
export function postCustomReport(customReportData, actionType) {
  const searchType = `${customReportData.filters.searchType}`;

  return async (dispatch) => {
    dispatch({ type: actionType, status: LOADING });
    let response;
    try {
      switch (customReportData.source) {
        case GRID_LAYOUT:
          response = await Axios.post(
            `${WAT_RT_URL}/report/`,
            {
              ...customReportData,
              report_name: customReportData.report_name.trim(),
            },
            {
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            }
          );
          break;

        case LIST_LAYOUT:
          response = await Axios.post(
            `${WAT_RT_URL}/report/`,
            {
              select_all: customReportData.selectAll,
              filters: {
                tags: customReportData.filters.tags,
                mark_classes: customReportData.filters.markClasses,
                threat_classes: customReportData.filters.threatClasses,
                threat_sources: customReportData.filters.threatSources,
                contains_term:
                  searchType === "Application No/Name"
                    ? customReportData.filters.searchTerm
                    : "",
                mark_desc_contains_term:
                  searchType === "Your Mark Description Search"
                    ? customReportData.filters.mark_desc_contains_term
                    : "",
                threat_desc_contains_term:
                  searchType === "Your Threat Description Search"
                    ? customReportData.filters.threat_desc_contains_term
                    : "",
                threat_similarity: `${customReportData.filters.threatsSimilarity.lowerLimit}to${customReportData.filters.threatsSimilarity.upperLimit}`,
                mark_types: customReportData.filters.markTypes,
                threat_types: customReportData.filters.threatTypes,
                mark_proprietors: customReportData.filters.markProprietors,
                mark_categories: customReportData.filters.markCategories,
                threat_categories: customReportData.filters.threatCategories,
                mark_offices: customReportData.filters.markOffices,
                threat_offices: customReportData.filters.threatOffices,
                mark_date_of_application:
                  customReportData.filters.mark_date_of_application,
                threat_date_of_application:
                  customReportData.filters.threat_date_of_application,
                mark_date_of_usage: customReportData.filters.mark_date_of_usage,
                mark_date_of_usage_proposed:
                  customReportData.filters.mark_date_of_usage_proposed,
                threat_date_of_usage:
                  customReportData.filters.threat_date_of_usage,
                threat_date_of_usage_proposed:
                  customReportData.filters.threat_date_of_usage_proposed,
                red_band: customReportData.filters.redBand,
                exclude_same_class: customReportData.filters.excludeClass,
              },
              journal_number: customReportData.journalNumber,
              filter_flag: customReportData.filter_flag,
              data: customReportData.selectedRecords,
              source: customReportData.source,
              report_format: customReportData.report_format,
              report_type: customReportData.report_type,
              report_name: customReportData.report_name.trim(),
            },
            {
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            }
          );
          break;

        case JOURNALS:
          response = await Axios.post(
            `${WAT_RT_URL}/report/`,
            {
              select_all: customReportData.selectAll,
              filters: {
                contains_term:
                  searchType === "Application No/Name"
                    ? customReportData.filters.containsTerm
                    : "",
                mark_desc_contains_term:
                  searchType === "Mark Description Search"
                    ? customReportData.filters.mark_desc_contains_term
                    : "",
                threat_proprietors: customReportData.filters.proprietors,
                threat_categories: customReportData.filters.trademarkCategories,
                threat_classes: customReportData.filters.trademarkClasses,
                threat_offices: customReportData.filters.trademarkOffices,
                threat_types: customReportData.filters.trademarkTypes,
              },
              journal_number: customReportData.journalNumber,
              filter_flag: customReportData.filter_flag,
              data: customReportData.threats,
              mark: customReportData.mark.application_number
                ? customReportData.mark.application_number
                : null,
              source: customReportData.source,
              report_type: customReportData.mark.application_number
                ? "CUSTOM"
                : "JOURNAL",
              report_format: customReportData.reportFormat,
              report_name: customReportData.reportName.trim(),
            },
            {
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            }
          );
          break;

        default:
          break;
      }

      switch (response.status) {
        case 201:
          message.success(
            <span>
              Your Report is being generated and will be sent to you via e-mail.
              <br />
              You can also visit the Reports section, once generated.
            </span>
          );
          dispatch({
            type: actionType,
            status: SUCCESS,
            payload: response?.data,
          });
          break;

        default:
          message.error(
            `Unexpected Response from server while creating report: ${response.status}`
          );
          dispatch({ type: actionType, status: ERROR });
          break;
      }
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: actionType, status: ERROR });
    }
  };
}

//--------Post image custom report for Watch--------
export function postImageCustomReport(customReportData, actionType) {
  const searchType = `${customReportData?.filters?.searchType}`;

  return async (dispatch) => {
    dispatch({ type: actionType, status: LOADING });
    let response;
    try {
      switch (actionType) {
        case GL_POST_IMAGE_LABEL_CUSTOM_REPORT:
          response = await Axios.post(
            `${WAT_RT_URL}/image_label/report/`,
            {
              ...customReportData,
              report_name: customReportData.report_name.trim(),
            },
            {
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            }
          );
          break;

        case GL_POST_IMAGE_TEXT_CUSTOM_REPORT:
          response = await Axios.post(
            `${WAT_RT_URL}/image_text/report/`,
            {
              ...customReportData,
              report_name: customReportData.report_name.trim(),
            },
            {
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            }
          );
          break;

        case LL_POST_IMAGE_CUSTOM_REPORT:
          response = await Axios.post(
            `${WAT_RT_URL}/image_text/report/`,
            {
              select_all: customReportData.selectAll,
              filters: {
                tags: customReportData.filters.tags,
                mark_classes: customReportData.filters.markClasses,
                threat_classes: customReportData.filters.threatClasses,
                threat_sources: customReportData.filters.threatSources,
                contains_term:
                  searchType === "Application No/Name"
                    ? customReportData.filters.searchTerm
                    : "",
                mark_desc_contains_term:
                  searchType === "Your Mark Description Search"
                    ? customReportData.filters.mark_desc_contains_term
                    : "",
                threat_desc_contains_term:
                  searchType === "Your Threat Description Search"
                    ? customReportData.filters.threat_desc_contains_term
                    : "",
                threat_similarity: `${customReportData.filters.threatsSimilarity.lowerLimit}to${customReportData.filters.threatsSimilarity.upperLimit}`,
                mark_types: customReportData.filters.markTypes,
                threat_types: customReportData.filters.threatTypes,
                mark_proprietors: customReportData.filters.markProprietors,
                mark_categories: customReportData.filters.markCategories,
                threat_categories: customReportData.filters.threatCategories,
                mark_offices: customReportData.filters.markOffices,
                threat_offices: customReportData.filters.threatOffices,
                mark_date_of_application:
                  customReportData.filters.mark_date_of_application,
                threat_date_of_application:
                  customReportData.filters.threat_date_of_application,
                mark_date_of_usage: customReportData.filters.mark_date_of_usage,
                mark_date_of_usage_proposed:
                  customReportData.filters.mark_date_of_usage_proposed,
                threat_date_of_usage:
                  customReportData.filters.threat_date_of_usage,
                threat_date_of_usage_proposed:
                  customReportData.filters.threat_date_of_usage_proposed,
                red_band: customReportData.filters.redBand,
                exclude_same_class: customReportData.filters.excludeClass,
              },
              journal_number: customReportData.journalNumber,
              filter_flag: customReportData.filter_flag,
              data: customReportData.selectedRecords,
              source: customReportData.source,
              report_format: customReportData.report_format,
              report_type: customReportData.report_type,
              report_name: customReportData.report_name.trim(),
            },
            {
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            }
          );
          break;

        default:
          break;
      }

      switch (response.status) {
        case 201:
          message.success(
            <span>
              Your Report is being generated and will be sent to you via e-mail.
              <br />
              You can also visit the Reports section, once generated.
            </span>
          );
          dispatch({
            type: actionType,
            status: SUCCESS,
            payload: response?.data,
          });
          break;

        default:
          message.error(
            `Unexpected Response from server while creating report: ${response.status}`
          );
          dispatch({ type: actionType, status: ERROR });
          break;
      }
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: actionType, status: ERROR });
    }
  };
}

//--------Post TMProtect custom report for Watch--------
export function postTMProtectCustomReport(customReportData, actionType) {
  const searchType = `${customReportData.filters.searchType}`;

  return async (dispatch) => {
    dispatch({ type: actionType, status: LOADING });
    let response;
    try {
      switch (actionType) {
        case GL_POST_TM_PROTECT_CUSTOM_REPORT:
          response = await Axios.post(
            `${WAT_RT_URL}/tm_protect/report/`,
            {
              ...customReportData,
              report_name: customReportData.report_name.trim(),
            },
            {
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            }
          );
          break;

        case LL_POST_TM_PROTECT_CUSTOM_REPORT:
          response = await Axios.post(
            `${WAT_RT_URL}/tm_protect/report/`,
            {
              select_all: customReportData.selectAll,
              filters: {
                threat_classes: customReportData.filters.threatClasses,
                threat_sources: customReportData.filters.threatSources,
                contains_term:
                  searchType === "Application No/Name"
                    ? customReportData.filters.searchTerm
                    : "",
                mark_desc_contains_term:
                  searchType === "Your Mark Description Search"
                    ? customReportData.filters.mark_desc_contains_term
                    : "",
                threat_desc_contains_term:
                  searchType === "Your Threat Description Search"
                    ? customReportData.filters.threat_desc_contains_term
                    : "",
                threat_similarity: `${customReportData.filters.threatsSimilarity.lowerLimit}to${customReportData.filters.threatsSimilarity.upperLimit}`,
                threat_types: customReportData.filters.threatTypes,
                threat_categories: customReportData.filters.threatCategories,
                threat_offices: customReportData.filters.threatOffices,
                threat_date_of_application:
                  customReportData.filters.threat_date_of_application,
                threat_date_of_usage:
                  customReportData.filters.threat_date_of_usage,
                threat_date_of_usage_proposed:
                  customReportData.filters.threat_date_of_usage_proposed,
              },
              filter_flag: customReportData.filter_flag,
              data: customReportData.selectedRecords,
              source: customReportData.source,
              cease_desist_report_id: customReportData.cease_desist_report_id,
              report_format: customReportData.report_format,
              report_type: customReportData.report_type,
              report_name: customReportData.report_name.trim(),
            },
            {
              headers: {
                Authorization: "Token " + localStorage.getItem("token"),
              },
            }
          );
          break;

        default:
          break;
      }

      switch (response.status) {
        case 201:
          message.success(response?.data?.message);
          dispatch({
            type: actionType,
            status: SUCCESS,
            payload: response?.data,
          });
          break;

        default:
          message.error(
            `Unexpected Response from server while creating report: ${response.status}`
          );
          dispatch({ type: actionType, status: ERROR });
          break;
      }
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: actionType, status: ERROR });
    }
  };
}

//--------Get Journals List--------
export function getDateRangeList(mode, setCurrentDateRangeFlag) {
  return async (dispatch) => {
    dispatch({ type: GET_DATE_RANGE_LIST, status: LOADING });
    let response;
    try {
      response = await Axios.get(`${WAT_RT_URL}/journals/`, {
        params: { mode: mode },
        headers: { Authorization: "Token " + localStorage.getItem("token") },
      });

      switch (response.status) {
        case 200:
          dispatch({
            type: GET_DATE_RANGE_LIST,
            status: SUCCESS,
            payload: response?.data,
          });
          if (setCurrentDateRangeFlag === true) {
            dispatch({
              type: SET_CURRENT_DATE_RANGE,
              status: SUCCESS,
              payload: response?.data.data[0].date_range,
              report_id: response?.data.data[0].id,
            });
          }
          break;

        default:
          notification["error"]({
            message: "Unexpected Response: Date Range List",
            description: "Server gave an inappropriate response.",
          });
          dispatch({ type: GET_DATE_RANGE_LIST, status: ERROR });
          break;
      }
    } catch (err) {
      checkAuthorisation(dispatch, err);
      dispatch({ type: GET_DATE_RANGE_LIST, status: ERROR });
    }
  };
}
