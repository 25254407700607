import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { FixedSizeList } from "react-window";
import _ from "lodash";

import {
  CarryOutTwoTone,
  DownOutlined,
  MessageTwoTone,
} from "@ant-design/icons";

import {
  Layout,
  Row,
  Col,
  Typography,
  Table,
  Switch,
  Input,
  Select,
  Checkbox,
  Menu,
  Dropdown,
  Button,
  message,
  notification,
} from "antd";

//--------HISTORY--------
import { history } from "../../../history";

//-----------MODALS-----------
import CreateTask from "../to-do-task/CreateTask";
import ManagerNotes from "../subComponents/Modal/managerNotes";
import SendEmail from "../../commonComponents/Modals/sendMail";
import { TM } from "../../../constants/managerConstants/managerConstants";
import {
  POST_TM_NOTES,
  PATCH_TM_NOTES,
} from "../../../redux/actions/managerActions/commonAction/types";
// import SendEmail from '../../commonComponents/Modals/sendMail';
import SendEmailNew from "../../commonComponents/Modals/SendEmailNew";

//--------MANAGER ACTIONS--------
import { getNotification } from "../../../redux/actions/managerActions/profile/actions";
import { getEmailTemplateData } from "../../../redux/actions/commonActions/actions";

//--------CHECK VALUE FUNCTIONS--------
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
  Notification_DefaultSelectedFilters,
  Notification_DefaultSearchFilters,
} from "../../../constants/commonConstants/generalConstants";
import {
  changeStateValue_L2,
  changeStateValue_L0,
} from "../../../functions/commonFunctions/generalFunctions";

import DateRangePicker from "../../../functions/commonFunctions/dateRangePicker";
import { logOut } from "../../../redux/actions/commonActions/actions";
import { Tooltip } from "antd";

import DownloadReportModal from "../report/DownloadReportModal";

import FilterSearchBasic from "../../commonComponents/FilterComponents/FilterSearchBasic";
const { Content } = Layout;
const { Text } = Typography;
const { Search } = Input;
const { Option } = Select;
const { SubMenu } = Menu;

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
      managerType: this.props.notificationType,
      value: this.props.tmNotificationType,
      management_trademark_id: "",
      management_patent_id: "",
      proprietor_id: "",
      applicant_id: "",
      both: true,
      inventor_id: "",
      range: null,

      searchFilters: {
        trademark: Notification_DefaultSearchFilters,
        patent: Notification_DefaultSearchFilters,
      },
      selectedFilters: {
        trademark: this.props.tmNotificationAppliedFilters,
        patent: this.props.patentNotificationAppliedFilters,
      },

      visible: false,
      addTaskVisible: false,
      changeSwitch: false,
      managerNotes: false,
      modalsVisibility: {
        sendEmail: false,
        mailContent: "",
        report_tag: {},
      },
      selectAll: false,
      selectedRows: [],
      selectedRowKeys: [],
      reportVisible: false,
    };

    this.changeStateValue_L2 = changeStateValue_L2.bind(this);
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
    this.setDate = this.setDate.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.tmNotification === null) {
        this.props.getNotification({
          type: "trademark",
          mmType: "trademark",
          filterFlag: false,
          filters: Notification_DefaultSelectedFilters,
        });
      }
      if (this.props.patentNotification === null) {
        this.props.getNotification({
          type: "patent",
          filterFlag: false,
          filters: Notification_DefaultSelectedFilters,
        });
      }
    } else {
      this.props.logOut();
    }
  }

  handleTrademarkProfile(data) {
    sessionStorage.setItem(
      "management_trademark_id",
      data.management_trademark_id,
    );
    sessionStorage.setItem(
      "trademark_application_number",
      data.application_number,
    );
    window.open(
      "/manager/trademark/trademark-profile/" + data.management_trademark_id,
      "_blank",
    );
    this.setState({
      management_trademark_id: data.management_trademark_id,
    });
  }

  handlePatentProfile(data) {
    sessionStorage.setItem("management_patent_id", data.management_patent_id);
    sessionStorage.setItem(
      "patent_application_number",
      data.application_number,
    );
    window.open(
      "/manager/patent/patent-profile/" + data.management_patent_id,
      "_blank",
    );
    this.setState({
      management_patent_id: data.management_patent_id,
    });
  }

  handleProprietorProfile(data) {
    if (data) {
      sessionStorage.setItem("proprietor_id", data.id);
      window.open("/manager/trademark/proprietor-profile/" + data.id, "_blank");
      this.setState({
        proprietor_id: data.id,
      });
    }
  }

  handleApplicantProfile(data) {
    sessionStorage.setItem("applicant_id", data.id);
    window.open("/manager/patent/applicant-profile/" + data.id, "_blank");
    this.setState({
      applicant_id: data.id,
    });
  }

  handleInventorProfile(data) {
    sessionStorage.setItem("inventor_id", data.id);
    window.open("/manager/patent/inventor-profile/" + data.id, "_blank");
    this.setState({
      inventor_id: data.id,
    });
  }

  changeManagerType() {
    this.setState({
      managerType: !this.state.managerType,
      both: false,
      selectedRows: [],
      selectedRowKeys: [],
      reportVisible: false,
      selectAll: false,
    });
  }

  handleChange = (value) => {
    if (
      this.props.tmNotificationStatus !== LOADING &&
      this.props.patentNotificationStatus !== LOADING
    ) {
      const { managerType } = this.state;
      const type = managerType ? "patent" : "trademark";
      this.props.getNotification({
        type: "trademark",
        mmType: value,
        filterFlag: false,
        filters: Notification_DefaultSelectedFilters,
        pageNumber: 1,
      });
      this.setState({
        value: value,
        selectedFilters: {
          ...this.state.selectedFilters,
          [type]: Notification_DefaultSelectedFilters,
        },
        searchFilters: {
          ...this.state.searchFilters,
          [type]: Notification_DefaultSearchFilters,
        },
      });
    } else {
      notification["warning"]({
        message: "Request Under Process",
        description:
          "A request is under process. Kindly, wait for its completion",
      });
    }
  };

  applyFilter() {
    if (
      this.props.tmNotificationStatus !== LOADING &&
      this.props.patentNotificationStatus !== LOADING
    ) {
      const { managerType, value } = this.state;
      const type = managerType ? "patent" : "trademark";
      const selectedFilters = managerType
        ? this.state.selectedFilters?.patent
        : this.state.selectedFilters?.trademark;
      const {
        notificationFields,
        proprietor,
        opponent,
        applicant,
        inventor,
        date_of_notification,
        containsTerm,
      } = selectedFilters;
      if (
        notificationFields?.length > 0 ||
        proprietor?.length > 0 ||
        opponent?.length > 0 ||
        applicant?.length > 0 ||
        inventor?.length > 0 ||
        date_of_notification?.length > 0 ||
        containsTerm?.length > 0
      ) {
        this.props.getNotification({
          type: type,
          mmType: value,
          filterFlag: true,
          filters: selectedFilters,
          pageNumber: 1,
        });
        this.setState({
          selectedFilters: {
            ...this.state.selectedFilters,
            [type]: selectedFilters,
          },
        });
      } else {
        message.error("No Filter Selected!");
      }
    } else {
      notification["warning"]({
        message: "Request Under Process",
        description:
          "A request is under process. Kindly, wait for its completion",
      });
    }
  }

  setDate(val) {
    this.setState({ range: val });
  }

  resetFilter() {
    if (
      this.props.tmNotificationStatus !== LOADING &&
      this.props.patentNotificationStatus !== LOADING
    ) {
      this.setState({ range: null });
      const { managerType, value } = this.state;
      const type = managerType ? "patent" : "trademark";
      const { tmNotificationAppliedFilters, patentNotificationAppliedFilters } =
        this.props || {};
      const {
        notificationFields,
        proprietor,
        opponent,
        applicant,
        inventor,
        date_of_notification,
        containsTerm,
      } = managerType
        ? patentNotificationAppliedFilters
        : tmNotificationAppliedFilters;
      if (
        notificationFields?.length > 0 ||
        proprietor?.length > 0 ||
        opponent?.length > 0 ||
        applicant?.length > 0 ||
        inventor?.length > 0 ||
        date_of_notification?.length > 0 ||
        containsTerm?.length > 0
      ) {
        this.props.getNotification({
          type: type,
          mmType: value,
          filterFlag: false,
          filters: Notification_DefaultSelectedFilters,
          pageNumber: 1,
        });
        this.setState({
          selectedFilters: {
            ...this.state.selectedFilters,
            [type]: Notification_DefaultSelectedFilters,
          },
          searchFilters: {
            ...this.state.searchFilters,
            [type]: Notification_DefaultSearchFilters,
          },
        });
      } else {
        message.error("No Filter Applied");
      }
    } else {
      notification["warning"]({
        message: "Request Under Process",
        description:
          "A request is under process. Kindly, wait for its completion",
      });
    }
  }

  handleNextpage = (pageNumber) => {
    if (
      this.props.tmNotificationStatus !== LOADING &&
      this.props.patentNotificationStatus !== LOADING
    ) {
      const { managerType, value } = this.state;
      const type = managerType ? "patent" : "trademark";
      const selectedFilters = managerType
        ? this.state.selectedFilters?.patent
        : this.state.selectedFilters?.trademark;
      this.props.getNotification({
        type: type,
        mmType: value,
        filterFlag: true,
        filters: selectedFilters,
        pageNumber: pageNumber,
      });
      this.setState({
        selectedFilters: {
          ...this.state.selectedFilters,
          [type]: {
            ...this.state.selectedFilters[type],
            pageNumber: pageNumber,
          },
        },
      });
    } else {
      notification["warning"]({
        message: "Request Under Process",
        description:
          "A request is under process. Kindly, wait for its completion",
      });
    }
  };

  changeSwitch() {
    this.setState({
      changeSwitch: !this.state.changeSwitch,
    });
  }

  handleManagerNotes(data) {
    this.setState({
      managerNotes: true,
      application_number: data.application_number,
    });
  }

  async handleEmail(modalsVisibility, modal, value, data) {
    sessionStorage.setItem(
      "management_trademark_id",
      data.management_trademark_id,
    );
    let mailContent = "<p><strong>Trademark Manager</strong></p>";
    this.setState({
      [modalsVisibility]: {
        ...this.state[modalsVisibility],
        [modal]: value,
        mailContent: mailContent,
        product: "MANAGER",
        subscription: [
          this.state.changeSwitch ? "PSEUDO_TRADEMARK" : "TRADEMARK",
        ],
        report_tag: {
          journal_number: checkValue(data.journal_number)
            ? data.journal_number
            : "",
          journal_date: checkValue(data.journal_date) ? data.journal_date : "",
          status: checkValue(data.status) ? data.status : "",
          renewal_date: checkValue(data.valid_upto) ? data.valid_upto : "",
          application_number: checkValue(data.application_number)
            ? data.application_number
            : "",
          mark_name: checkValue(data.applied_for) ? data.applied_for : "",
          date_of_application: checkValue(data.date_of_application)
            ? data.date_of_application
            : "",
          class: checkValue(data.classes) ? data.classes : "",
          date_of_usage: checkValue(data.date_of_usage)
            ? data.date_of_usage
            : "",
          proprietor_name: checkValue(data.proprietor)
            ? data.proprietor.map((prop) => prop.name).join(", ")
            : "",
          opponent_name: checkValue(data.opponent)
            ? data.opponent.map((opp) => opp.name).join(", ")
            : "",
          tla_hearing_date: "",
          opposition_hearing_date: "",
          report_name: "",
        },
        objectType: this.state.changeSwitch ? "pseudo_trademark" : "trademark",
        applicationNumber: data.application_number,
        markId: data.management_trademark_id,
        sourceId: sessionStorage.getItem("management_trademark_id"),
      },
    });

    if (value) {
      await this.props.getEmailTemplateData({
        subscription: "TRADEMARK",
        product: "MANAGER",
        object_id: data?.application_number,
        sub_section: "DASHBOARD",
      });
    }
  }

  async handleEmail_patent(modalsVisibility, modal, value, data) {
    sessionStorage.setItem("management_patent_id", data.management_patent_id);
    let mailContent = "<p><strong>Trademark Manager</strong></p>";
    this.setState({
      [modalsVisibility]: {
        ...this.state[modalsVisibility],
        [modal]: value,
        mailContent: mailContent,
        product: "MANAGER",
        subscription: [
          this.state.changeSwitch ? "PSEUDO_TRADEMARK" : "TRADEMARK",
        ],
        report_tag: {
          journal_number: checkValue(data.journal_number)
            ? data.journal_number
            : "",
          journal_date: checkValue(data.journal_date) ? data.journal_date : "",
          status: checkValue(data.status) ? data.status : "",
          renewal_date: checkValue(data.valid_upto) ? data.valid_upto : "",
          application_number: checkValue(data.application_number)
            ? data.application_number
            : "",
          mark_name: checkValue(data.applied_for) ? data.applied_for : "",
          date_of_application: checkValue(data.date_of_application)
            ? data.date_of_application
            : "",
          class: checkValue(data.classes) ? data.classes : "",
          date_of_usage: checkValue(data.date_of_usage)
            ? data.date_of_usage
            : "",
          proprietor_name: checkValue(data.proprietor)
            ? data.proprietor.map((prop) => prop.name).join(", ")
            : "",
          opponent_name: checkValue(data.opponent)
            ? data.opponent.map((opp) => opp.name).join(", ")
            : "",
          tla_hearing_date: "",
          opposition_hearing_date: "",
          report_name: "",
        },
        objectType: this.state.changeSwitch ? "pseudo_trademark" : "trademark",
        applicationNumber: data.application_number,
        markId: data.management_mark_id,
        sourceId: sessionStorage.getItem("management_patent_id"),
      },
    });
    if (value) {
      await this.props.getEmailTemplateData({
        subscription: "PATENT",
        product: "MANAGER",
        object_id: data?.application_number,
        sub_section: "DASHBOARD",
      });
    }
  }

  downloadReport() {
    const { selectedRows } = this.state;

    if (selectedRows.length > 0) {
      this.setState({
        reportVisible: true,
      });
    } else {
      message.error("no mark selected");
    }
  }

  handleOpenChange = (flag, key) => {
    if (flag) {
      this.setState({
        open: key,
      });
    } else {
      this.setState({
        open: null,
      });
    }
  };

  render() {
    const { userSubscriptions } = this.props;
    const { managerType, both, changeSwitch, value } = this.state;

    const selectedFilters = managerType
      ? this.state.selectedFilters?.patent
      : this.state.selectedFilters?.trademark;
    const searchFilters = managerType
      ? this.state.searchFilters.patent
      : this.state.searchFilters.trademark;
    const notificationFilters = managerType
      ? this.props.patentNotificationStatus
      : this.props.tmNotificationStatus;

    let switchValue;
    if (managerType) {
      switchValue = "patent";
    } else {
      switchValue = "trademark";
    }

    const trademarkNotificationColumns = [
      {
        title: "TRADEMARK",
        width: "10%",
        className: "trademark-column",
        render: (text, record) => {
          return (
            <div>
              <p
                className="application-text"
                onClick={() => this.handleTrademarkProfile(record)}
              >
                <span>
                  {checkValue(record.application_number)
                    ? record.application_number
                    : "N.A."}{" "}
                  -{" "}
                </span>
                <span className="text-camelCase">
                  {checkValue(record.applied_for)
                    ? record.applied_for.toLowerCase()
                    : "N.A."}
                </span>
              </p>
              <p>
                <img
                  className="trademark-image"
                  src={
                    checkValue(record.associated_image)
                      ? record.associated_image
                      : ""
                  }
                  alt=""
                />
              </p>
            </div>
          );
        },
      },

      {
        title: "DATE",
        dataIndex: "created",
        className: "trademark-column",
      },

      {
        title: "FIELD",
        dataIndex: "field",
        className: "trademark-column",
      },

      {
        title: "PROPRIETOR",
        width: "10%",
        dataIndex: "proprietor",
        className: "trademark-column",
        render: (proprietor) => {
          return (
            <div>
              {checkValue(proprietor)
                ? proprietor.length > 0
                  ? proprietor.map((data, key) => (
                      <p
                        key={key}
                        className="application-text text-camelCase"
                        onClick={() => this.handleProprietorProfile(data)}
                      >
                        {checkValue(data.name)
                          ? data.name.toLowerCase()
                          : "N.A."}
                      </p>
                    ))
                  : "N.A."
                : "N.A."}
            </div>
          );
        },
      },

      {
        title: "OPPONENT",
        width: "10%",
        dataIndex: "opponent",
        className: "trademark-column",
        render: (opponent) => {
          return (
            <div>
              {checkValue(opponent)
                ? opponent.length > 0
                  ? opponent.map((data, key) => (
                      <p key={key} className="text-camelCase">
                        <span>
                          {checkValue(data.name)
                            ? data.name.toLowerCase()
                            : "N.A."}
                        </span>
                        <p>
                          (
                          {checkValue(data.opp_rec_number)
                            ? data.opp_rec_number.toLowerCase()
                            : "N.A."}
                          )
                        </p>
                      </p>
                    ))
                  : "N.A."
                : "N .A."}
            </div>
          );
        },
      },

      {
        title: "MESSAGE",
        dataIndex: "message",
        width: "50%",
      },

      {
        title: "ACTION",
        width: "5%",
        className: "trademark-column",
        render: (action) => {
          return (
            <div>
              <Tooltip placement="top" title={"Create To-do Task"}>
                <p className="alignC">
                  <CarryOutTwoTone
                    onClick={() =>
                      this.changeStateValue_L0("addTaskVisible", true)
                    }
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Notes"}>
                <p className="alignC">
                  <MessageTwoTone
                    onClick={() => this.handleManagerNotes(action)}
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Send email"}>
                <p
                  className="alignC"
                  onClick={() =>
                    this.handleEmail(
                      "modalsVisibility",
                      "sendEmail",
                      true,
                      action,
                    )
                  }
                >
                  <i
                    className="fa fa-paper-plane"
                    style={{
                      padding: "0px 5px",
                      color: "#1890ff",
                      fontSize: 20,
                      cursor: "pointer",
                    }}
                    aria-hidden="true"
                  ></i>
                </p>
              </Tooltip>
            </div>
          );
        },
      },
    ];

    const patentNotificationColumns = [
      {
        title: "PATENT",
        className: "trademark-column",
        render: (text, record) => {
          return (
            <div>
              <p
                className="application-text"
                onClick={() => this.handlePatentProfile(record)}
              >
                <span>
                  {checkValue(record.application_number)
                    ? record.application_number
                    : "N.A."}{" "}
                  -{" "}
                </span>
                <span className="text-camelCase">
                  {checkValue(record.title_of_invention)
                    ? record.title_of_invention.toLowerCase()
                    : "N.A."}
                </span>
              </p>
            </div>
          );
        },
      },

      {
        title: "DATE",
        dataIndex: "created",
        className: "trademark-column",
      },

      {
        title: "FIELD",
        dataIndex: "field",
        className: "trademark-column",
      },

      {
        title: "APPLICANT",
        dataIndex: "applicant",
        className: "trademark-column",
        render: (applicant) => {
          return (
            <div>
              {checkValue(applicant)
                ? applicant.length > 0
                  ? applicant.map((data, key) => (
                      <p
                        key={key}
                        className="application-text text-camelCase"
                        onClick={() => this.handleProprietorProfile(data)}
                      >
                        {checkValue(data.name)
                          ? data.name.toLowerCase()
                          : "N.A."}
                      </p>
                    ))
                  : "N.A."
                : "N.A."}
            </div>
          );
        },
      },

      {
        title: "INVENTOR",
        dataIndex: "inventor",
        className: "trademark-column",
        render: (inventor) => {
          return (
            <div>
              {checkValue(inventor)
                ? inventor.length > 0
                  ? inventor.map((data, key) => (
                      <p
                        key={key}
                        className="application-text text-camelCase"
                        onClick={() => this.handleProprietorProfile(data)}
                      >
                        {checkValue(data.name)
                          ? data.name.toLowerCase()
                          : "N.A."}
                      </p>
                    ))
                  : "N.A."
                : "N.A."}
            </div>
          );
        },
      },

      {
        title: "MESSAGE",
        dataIndex: "message",
        width: "50%",
      },

      {
        title: "ACTION",
        className: "trademark-column",
        render: (action) => {
          return (
            <div>
              <Tooltip placement="top" title={"Create To-do Task"}>
                <p className="alignC">
                  <CarryOutTwoTone
                    onClick={() =>
                      this.changeStateValue_L0("addTaskVisible", true)
                    }
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Notes"}>
                <p className="alignC">
                  <MessageTwoTone
                    onClick={() => this.handleManagerNotes(action)}
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Send email"}>
                <p
                  className="alignC"
                  onClick={() =>
                    this.handleEmail_patent(
                      "modalsVisibility",
                      "sendEmail",
                      true,
                      action,
                    )
                  }
                >
                  <i
                    className="fa fa-paper-plane"
                    style={{
                      padding: "0px 5px",
                      cursor: "pointer",
                      color: "#1890ff",
                      fontSize: 20,
                    }}
                    aria-hidden="true"
                  ></i>
                </p>
              </Tooltip>
            </div>
          );
        },
      },
    ];

    let noFilterData = (
      <Menu>
        <Menu.Item>
          <div className="alignC">
            <Text strong>No Filters Data Found</Text>
          </div>
        </Menu.Item>
      </Menu>
    );

    let fieldMenu = noFilterData;
    let proprietorsMenu = noFilterData;
    let opponentMenu = noFilterData;
    let applicantMenu = noFilterData;
    let inventorMenu = noFilterData;

    if (notificationFilters === SUCCESS) {
      const {
        fields,
        proprietors,
        opponent,
        applicant,
        inventor,
        status,
        documents,
        correspondence_notices,
        opposition_documents,
        opposition_correspondence_notices,
        legal_status,
      } = managerType
        ? this.props.patentNotification.filters
        : this.props.tmNotification.filters;

      if (fields !== null && fields.length > 0) {
        fieldMenu = (
          <FilterSearchBasic
            data={fields}
            searchFilters={searchFilters}
            search_key={"fieldSearch"}
            filter_key={"notificationFields"}
            selectedFilters={selectedFilters}
            switchValue={switchValue}
            onSelect={this.changeStateValue_L2}
          />
        );
      }

      if (proprietors !== null && proprietors.length > 0) {
        proprietorsMenu = (
          <FilterSearchBasic
            data={proprietors}
            searchFilters={searchFilters}
            search_key={"proprietorSearch"}
            filter_key={"proprietor"}
            selectedFilters={selectedFilters}
            switchValue={switchValue}
            onSelect={this.changeStateValue_L2}
            type={"name"}
          />
        );
      }

      if (opponent !== null && opponent.length > 0) {
        opponentMenu = (
          <FilterSearchBasic
            data={opponent.map((opponentObj) => ({
              id: opponentObj?.id,
              name: opponentObj?.opponent_name,
            }))}
            searchFilters={searchFilters}
            search_key={"opponentSearch"}
            filter_key={"opponent"}
            selectedFilters={selectedFilters}
            switchValue={switchValue}
            onSelect={this.changeStateValue_L2}
            type={"name"}
          />
        );
      }

      if (applicant !== null && applicant.length > 0) {
        applicantMenu = (
          <FilterSearchBasic
            data={applicant}
            searchFilters={searchFilters}
            search_key={"applicantSearch"}
            filter_key={"applicant"}
            selectedFilters={selectedFilters}
            switchValue={switchValue}
            onSelect={this.changeStateValue_L2}
            type={"name"}
          />
        );
      }

      if (inventor !== null && inventor.length > 0) {
        inventorMenu = (
          <FilterSearchBasic
            data={inventor}
            searchFilters={searchFilters}
            search_key={"inventorSearch"}
            filter_key={"inventor"}
            selectedFilters={selectedFilters}
            switchValue={switchValue}
            onSelect={this.changeStateValue_L2}
            type={"name"}
          />
        );
      }
    }

    const manageNotificationRowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRows: selectedRows,
          add_trademark_id: selectedRows.map((data) => data.id),
          add_application_no: selectedRows.map(
            (data) => data.application_number,
          ),
          selectedRowKeys: selectedRowKeys,
        });
      },

      onSelectAll: (selected, selectedRows) => {
        this.setState({
          selectAll: selected,
          selectedRows: selectedRows,
        });
        if (selected) {
          notification.open({
            message: "Deselect All Mark",
            description:
              "Click on the arrow near the select all checkbox to deselect all marks",
          });
        }
      },

      onSelect: (record, selected, selectedRows) => {
        this.setState({
          selectRow: selected,
          selectAll: selectedRows.length === 100 ? true : false,
        });
      },

      hideDefaultSelections: true,
      selections: this.state.selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All Data",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  selectedRowKeys: changeableRowKeys,
                  selectedRows: [],
                  selectAll: false,
                  add_trademark_id: [],
                  add_application_no: [],
                });
              },
            },
          ]
        : false,

      selectedRowKeys: this.state.selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled: this.state.selectAll,
      }),
    };

    return (
      <div>
        <Layout className="Notification">
          <Layout>
            <Content style={{ margin: "0px" }}>
              <Row
                className="content-container vertical_scroll"
                style={{
                  height: `${
                    this.props.managerAlert.length > 0
                      ? "calc(100vh - 40px)"
                      : "calc(100vh - 0px)"
                  }`,
                }}
              >
                <Col span={24}>
                  {/* Notification Type */}
                  <Row style={{ margin: "8px 8px" }}>
                    <Col span={8}>
                      {userSubscriptions &&
                        (userSubscriptions.manager.includes("TRADEMARK") ||
                          userSubscriptions.manager.includes("PATENT")) && (
                          <div>
                            <Text strong>TRADEMARK</Text>
                            <Switch
                              className="change-manager-type"
                              onChange={() => this.changeManagerType()}
                              checked={managerType}
                            />
                            <Text strong>PATENT</Text>
                          </div>
                        )}
                    </Col>
                    <Col span={8} className="alignC">
                      <Text
                        strong
                        style={{ margin: "0px", fontSize: "16px" }}
                        className={managerType ? "display_none" : ""}
                      >
                        TOTAL TRADEMARK NOTIFICATION :{" "}
                        {checkValue(this.props.tmNotification)
                          ? this.props.tmNotification.count
                          : 0}{" "}
                      </Text>
                      <Text
                        strong
                        style={{ margin: "0px", fontSize: "16px" }}
                        className={managerType ? "" : "display_none"}
                      >
                        TOTAL PATENT NOTIFICATION :{" "}
                        {checkValue(this.props.patentNotification)
                          ? this.props.patentNotification.count
                          : 0}{" "}
                      </Text>
                    </Col>
                    {/* {(userSubscriptions.manager.includes('TRADEMARK') || userSubscriptions.manager.includes('PATENT')) && 
                                        <Col span={8} className="alignC">
                                            <Text strong style={{margin: '0px', fontSize:'16px'}} className={userSubscriptions.manager.includes('TRADEMARK')? '' : 'display_none'}>TOTAL TRADEMARK NOTIFICATION : {checkValue(this.props.tmNotification) ? this.props.tmNotification.count : 0 } </Text>
                                            <Text strong style={{margin: '0px', fontSize:'16px'}} className={userSubscriptions.manager.includes('TRADEMARK') ? 'display_none' : ''}>TOTAL PATENT NOTIFICATION : {checkValue(this.props.patentNotification) ? this.props.patentNotification.count : 0 } </Text>
                                        </Col>
                                        } */}
                    <Col
                      span={8}
                      className={`alignR ${managerType ? "display_none" : ""}`}
                    >
                      <Select
                        value={this.state.value}
                        style={{ width: 180 }}
                        onChange={this.handleChange}
                      >
                        <Option
                          key="trademark"
                          title="trademark"
                          value="trademark"
                          style={{ display: "inline-flex" }}
                        >
                          Your Trademark
                        </Option>
                        <Option key="opposed" title="opposed" value="opposed">
                          3rd Party Trademark
                        </Option>
                        <Option key="tracker" title="tracker" value="tracker">
                          Status Tracker
                        </Option>
                      </Select>
                    </Col>
                  </Row>
                  {/* Notification Type */}

                  {/* Notification filter */}
                  <Row style={{ borderBottom: "1px solid #E9E9E9" }}>
                    <Col span={24}>
                      <Menu
                        selectedKeys={[
                          selectedFilters?.notificationFields?.length > 0
                            ? "fieldMenu"
                            : "",
                          selectedFilters?.proprietor?.length > 0
                            ? "proprietorsMenu"
                            : "",
                          selectedFilters?.opponent?.length > 0
                            ? "opponentMenu"
                            : "",
                          selectedFilters?.applicant?.length > 0
                            ? "applicantMenu"
                            : "",
                          selectedFilters?.inventor?.length > 0
                            ? "inventorMenu"
                            : "",
                          selectedFilters?.date_of_notification?.length > 0
                            ? "notificationDate"
                            : "",
                        ]}
                        className="filter-list"
                        mode="horizontal"
                        style={{
                          alignItems: "baseline",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Menu.Item key="fieldMenu" className="filter-list-item">
                          <Dropdown
                            dropdownRender={() => fieldMenu}
                            overlayClassName="filter-menu"
                            autoAdjustOverflow
                            onOpenChange={(flag) =>
                              this.handleOpenChange(flag, "field")
                            }
                            open={this.state.open === "field"}
                          >
                            <div className="ant-dropdown-link">
                              <span>Fields </span>
                              <DownOutlined />
                            </div>
                          </Dropdown>
                        </Menu.Item>
                        <Menu.Item
                          key={
                            managerType ? "applicantMenu" : "proprietorsMenu"
                          }
                          className="filter-list-item"
                        >
                          <Dropdown
                            dropdownRender={() =>
                              managerType ? applicantMenu : proprietorsMenu
                            }
                            overlayClassName="filter-menu"
                            autoAdjustOverflow
                            onOpenChange={(flag) =>
                              this.handleOpenChange(
                                flag,
                                managerType
                                  ? "applicantMenu"
                                  : "proprietorsMenu",
                              )
                            }
                            open={
                              this.state.open ===
                              (managerType
                                ? "applicantMenu"
                                : "proprietorsMenu")
                            }
                          >
                            <div className="ant-dropdown-link">
                              <span>
                                {managerType ? "Applicant" : "Proprietor"}
                              </span>
                              <DownOutlined />
                            </div>
                          </Dropdown>
                        </Menu.Item>
                        <Menu.Item
                          key={managerType ? "inventorMenu" : "opponentMenu"}
                          className="filter-list-item"
                        >
                          <Dropdown
                            dropdownRender={() =>
                              managerType ? inventorMenu : opponentMenu
                            }
                            overlayClassName="filter-menu"
                            autoAdjustOverflow
                            onOpenChange={(flag) =>
                              this.handleOpenChange(
                                flag,
                                managerType ? "inventorMenu" : "opponentMenu",
                              )
                            }
                            open={
                              this.state.open ===
                              (managerType ? "inventorMenu" : "opponentMenu")
                            }
                          >
                            <div className="ant-dropdown-link">
                              <span>
                                {managerType ? "Inventor" : "Opponent"}
                              </span>
                              <DownOutlined />
                            </div>
                          </Dropdown>
                        </Menu.Item>
                        <Menu.Item
                          key="notificationDate"
                          className="filter-list-item"
                        >
                          <Dropdown
                            overlay={
                              <DateRangePicker
                                type={"Notification Date"}
                                startValue={
                                  selectedFilters?.date_of_notification_startValue
                                }
                                endValue={
                                  selectedFilters?.date_of_notification_endValue
                                }
                                setDate={(val) => this.setDate(val)}
                                range={this.state.range}
                                handleDateString={(
                                  startValue,
                                  endValue,
                                  dateString,
                                ) =>
                                  this.setState({
                                    selectedFilters: {
                                      ...this.state.selectedFilters,
                                      [switchValue]: {
                                        ...this.state.selectedFilters[
                                          switchValue
                                        ],
                                        date_of_notification_startValue:
                                          startValue,
                                        date_of_notification_endValue: endValue,
                                        date_of_notification:
                                          startValue !== null ||
                                          endValue !== null
                                            ? dateString
                                            : null,
                                      },
                                    },
                                  })
                                }
                              />
                            }
                            overlayClassName="filter-menu"
                          >
                            <div className="ant-dropdown-link">
                              <span>Notification Date </span>
                              <DownOutlined />
                            </div>
                          </Dropdown>
                        </Menu.Item>
                        <Menu.Item
                          key="contentTerm"
                          className="filter-list-item apply-filter"
                        >
                          <Search
                            placeholder="Application Number/Name"
                            onChange={(event) =>
                              this.changeStateValue_L2(
                                "selectedFilters",
                                switchValue,
                                "containsTerm",
                                event.target.value,
                              )
                            }
                            value={selectedFilters?.containsTerm}
                            allowClear
                            style={{ width: 250, display: "block" }}
                          />
                        </Menu.Item>
                        <Menu.Item
                          key="applyFilter"
                          className="filter-list-item apply-filter"
                        >
                          <Button
                            type="primary"
                            onClick={() => this.applyFilter()}
                          >
                            <i className="fa fa-filter" aria-hidden="true" />
                            <Text style={{ color: "#fff", marginLeft: "5px" }}>
                              Apply Filter
                            </Text>
                          </Button>
                        </Menu.Item>
                        <Menu.Item
                          key="resetFilter"
                          className="filter-list-item apply-filter"
                        >
                          <span
                            onClick={() => this.resetFilter()}
                            style={{ padding: "0px 5px" }}
                          >
                            RESET
                          </span>
                        </Menu.Item>
                      </Menu>
                    </Col>
                  </Row>
                  {/* Notification filter */}
                  {this.state?.selectedRowKeys?.length > 0 ||
                  this.state?.selectAll ? (
                    <Button
                      style={{ top: "8px" }}
                      type="primary"
                      onClick={() => this.downloadReport()}
                    >
                      {" "}
                      Create Report
                    </Button>
                  ) : null}
                  {/* Notification */}
                  <Row>
                    <Col span={24} className="m-top-20 notifications">
                      <Table
                        rowKey={(record) => record.id}
                        rowSelection={manageNotificationRowSelection}
                        columns={
                          managerType
                            ? patentNotificationColumns
                            : trademarkNotificationColumns
                        }
                        bordered
                        dataSource={
                          managerType
                            ? this.props.patentNotification &&
                              this.props.patentNotification.notifications
                            : this.props.tmNotification &&
                              this.props.tmNotification.notifications
                        }
                        pagination={{
                          pageSize: 100,
                          showQuickJumper: true,
                          showSizeChanger: false,
                          defaultCurrent: 1,
                          current: managerType
                            ? this.props.patentNotification &&
                              this.props.patentNotificationPageNumber
                            : this.props.tmNotification &&
                              this.props.tmNotificationPageNumber,
                          total: managerType
                            ? this.props.patentNotification &&
                              this.props.patentNotification.count
                            : this.props.tmNotification &&
                              this.props.tmNotification.count,
                          onChange: this.handleNextpage,
                        }}
                        scroll={{ y: "calc(100vh - 280px)" }}
                        loading={
                          managerType
                            ? this.props.patentNotificationStatus === LOADING
                              ? true
                              : false
                            : this.props.tmNotificationStatus === LOADING
                              ? true
                              : false
                        }
                      />
                    </Col>
                  </Row>
                  {/* Notification */}
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>

        <CreateTask
          visible={this.state.addTaskVisible}
          onCloseModal={() => this.changeStateValue_L0("addTaskVisible", false)}
          type={"trademark"}
        />

        <ManagerNotes
          visible={this.state.managerNotes}
          onCloseModal={() => this.changeStateValue_L0("managerNotes", false)}
          actionType={[POST_TM_NOTES, PATCH_TM_NOTES]}
          email={
            checkValue(this.props.userDetails)
              ? this.props.userDetails.email
              : ""
          }
          note_for={TM}
          application_number={this.state.application_number}
          type={"ADD"}
        />

        <SendEmailNew
          visible={this.state.modalsVisibility.sendEmail}
          onCloseModal={() =>
            this.handleEmail("modalsVisibility", "sendEmail", false, {})
          }
          sendFrom={
            this.props.userDetails && this.props.userDetails.communication_email
          }
          sendCC={this.props.userTeam && this.props.userTeam.manager}
          mailContent={this.state.modalsVisibility.mailContent}
          product={this.state.modalsVisibility.product}
          subscription={this.state.managerType ? ["PATENT"] : ["TRADEMARK"]}
          report_tag={this.state.modalsVisibility.report_tag}
          objectType={this.state.modalsVisibility.objectType}
          mm_type={
            !this.state.managerType
              ? this.state.value === "trademark"
                ? "Portfolio trademark"
                : this.state.value === "opposed"
                  ? "Third party Opposed trademark"
                  : "Status tracker"
              : ""
          }
          applicationNumber={this.state.modalsVisibility.applicationNumber}
          markId={this.state.modalsVisibility.markId}
          doc_link={true}
          sourceId={this.state.modalsVisibility.sourceId}
          source={`TRADEMARK`}
          sub_section="DASHBOARD"
          managerEmail={true}
        />

        <DownloadReportModal
          visible={this.state.reportVisible}
          onCloseModal={() => this.setState({ reportVisible: false })}
          type={managerType ? "patent" : "trademark"}
          mm_type={this.state.value}
          hideExcel
          notification_page_flag
          selected_notifications={this.state.selectedRowKeys}
          selectAll={this.state.selectAll}
          changeSwitch={changeSwitch}
          comparative={
            checkValue(this.props.userSubscriptions)
              ? this.props.userSubscriptions.manager.includes(
                  "PSEUDO_TRADEMARK",
                )
              : false
          }
          filters={
            managerType
              ? this.props.patentNotificationAppliedFilters
              : this.props.tmNotificationAppliedFilters
          }
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getNotification,
  logOut,
  getEmailTemplateData,
};

function mapStateToProps(state) {
  const {
    notificationType,
    tmNotificationType,

    tmNotification,
    tmNotificationStatus,
    tmNotificationAppliedFilters,
    tmNotificationPageNumber,

    patentNotification,
    patentNotificationStatus,
    patentNotificationAppliedFilters,
    patentNotificationPageNumber,
  } = state.managerState.profileState;
  const { userSubscriptions, userDetails, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    userSubscriptions,
    userDetails,
    userSubscriptionStatus,
    notificationType,
    tmNotificationType,

    tmNotification,
    tmNotificationStatus,
    tmNotificationAppliedFilters,
    tmNotificationPageNumber,

    patentNotification,
    patentNotificationStatus,
    patentNotificationAppliedFilters,
    patentNotificationPageNumber,

    managerAlert: state.alertMessages.manager,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
